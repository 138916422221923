package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.custom_order_parches

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_winyway_wallet.data.response.verify_recharge_status.VerifyRechargeData

@Serializable
data class CusotmOrderPurchaseStatusResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data")val data: VerifyRechargeData?,
    @SerialName("message") val message: String,
    @SerialName("status") val status: Boolean // true
)
