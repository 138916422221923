package tta.destinigo.talktoastro.feature_custom_order.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_custom_order.data.api.CustomOrderApi
import tta.destinigo.talktoastro.feature_custom_order.domain.CustomOrderListRepo


val CustomOrderModel = module {
    factory { CustomOrderListRepo(get()) }
    factory { CustomOrderApi() }

}