package tta.destinigo.talktoastro.feature_winyway_wallet.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderPurchaseStatus(
    @SerialName("razorpay_payment_id")val razorpayPaymentId:String,
    @SerialName("razorpay_order_id")val razorpayOrderId:String,
    @SerialName("razorpay_signature")val razorpaySignature:String,
    @SerialName("domain") val Domain:String
)
