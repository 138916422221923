package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.custom_order_parches

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class TxnHistory(
    @SerialName("amount_with_gst") val amountWithGst: String?, // 1532.82
    @SerialName("call_duration") val callDuration: String?, // null
    @SerialName("cashback_percent") val cashbackPercent: String?, // null
    @SerialName("cashback_value") val cashbackValue: String?, // null
    @SerialName("chat_duration") val chatDuration: String?, // null
    @SerialName("coupon_code") val couponCode: String?, // null
    @SerialName("coupon_id") val couponId: String?, // null
    @SerialName("created_at") val createdAt: String?, // 2024-05-10 16:04:57
    @SerialName("currency") val currency: String?, // INR
    @SerialName("custom_order_id") val customOrderId: Int?, // 10
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("event") val event: String?, // null
    @SerialName("expert_id") val expertId: Int?, // 49721
    @SerialName("id") val id: Int, // 98332
    @SerialName("is_free_refund") val isFreeRefund: String?, // null
    @SerialName("is_updated") val isUpdated: String?, // 1
    @SerialName("last_wallet_balance") val lastWalletBalance: String?, // null
    @SerialName("paid_amount") val paidAmount: String?, // 764
    @SerialName("payment_method") val paymentMethod: String?, // Online
    @SerialName("payment_mode") val paymentMode: String?, // Razorpay
    @SerialName("payment_status") val paymentStatus: String?, // success
    @SerialName("plan_id") val planId: String?, // null
    @SerialName("plan_name") val planName: String?, // null
    @SerialName("redeem_coins") val redeemCoins: Int?, // 0
    @SerialName("refund_amount") val refundAmount: String?, // null
    @SerialName("remarks") val remarks: String?, // null
    @SerialName("send_refund_whatsapp") val sendRefundWhatsapp: Int?, // 0
    @SerialName("total_amount") val totalAmount: String?, // 1299
    @SerialName("txn_id") val txnId: String?, // order_O8lAT6UZ38SGvw
    @SerialName("type") val type: String?, // Paid Custom Order
    @SerialName("updated_at") val updatedAt: String?, // 2024-05-10 16:05:40
    @SerialName("used_coin") val usedCoin: Int?, // 0
    @SerialName("used_coin_balance") val usedCoinBalance: String?, // 0
    @SerialName("user_id") val userId: Int?, // 84
    @SerialName("wallet_balance") val walletBalance: Int? // 764
)
