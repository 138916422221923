package tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.EnrollCustomOrderConfirmationBottomSheetComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun EnrolConfirmationBottomSheet(component: EnrollCustomOrderConfirmationBottomSheetComponent) {
    //val composition by rememberLottieComposition(LottieCompositionSpec.RawRes(R.raw.done_lottie))
    // Show ModalBottomSheet for Compact screens

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    Box(modifier = Modifier.fillMaxWidth().background(background_color), contentAlignment = Alignment.Center){
        Column(
            modifier = widthModifier.fillMaxSize().background(background_color),
            horizontalAlignment = Alignment.CenterHorizontally,
//            verticalArrangement = Arrangement.Center
        ) {
            CommonTopAppBar(title = "CustomOrder Perches", onBackClick = { component.onBackClick() })
            Spacer(modifier = Modifier.height(64.dp))

            Text(
                text = "Congratulation!",
                style = MaterialTheme.typography.headlineSmall,
                textAlign = TextAlign.Center
            )

            Text(
                text = "Your enrollment is successfully placed",
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(bottom = 32.dp)
            )

            Button(
                onClick = { component.onLessonClick() },
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 24.dp, vertical = 24.dp)
            ) {
                Text(text = "Explore Lesson")
            }
        }
    }
//        if (windowSizeClass == WindowWidthSizeClass.Compact) {
//            ModalBottomSheet(
//                sheetState = sheetState,
//                containerColor = Color.White,
//                onDismissRequest = {
////                    LaunchedEffect(sheetState) {
////                        sheetState.hide()
////                    }
//                }
//            ) {
//                Column(
//                    modifier = Modifier
//                        .fillMaxWidth()
//                        .wrapContentHeight(),
//                    horizontalAlignment = Alignment.CenterHorizontally
//                ) {
//                    Spacer(modifier = Modifier.height(64.dp))
//
//                    Text(
//                        text = "Congratulation!",
//                        style = MaterialTheme.typography.headlineSmall,
//                        textAlign = TextAlign.Center
//                    )
//
//                    Text(
//                        text = "Your enrollment is successfully placed",
//                        style = MaterialTheme.typography.bodyMedium,
//                        textAlign = TextAlign.Center,
//                        modifier = Modifier.padding(bottom = 32.dp)
//                    )
//
//                    Button(
//                        onClick = { /* TODO: Handle button click */ },
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .padding(horizontal = 24.dp, vertical = 24.dp)
//                    ) {
//                        Text(text = "Explore Lesson")
//                    }
//                }
//            }
//        } else {
//            // Show AlertDialog for larger screens
//            AlertDialog(
//                containerColor = Color.White,
//                onDismissRequest = {
//                },
//                text = {
//                    Column(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .wrapContentHeight(),
//                        horizontalAlignment = Alignment.CenterHorizontally
//                    ) {
//                        Spacer(modifier = Modifier.height(64.dp))
//
//                        Text(
//                            text = "Congratulation!",
//                            style = MaterialTheme.typography.headlineSmall,
//                            textAlign = TextAlign.Center
//                        )
//
//                        Text(
//                            text = "Your enrollment is successfully placed",
//                            style = MaterialTheme.typography.bodyMedium,
//                            textAlign = TextAlign.Center,
//                            modifier = Modifier.padding(bottom = 32.dp)
//                        )
//
//                        Button(
//                            onClick = { /* TODO: Handle button click */ },
//                            modifier = Modifier
//                                .fillMaxWidth()
//                                .padding(horizontal = 24.dp, vertical = 24.dp)
//                        ) {
//                            Text(text = "Explore Lesson")
//                        }
//                    }
//                },
//                shape = RoundedCornerShape(Scale.CORNER_SMALL),
//                confirmButton = {},
//                dismissButton = {}
//            )
//        }
    }

//    }else{
//        Box(
//            modifier = Modifier
//                .fillMaxSize()
//                .background(Color.Black.copy(alpha = 0.5f))
//                .clickable(enabled = false) { },
//            contentAlignment = Alignment.Center
//        ) {
//            CircularProgressIndicator(color = Color.White)
//        }
//    }

