package tta.destinigo.talktoastro.feature_common.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class RequestSliderResponse(
    @SerialName("code") val code: Int?, // 200
    @SerialName("data") val `data`: SliderData?,
    @SerialName("message") val message: String?, // Slider data fetched successfully
    @SerialName("status") val status: Boolean// true
)