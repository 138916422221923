package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_host

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.fade
import com.arkivanov.decompose.extensions.compose.stack.animation.plus
import com.arkivanov.decompose.extensions.compose.stack.animation.predictiveback.predictiveBackAnimation
import com.arkivanov.decompose.extensions.compose.stack.animation.scale
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import tta.destinigo.talktoastro.core.theme.LightColorScheme
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_concern.ChatConnectingScreen
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_host.decompose.HostDefaultComponent
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_host.decompose.HostRootComponent
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_room_view.ChatRoomScreen
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_waiting_room.ChatWaitingScreen

@OptIn(ExperimentalDecomposeApi::class)
@Composable
fun ChatHostPage(
    component: HostDefaultComponent
) {

    val childStack by component.childStack.subscribeAsState()


    MaterialTheme(
        colorScheme = LightColorScheme
    ) {
        Children(
            stack = childStack,
            animation = predictiveBackAnimation(
                backHandler = component.backHandler,
                fallbackAnimation = stackAnimation(fade() + scale()),
                onBack = {
                    Logger.d("onBack pressed in ChatHostPage")
                },
            )
        ) { child ->
            when (val instance = child.instance) {
                is HostRootComponent.Child.ChatConcernChild -> ChatConnectingScreen(instance.component)
                is HostRootComponent.Child.ChatRoomChild -> ChatRoomScreen(instance.component)
                is HostRootComponent.Child.ChatWaitingChild -> ChatWaitingScreen(instance.component)
            }
        }
    }

}