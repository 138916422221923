package tta.destinigo.talktoastro.feature_courses.data

import co.touchlab.kermit.Logger
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.core.data.CommonResponse
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.remote.makeApiCall
import tta.destinigo.talktoastro.feature_courses.data.request.CourseDetailsSubmitForm
import tta.destinigo.talktoastro.feature_courses.data.response.course.CourseResponse
import tta.destinigo.talktoastro.feature_courses.data.response.courses.CourseData
import tta.destinigo.talktoastro.feature_courses.data.response.courses.CoursesResponse

class CoursesApi : KtorApi() {

    companion object{
        private const val COURSE_PREFIX = "/"
        const val POST_REQUEST_COURSE_DETAILS = "store-quotes-request-data"
        private const val GET_COURSES = "bootcamps"
    }


    private suspend fun postCourseDetails(request: CourseDetailsSubmitForm):CommonResponse{
        return client.post {
            url(POST_REQUEST_COURSE_DETAILS)
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()
    }

    private suspend fun getCourses(): CoursesResponse {
        return client.get {
            url(GET_COURSES)
            contentType(ContentType.Application.Json)
        }.body()
    }

    suspend fun getCoursesApi(): Resources<List<CourseData>> {
        return makeApiCall(
            apiCall = { getCourses() },
            transform = { it.data }
        )
    }

    private suspend fun getCourse(slug: String): CourseResponse {
        return client.get {
            url("$GET_COURSES/$slug")
            contentType(ContentType.Application.Json)
        }.body()
    }

    suspend fun getCourseApi(slug: String): Resources<CourseData?> {
        return makeApiCall(
            apiCall = { getCourse(slug) },
            transform = { it.data }
        )
    }


    suspend fun postCourseDetailsRemote(request: CourseDetailsSubmitForm): Resources<Boolean> {
         return withContext(Dispatchers.Default) {
            try {
                val resources = postCourseDetails(request)
                if(resources.status.not()){
                    Logger.d { "postCourseDetailsRemote ${resources.msg}" }
                    return@withContext (Resources.Error(resources.msg))
                }
                Logger.d { "postCourseDetailsRemote ${resources.msg}" }
                return@withContext(Resources.Success(resources.status))
            }catch (e: ClientRequestException){
                return@withContext(Resources.Error(e.message))
            }catch (e: ServerResponseException){
                return@withContext(Resources.Error(e.message))
            }catch (e: Exception){
                return@withContext(Resources.Error(e.message))
            }
        }
    }

}