package tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.api.PurchaseHstoryApi
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.request.PurchaseHistoryRequest
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.response.PurchaseHistoryData

class PurchaseHistoryRepo(val api: PurchaseHstoryApi) {
    fun getPurchaseHistoryInfo(request: PurchaseHistoryRequest) : Flow<Resources<List<PurchaseHistoryData>>>{
        return flow {
            emit(Resources.Loading())
            val resources = api.getPurchaseHistoryListData(request)
            emit(resources)
            emit(Resources.Loading(false))
        }
    }
}