package tta.destinigo.talktoastro.feature_chat_consultation.data.response.place_chat_concern

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatUserConcernResponse(
    @SerialName("code")
    val code: Int?,
    @SerialName("data")
    val data: ChatUserConcernData?,
    @SerialName("message")
    val message: String?,
    @SerialName("status")
    val status: Boolean?
)