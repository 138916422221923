package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatHistoryDetailRequest(
    @SerialName("page")val page:Int,
    @SerialName("chat_id")val chatId:String
)

