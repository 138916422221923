package tta.destinigo.talktoastro.feature_article.persentation.posts_page

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.feature_article.data.response.Post

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun PostsPage(
    component: ArticlesComponent
) {

    val rememberedComponent = remember { component }

    val state by rememberedComponent.state.collectAsState()
    val articles by rememberedComponent.article.collectAsState()


    LaunchedEffect(Unit){
        Logger.d("Feature Post Start")
    }

    Scaffold(
        topBar = {
            CommonTopAppBar(title = "Articles", onBackClick = { component.onClosePage() })
        }
    ) { paddingValues -> // Add paddingValues to adjust for the scaffold's content padding
        when (state) {
            is Resources.Error -> {
                Logger.d("On Error -> ${(state as Resources.Error).msg}")
                val errorMessage = (state as Resources.Error).msg ?: "Something went wrong"
                showToast(errorMessage)
            }

            is Resources.Loading -> {
                Logger.d("On Loading -> ${(state as Resources.Loading).isLoading}")
                if ((state as Resources.Loading).isLoading) {
                    LoadingProgressDialog { }
                }
            }

            is Resources.Success -> Unit
        }

        if(articles.isNullOrEmpty().not()){

            articles?.let {
                OnSuccessArticle(
                    modifier = Modifier.padding(paddingValues), // Adjust for Scaffold's padding
                    posts = it,
                    onClickReadMore = {slug ->
                        rememberedComponent.openPost(slug)
                    }
                )
            }
        }
    }
}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun OnSuccessArticle(
    modifier: Modifier,
    posts: List<Post?>,
    onClickReadMore: (String) -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    val columns = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> 2
        WindowWidthSizeClass.Medium -> 3
        WindowWidthSizeClass.Expanded -> 4
        else -> 2
    }
    LazyVerticalGrid(
        modifier = modifier,
        columns = GridCells.Fixed(columns),
        contentPadding = PaddingValues(16.dp),
        horizontalArrangement = Arrangement.spacedBy(8.dp),
        verticalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        items(posts.size) { index ->
            posts[index]?.let { PostCard(it,onClickReadMore) }
        }
    }
}

@Composable
fun PostCard(
    item: Post,
    onClickReadMore:(String)->Unit
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(8.dp),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_MEDIUM)
    ) {
        Column(
            modifier = Modifier
                .padding(16.dp)
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            AsyncImage(
                model = item.imageUrl,
                contentDescription = "",
                modifier = Modifier
                    .fillMaxWidth()
                    .height(150.dp),
                contentScale = ContentScale.FillWidth
            )
            Spacer(modifier = Modifier.height(8.dp))
            Text(text = item.metaTitle.toString(), style = MaterialTheme.typography.titleMedium)
            Spacer(modifier = Modifier.height(4.dp))
            Text(
                text = item.metaDescription.toString(),
                style = MaterialTheme.typography.bodyMedium,
                maxLines = 2,
                overflow = TextOverflow.Ellipsis
            )
            TextButton(onClick = {
                onClickReadMore.invoke(item.url ?: "")
            }) {
                Text(text = "Read More")
            }
        }
    }
}