package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.razorpay_order_id


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Referbalance(
    @SerialName("amount")
    val amount: String?,
    @SerialName("cash_amount")
    val cashAmount: String?,
    @SerialName("coin_percentage_value")
    val coinPercentageValue: String?,
    @SerialName("gst_percentage")
    val gstPercentage: Int?,
    @SerialName("gst_percentage_amount")
    val gstPercentageAmount: String?,
    @SerialName("redeem_percentage")
    val redeemPercentage: String?,
    @SerialName("remain_coin")
    val remainCoin: Int?,
    @SerialName("total_amount")
    val totalAmount: String?,
    @SerialName("total_coin")
    val totalCoin: Int?,
    @SerialName("used_coin")
    val usedCoin: String?
)