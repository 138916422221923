package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.widget

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallHostPage
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_duration_dailog.ChatConsultationStarter
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_common.persentation.favourite_tab.CategoryConsultListRowClickListener
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.ExpertListPageEvent

@Composable
fun CategoryConsultListRowClickListenerImpl(component: ExpertListPageComponent): CategoryConsultListRowClickListener {

    val openDialog = remember { mutableStateOf(false) }
    if (openDialog.value) {
        openDialog(openDialog)
    }
    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    if (isChatClickSelected != null) {
        ChatConsultationStarter(
            chatDetails = isChatClickSelected!!,
            onLoginClicked = {
                component.event(ExpertListPageEvent.onLoginClick)
            },
            onDismiss = {
                isChatClickSelected = null
            },
            onStartChatConsultation = { duration, category ->
                component.event(
                    ExpertListPageEvent.OnChatClick(
                        expertId = isChatClickSelected?.id.toString(),
                        expertName = isChatClickSelected?.full_name.toString(),
                        expertImage = isChatClickSelected?.profile_image_url ?: isChatClickSelected?.profile_image
                            ?: "",
                        expertSkills = isChatClickSelected?.skills.toString(),
                        duration = duration,
                        category = category

                    )
                )
            },
            context = component,
            onRechargeAgain = { rechargeData ->
                component.event(
                    ExpertListPageEvent.RequiredRecharge(rechargeData)
                )
            },
            lowBalanceRecharge = {
                component.onLowBalance()
            }
        )
    }


    if (isCallClickSelected != null) {

//        val isUserLogin = UserInfoProvider.getIsLogin()
//
//        if(isUserLogin.not()){
//            component.event(ExpertListPageEvent.onLoginClick)
//        }else{
//
//        }

        CallHostPage(
            callDetails = isCallClickSelected!!,
            onLoginClicked = {
                component.event(ExpertListPageEvent.onLoginClick)
            },
            onDismiss = {
                isCallClickSelected = null
            },
            onStartCallConsultation = {
                component.event(
                    ExpertListPageEvent.onCallClick(
                        expertId = isCallClickSelected?.id.toString(),
                        expertName = isCallClickSelected?.full_name!!,
                        expertImage = isCallClickSelected?.profile_image_url!!,
                        expertSkills = isCallClickSelected?.skills!!
                    )
                )
            },
            context = component,
            onNavigateRechargeCheckOut = { rechargeData ->
                component.event(
                    ExpertListPageEvent.RequiredRecharge(rechargeData)
                )
            },
            onVoIpCallStart = { duration, commonExpertInfo ->
                component.event(
                    ExpertListPageEvent.OnVoIPCallConsultationStart(
                        duration,
                        commonExpertInfo
                    )
                )
            },
            onNavigateCallWaitingPage = {  CommonExpertInfo,onClose ->
              //  onNavigateCallWaitingPage.invoke(CommonExpertInfo,onClose)
            }
        )
    }

    // Define the listener object
    return object : CategoryConsultListRowClickListener {
        override fun onItemClicks(position: Int, expert: RequestConsultant) {
            component.event(ExpertListPageEvent.onExpertCardClick(expertId = expert.id!!))
        }

        override fun onChatClick(position: Int, expert: RequestConsultant) {
            // Show the dialog when the chat icon is clicked
            isChatClickSelected = expert
        }

        override fun onCallClick(position: Int, expert: RequestConsultant) {
            isCallClickSelected = expert
//            component.event(
//                ExpertListPageEvent.onCallClick(
//                    expertId = expert.id.toString(),
//                    expertName = expert.full_name!!,
//                    expertImage = expert.profile_image_url!!,
//                    expertSkills = expert.skills!!
//                )
//            )
        }

        override fun onConsultNowClick(position: Int, expert: RequestConsultant) {
            showToast("${expert.full_name}")
        }
    }

}