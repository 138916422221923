package tta.destinigo.talktoastro.feature_winyway_wallet.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerifyRechargeStatusRequest(
    @SerialName("razorpay_order_id") val razorPayOrderId: String,
    @SerialName("razorpay_signature") val razorPaySignature: String,
    @SerialName("razorpay_payment_id") val paymentId: String,
    @SerialName("domain") val domain : String
)