package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.custom_order_parches

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderPurchaseHistory(
    @SerialName("created_at") val createdAt: String, // 2024-05-10 16:05:40
    @SerialName("custom_order_id") val customOrderId: String, // 10
    @SerialName("custom_order_price") val customOrderPrice: Int, // 1299
    @SerialName("id") val id: Int, // 1
    @SerialName("updated_at") val updatedAt: String, // 2024-05-10 16:05:40
    @SerialName("user_id") val userId: Int // 84
)
