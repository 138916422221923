package tta.destinigo.talktoastro.feature_courses.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_courses.data.CoursesApi
import tta.destinigo.talktoastro.feature_courses.data.request.CourseDetailsSubmitForm

class LandingPageRepo(val api: CoursesApi) {

    fun submitCourseDetails(request: CourseDetailsSubmitForm):Flow<Resources<Boolean>>{
        return flow {
            emit(Resources.Loading())
            val response = api.postCourseDetailsRemote(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}