package tta.destinigo.talktoastro.feature_custom_order.custom_order_details

import androidx.compose.ui.util.trace
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.CustomOrderListData
import tta.destinigo.talktoastro.feature_custom_order.data.request.SetEnrollRequest
import tta.destinigo.talktoastro.feature_custom_order.domain.CustomOrderListRepo
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

class CustomOrderDetailsComponent(
    context: ComponentContext,
    private val onBackPress:()->Unit,
    private val _data: CustomOrderListData,
    private val onLoginClick:()-> Unit,
    private val onEnrollNowClick:(RechargeData,String,String)->Unit
): ComponentContext by context, KoinComponent {

    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()
    }
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    private val api: CustomOrderListRepo by inject()

    val data get() = _data
    fun onBackClick() = onBackPress.invoke()
    fun onLoginButtonClick() = onLoginClick.invoke()


    init {
        lifecycle.doOnStart {
            Logger.d { "Custom Order Details Screen Component onStart" }
            coroutineScope.launch {

            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Custom Order Details Screen Component onDestroy" }
            coroutineScope.cancel()
        }
    }


    private suspend fun enrollCustomOrder(){
        val request = SetEnrollRequest(custmerOrderId = data.id.toString())
        //Logger.d { "enrollCustomOrder id ${data.id}" }

        api.setEnrollCustomOrder(request).collect{
           // Logger.d { "enrollCustomOrder id ${it}" }
            when(it){
                is Resources.Error -> {
                    //Logger.d { "enrollCustomOrder error ${it.msg}" }
                    coroutineScope.launch {
                        showToast("Unexpected Error Occurred")
                    }
                }
                is Resources.Loading -> {
                    Logger.d { "enrollCustomOrder loading ${it.isLoading}" }
                }
                is Resources.Success -> {
                    //Logger.d { "$it" }
                    if(it.data?.service.equals("Paid", true)){
                        onEnrollNowClick.invoke(RechargeData(amount = it.data?.amount, id = it.data?.txnDetails?.id, planName = null, cupon = null),data.image.toString(),data.heading.toString())
                    }else{
                        Logger.d { "Free Custom Order" }
                        onEnrollNowClick.invoke(RechargeData(amount = it.data?.amount, id = it.data?.txnDetails?.id, planName = null, cupon = null),data.image.toString(),data.heading.toString())
                    }
                   // Logger.d { "enrollCustomOrder success ${it.data}" }
                }
            }
        }
    }

    fun getEnrollCustomOrder() = coroutineScope.launch {
        enrollCustomOrder()
    }
}
