package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ExpertInfo(
    @SerialName("image")
    val image: String?,
    @SerialName("mobile")
    val mobile: String,
    @SerialName("name")
    val name: String,
    @SerialName("skills")
    val skills: String?
)