package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.remote

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderHistory
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class TransactionHistoryApiService : KtorApi() {
    companion object{
        const val ORDER_TRANSACTION_HISTORY = "transaction-history"
    }

     private suspend fun getTransactionHistory() = client.get {
         url(ORDER_TRANSACTION_HISTORY)
    }.body<TransactionOrderHistory>()

    suspend fun getTransactionHistoryInfo(): Resources<List<TransactionOrderData>>{
        return withContext(Dispatchers.Default){
            try {
                val transactionHistoryResponse = getTransactionHistory()

                if(transactionHistoryResponse.status.not()){
                    Logger.d("Error state ${transactionHistoryResponse.message} ")
                    Logger.d { "TransactionHistoryApiService getTransactionHistoryInfo Error ${transactionHistoryResponse.message}"}
                    return@withContext Resources.Error(transactionHistoryResponse.message ?: "Unexpected Error")
                }

                if (transactionHistoryResponse.data.isNullOrEmpty()) {
                    Logger.e { "Empty transaction history data" }
                    return@withContext Resources.Error("Empty transaction history data")
                }

                Logger.d { "TransactionHistoryApiService getTransactionHistoryInfo success ${transactionHistoryResponse.data}"}
                return@withContext Resources.Success(transactionHistoryResponse.data)
            } catch (e: ClientRequestException) {
                Logger.e { "Client request error: ${e.message}, response: ${e.response}" }
                Resources.Error("Client request error: ${e.message}")
            }
            catch (e: ServerResponseException) {
                Logger.e { "Server response error: ${e.message}, response: ${e.response}" }
                Resources.Error("Server response error: ${e.message}")
            }
            catch (e: Exception) {
                Logger.e { "Unexpected error: ${e.message}" }
                Resources.Error("Unexpected error: ${e.message}")
            }

        }
    }
}