package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_search_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.SearchExpertApi
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.request.SearchExpertRequest
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class SearchScreenComponent(
    context: ComponentContext,
    private val onBackPress:()->Unit,
    private val onClickExpertCard: (Int) -> Unit,
    //private val onCallButtonClick: (String, String, String, String) -> Unit,
    //private val onChatButtonClick: (String, String, String, String, String,String) -> Unit,
): ComponentContext by context, KoinComponent {

    fun event(event: SearchScreenEvent) {
        when (event) {
            SearchScreenEvent.OnBackKeyPress -> onBackPress.invoke()
        }
    }
    fun onClickExpertCard(id:Int) = onClickExpertCard.invoke(id)
    // fun onCallButtonClick( expertId: String, expertName: String, expertImage: String, expertSkills: String) = onCallButtonClick.invoke(expertId,expertName,expertImage,expertSkills)
   // fun onChatButtonClick( expertId: String, expertName: String, expertImage: String, expertSkills: String,duration:String,category:String) = onChatButtonClick.invoke(expertId,expertName,expertImage,expertSkills)
    fun onBackClick() = onBackPress.invoke()
    init {
        lifecycle.doOnStart {
            Logger.d { "Search Screen Component onStart" }

        }

        lifecycle.doOnDestroy {
            Logger.d { "Search Screen Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }

    }

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val searchExpertApi: SearchExpertApi by inject()
    private val _state = MutableStateFlow<List<RequestConsultant>>(emptyList())
    val state: StateFlow<List<RequestConsultant>> get() = _state

//    fun getExpertByRemote(request: SearchExpertRequest) {
//        coroutineScope.launch {
//            searchExpertApi.getExpertListRemote(request).collect {
//                when (it) {
//                    is Resources.Success -> {
//                        //_state.value = (it.data?.consultants ?: emptyList()) as List<RequestConsultant>
//                        _state.value = it.data?.consultants?.data!!
//                    }
//
//                    else -> Unit
//                }
//            }
//        }
//    }

    suspend fun getExpertByRemote(request: SearchExpertRequest): List<RequestConsultant> {
        return try {
            Logger.d { "Request: $request" } // Log request details
            val response = searchExpertApi.getExpertListRemote(request)
            Logger.d { "Response: $response" } // Log raw response

            when (response) {
                is Resources.Success -> {
                    val data = response.data?.consultants?.data
                    Logger.d { "Parsed Data: $data" }
                    data ?: emptyList()
                }
                is Resources.Error -> {
                    Logger.e { "Error: ${response.msg}" }
                    emptyList()
                }
                else -> {
                    Logger.w { "Unhandled response type: $response" }
                    emptyList()
                }
            }
        } catch (e: Exception) {
            Logger.e(e) { "Exception occurred during API call: ${e.message}" }
            emptyList()
        }
    }


}