package tta.destinigo.talktoastro.feature_courses.persentation.courses_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_courses.domain.CoursesRepo

class CoursesComponent(
    context: ComponentContext,
    private val onBackButtonClick: () -> Unit,
    private val onRegisterButtonClick: (slug: String) -> Unit
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)
    private val api: CoursesRepo by inject()

    private val _state = MutableStateFlow<Resources<CourseModelList>>(Resources.Loading(true))
    val state get() = _state.asStateFlow()

    private val _courses = MutableStateFlow<CourseModelList?>(null)
    val courses get() = _courses.asStateFlow()

    private var allCourses: CourseModelList? = null
    fun onBackPress() = onBackButtonClick.invoke()
    fun onRegisterButtonClickNow(courseData: CourseModel) = onRegisterButtonClick.invoke(
        courseData.slug ?: ""
    )

    fun onCategoryChange(category: String) {
        val data = filterCoursesByCategory(category)
        _courses.value = CourseModelList(data, allCourses?.categoryList)
    }

    private fun filterCoursesByCategory(category: String): List<CourseModel>? {
        return if (category == "All") {
            allCourses?.data // Return all courses if "All" is selected
        } else {
            allCourses?.data?.filter { it.categoryType == category } // Filter by category
        }
    }

    init {
        lifecycle.doOnStart {
            Logger.d { "Landing Page onStart" }
        }

        lifecycle.doOnCreate {
            coroutineScope.launch {
                getCoursesByRemote()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    private suspend fun getCoursesByRemote() {
        api.getCourses().collectLatest {
            when (it) {
                is Resources.Error -> {
                    printLog("Courses Response Error -> ${it.msg}")
                    _state.emit(Resources.Error(it.msg))
                }

                is Resources.Loading -> {
                    printLog("Courses Response Loading -> ${it.isLoading}")
                    _state.emit(Resources.Loading(it.isLoading))
                }

                is Resources.Success -> {
                    printLog("Courses Response Success -> ${it.data}")
                    _state.emit(Resources.Success(it.data))
                    _courses.emit(it.data)
                    allCourses = it.data

                }
            }

        }
    }

    private fun printLog(msg: String) {
        Logger.d("COURSE_LIST_PAGE - $msg")
    }

}