package tta.destinigo.talktoastro.feature_chat_consultation_new.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatConcernItem(
    @SerialName("answer") val answer: String, // Jyoti Chaudhuri
    @SerialName("character_length") val characterLength: Int, // 100
    @SerialName("input_type") val inputType: String, // Character
    @SerialName("question") val question: String?, // Enter Cosultant Name
    @SerialName("file") val imageUrl: String?, // "https:\/\/prod.winyway.com\/images\/chat\/gallery\/chat3N7SVd06-38-33.jpg"
    @SerialName("image_url_list")val imageList:List<String>?
)
