package tta.destinigo.talktoastro.feature_call_consultation.data.response.start_voip_call

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StartCallConsultationResponse(
    @SerialName("data") val data: StartCallConsultationData?,
    @SerialName("status") val status: Boolean,
    @SerialName("message") val msg: String?,
)

