package tta.destinigo.talktoastro.feature_article.data

import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.url
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.remote.makeApiCall
import tta.destinigo.talktoastro.feature_article.data.response.GetPostResponse
import tta.destinigo.talktoastro.feature_article.data.response.GetPostsResponse
import tta.destinigo.talktoastro.feature_article.data.response.Post

class PostFeedApi : KtorApi() {

    companion object {
        private const val POST_LIST = "article"
    }

    private suspend fun getPostList() = client.get {
        url(POST_LIST)
    }.body<GetPostsResponse>()

    suspend fun getPostListApi(): Resources<List<Post?>> {
        return makeApiCall(
            apiCall = { getPostList() },
            transform = { it.data ?: emptyList() }
        )
    }

    private suspend fun getPostDetail(slug: String) = client.post {
        url("$POST_LIST/$slug")
    }.body<GetPostResponse>()

    suspend fun getPostDetailApi(slug: String): Resources<Post> {
        return makeApiCall(
            apiCall = { getPostDetail(slug) },
            transform = { it.data }
        )
    }

}