package tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.feature_common.data.request.RequestCategory
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.astrologer_icon
import tta.destinigo.talktoastro.resources.astrology_courses
import tta.destinigo.talktoastro.resources.banner1
import tta.destinigo.talktoastro.resources.winyway_button_spiritual

@Composable
fun TopCategoryList(
    onCategoryClick: (RequestCategory) -> Unit,
    component: HomeScreenTabComponent,
    modifier: Modifier
) {
    val categoryData by component.topCategory.collectAsState()

    if (categoryData.isNullOrEmpty()) return

    val rearrangedCategories = categoryData
        ?.sortedBy { category ->
            if (category.name == "Scientific Astrology") 1 else 0
        }

    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = modifier
                .wrapContentHeight()
                .padding(horizontal = Scale.HORIZONTAL_PADDING),
            horizontalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            rearrangedCategories?.forEach { category ->
                Box(
                    modifier = Modifier
                        .weight(1f)
                ) {
                    CategoryCard(
                        categoryName = category,
                        onCategoryClick = onCategoryClick
                    )
                }
            }
        }
    }
}



@Composable
fun CategoryCard(
    categoryName: RequestCategory,
    onCategoryClick: (RequestCategory) -> Unit,
) {
    Card(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_EXTRA_LARGE),
        modifier = Modifier
            .fillMaxWidth()
            //.width(100.dp)
            //.height(120.dp)
            .wrapContentHeight()
            .clickable { onCategoryClick(categoryName) }
    ) {
        val resImage = when (categoryName.name) {
            "Mental Wellness Therapist" -> Res.drawable.winyway_button_spiritual
            "Astrologer" -> Res.drawable.banner1
            "Scientific Astrology" -> Res.drawable.astrology_courses
            else -> Res.drawable.astrologer_icon
        }

        Image(
            painter = painterResource(resource = resImage),
            contentDescription = "",
            contentScale = ContentScale.FillBounds,
            modifier = Modifier
                .fillMaxSize()
                .clip(RoundedCornerShape(8.dp))
        )
    }
}
