package tta.destinigo.talktoastro.feature_common.data.remote

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.local.TokenProvider
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.data.response.wallet_balance.WalletRupeeResponse
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.http.headers
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class FatchWalletBalenceApiService:KtorApi() {

    companion object {
        const val GET_WALLET_MONEY = "get-wallet-balance"
    }


    suspend fun getWalletMoney(): Resources<String> {
        return withContext(Dispatchers.Default) {
            try {
                val token = TokenProvider.getNewToken() // Retrieve the token from your TokenProvider or equivalent
                Logger.d { " FatchWalletBalenceApiService getWalletMoney $token" }
                val topConsultantResponse = client.get(GET_WALLET_MONEY) {

                    headers {
                        append("Authorization", "Bearer $token")
                    }
                }.body<WalletRupeeResponse>()

                if (topConsultantResponse.status.not()) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message ?: "Unexpected Error"
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data.balance)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

}