package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderLiveSession(
    @SerialName("content_access") val contentAccess: String?, // One to One
    @SerialName("created_at") val createdAt: String?, // 2024-05-13 13:50:13
    @SerialName("custom_order_id") val customOrderId: Int, // 7
    @SerialName("days") val days: String?, // Sunday,Monday
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("expert_id") val expertId: Int?, // 200
    @SerialName("from_date") val fromDate: String?, // 2024-05-13
    @SerialName("id") val id: Int?, // 1
    @SerialName("time") val time: String?, // 09:20:00
    @SerialName("to_date") val toDate: String?, // 2024-05-15
    @SerialName("total_session") val totalSession: Int?, // 1
    @SerialName("updated_at") val updatedAt: String? // 2024-05-13 13:50:13
)
