package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response



import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VoipCallDetails(
    @SerialName("amount") val amount: String?, // 0
    @SerialName("booking_date_time") val bookingDateTime: String?, // 2024-09-16 13:29:59
    @SerialName("call_booking_date") val callBookingDate: String?, // 16-09-2024
    @SerialName("call_booking_time") val callBookingTime: String?, // 2024-09-16 13:30:00
    @SerialName("call_duration") val callDuration: Int?, // 16
    @SerialName("call_minutes") val callMinutes: Int, // 0
    @SerialName("call_place_again") val callPlaceAgain: Int, // 0
    @SerialName("created_at") val createdAt: String?, // 2024-09-16 13:29:34
    @SerialName("currency") val currency: String?, // INR
    @SerialName("exotel_call_duration") val exotelCallDuration: String?, // 16
    @SerialName("exotel_call_status") val exotelCallStatus: String?, // End
    @SerialName("expert_id") val expertId: Int, // 5
    @SerialName("expert_info") val expertInfo: ExpertInfo?,
    @SerialName("expert_payment") val expertPayment: String?, // null
    @SerialName("expert_price") val expertPrice: String?, // 6
    @SerialName("flash_off") val flashOff: String?, // 1
    @SerialName("id") val id: Int, // 34670
    @SerialName("international_rate") val internationalRate: String?, // 14
    @SerialName("parent_id") val parentId: Int, // 0
    @SerialName("ramaining_balance") val ramainingBalance: String?, // 369
    @SerialName("sid") val sid: String?, // vt8HWVw4gOlGp6a9zTAStPjDdEw8RJjq
    @SerialName("slot_for") val slotFor: String?, // Voip Call
    @SerialName("updated_at") val updatedAt: String?, // 2024-09-16 13:30:16
    @SerialName("user_id") val userId: Int, // 84
    @SerialName("user_rating") val userRating: String?, // null
    @SerialName("user_review") val userReview: String?, // null
    @SerialName("voip_end_by") val voipEndBy: String?, // user
    @SerialName("voip_end_datetime") val voipEndDatetime: String?, // 2024-09-16 13:30:16
    @SerialName("voip_start_datetime") val voipStartDatetime: String?, // 2024-09-16 13:30:00
    @SerialName("winyway_payment") val winywayPayment: String?, // null
    @SerialName("winyway_price") val winywayPrice: String? // 8
)