package tta.destinigo.talktoastro.core.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
open class CommonResponse(
    @SerialName("status")val status:Boolean = false,
    @SerialName("message")val msg:String = "Unknown Error",
    @SerialName("code")val code:Int? = 0
)
