package tta.destinigo.talktoastro.feature_courses.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_courses.data.CoursesApi
import tta.destinigo.talktoastro.feature_courses.data.response.courses.CourseData
import tta.destinigo.talktoastro.feature_courses.persentation.courses_page.CourseModelList

class CoursesRepo(
    val api: CoursesApi
) {

    fun getCourses(): Flow<Resources<CourseModelList>> {
        return flow {
            try {
                // Emit loading state
                emit(Resources.Loading(true))

                // Fetch API response
                val response = api.getCoursesApi()

                // Emit success or error based on response
                if (response is Resources.Success) {

                    if (response.data.isNullOrEmpty()) {
                        emit(Resources.Error("No Course Found"))
                        return@flow
                    }

                    emit(
                        Resources.Success(
                            CourseModelList(
                                data = response.data.map { it.toCourseModel() },
                                categoryList = getCategoryListWithAll(response.data)
                            )
                        )
                    )

                } else if (response is Resources.Error) {
                    emit(Resources.Error(response.msg))
                }
            } catch (e: Exception) {
                // Handle exceptions by emitting an error state
                emit(Resources.Error("An error occurred: ${e.message}"))
            } finally {
                // Emit loading state as false when done
                emit(Resources.Loading(false))
            }
        }
    }
}

fun getCategoryListWithAll(courseDataList: List<CourseData>): List<String> {
    return listOf("All") + courseDataList
        .mapNotNull { it.categoryType } // Extract categoryType and ignore nulls
        .distinct() // Get unique values
}