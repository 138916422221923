package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatList(
    @SerialName("chat_id")
    val chatId: Int,
    @SerialName("date_time")
    val dateTime: String,
    @SerialName("duration")
    val duration: String,
    @SerialName("expert_id")
    val expertId: Int?,
    @SerialName("expert_name")
    val expertName: String?,
    @SerialName("expert_profile_image")
    val expertProfileImage: String?,
    @SerialName("expert_skills")
    val expertSkills: String?,
    @SerialName("per_min_price")
    val perMinPrice: Int?,
    @SerialName("status")
    val status: String?,
    @SerialName("type")
    val type: String?,
    @SerialName("user_name")
    val userName: String?,
    @SerialName("user_profile_image")
    val userProfileImage: String?,
    @SerialName("user_rating")
    val userRating: String?,
    @SerialName("user_review")
    val userReview: String?
)
