package tta.destinigo.talktoastro.feature_chat_consultation_new.di

import tta.destinigo.talktoastro.feature_chat_consultation_new.data.api.ChatConsultationApi
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatConcernRepo
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatConsultationInfoRepo
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatRoomRepo
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatWaitingRepo
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.api.AstrologyApi
import org.koin.dsl.module

val chatConsultationModule = module {
//    single { ChatConsultationApi() }
//    single { AstrologyApi() }
//    single { ChatConsultationInfoRepo(get()) }
//    single { ChatWaitingRepo(get()) }
//    single { ChatConcernRepo(get(),get()) }
//    single { ChatRoomRepo(get()) }
    factory { ChatConsultationApi() }
    factory { AstrologyApi() }
    factory { ChatConsultationInfoRepo(get()) }
    factory { ChatWaitingRepo(get()) }
    factory { ChatConcernRepo(get(),get()) }
    factory { ChatRoomRepo(get()) }

}