package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.widget


import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.md_them_primary
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.ExpertSearchFieldPreview
import tta.destinigo.talktoastro.feature_expert_consultation.data.RechargeData
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.ExpertListPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.ExpertListPageEvent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_auto_premium_24
import tta.destinigo.talktoastro.resources.baseline_voice_chat_24
import tta.destinigo.talktoastro.resources.ic_heart_primary_color_fill
import tta.destinigo.talktoastro.resources.round_sort_24
import kotlin.math.round

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun ExpertListSuccessPage(
    consultants: List<RequestConsultant>, component: ExpertListPageComponent, isFreeApplicable: Boolean
) {
    var bottomSheetState by remember { mutableStateOf(false) }
    var selectedOption by remember { mutableStateOf("Popularity") }
    var selectedSubCategory by remember { mutableStateOf("All") }
    val subCategoryState by component.subCategories.collectAsState()

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    var scrollIndex by remember { mutableStateOf(0) }

    Box(
        modifier = Modifier.fillMaxSize().background(background_color),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(background_color),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Column(
                modifier = widthModifier
                    .fillMaxWidth()
                    .weight(1f)
            ) {

                CommonTopAppBar(
                    modifier = Modifier,
                    title = component._categoryName.toString(),
                    onBackClick = {component.event(ExpertListPageEvent.OnBackPressed)}
                )
                Spacer(Modifier.height(10.dp))
                if (scrollIndex == 0) { ExpertSearchFieldPreview(component) }

                if (scrollIndex == 0) {
                    subCategoryState?.let {
                        ChipGroup(chipItems = it, onChipSelected = { selectedChip ->
                            selectedSubCategory = selectedChip
                        })
                    }
                }
                ConsultantList(
                    component,
                    selectedOption,
                    selectedSubCategory,
                    consultants,
                    isFreeCallApplicable = isFreeApplicable,
                    onScrollStateChanged = { index ->
                        scrollIndex = index
                    }
                )
            }

            if (bottomSheetState) {
                BottomSheetContent(
                    selectedOption = selectedOption,
                    onOptionSelected = { option ->
                        selectedOption = option
                    },
                    onDismiss = { bottomSheetState = false },
                    bottomPadding = 16
                )
            }
            if (scrollIndex == 0) {
                BottomMenuItem(
                    text1 = "Sort By",
                    imageResId1 = Res.drawable.round_sort_24,
                    onClick1 = { bottomSheetState = true },
                    text2 = "Free",
                    imageResId2 = Res.drawable.baseline_voice_chat_24,
                    onClick2 = { selectedOption = "Free" },
                    text3 = "Premium",
                    imageResId3 = Res.drawable.baseline_auto_premium_24,
                    onClick3 = { selectedOption = "Premium" },
                )
            }
        }
    }
}


@Composable
fun ActionButton(
    text: String, icon: Painter, onClick: () -> Unit, buttonColor: Color
) {
    Button(onClick = onClick,
        modifier = Modifier,
//            .fillMaxWidth(),
        colors = ButtonDefaults.buttonColors(buttonColor),
        shape = RoundedCornerShape(4.dp),
        elevation = ButtonDefaults.elevatedButtonElevation(3.dp),
        contentPadding = PaddingValues(horizontal = 8.dp),
        content = {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(
                    painter = icon,
                    contentDescription = null,
                    modifier = Modifier.size(40.dp),
                    tint = Color.White
                )
                Spacer(modifier = Modifier.width(15.dp))
                Text(
                    text = text, color = Color.White, maxLines = 1, textAlign = TextAlign.Center
                )
            }
        })
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ActionIconButton(
    icon: Painter, onClick: () -> Unit
) {
    var clicked by remember { mutableStateOf(false) }

    val iconToShow = if (clicked) {
        painterResource(Res.drawable.ic_heart_primary_color_fill)
    } else {
        icon
    }

    IconButton(onClick = {
        clicked = !clicked
        onClick()
    }, modifier = Modifier.size(48.dp), content = {
        Icon(
            painter = iconToShow,
            contentDescription = null,
            tint = if (clicked) md_them_primary else md_them_primary,
            modifier = Modifier.size(24.dp)
        )
    })
}


//
//@OptIn(ExperimentalResourceApi::class)
//@Composable
//fun BottomMenuItem(
//    text1: String,
//    imageResId1: DrawableResource? = null,
//    text2: String,
//    imageResId2: DrawableResource? = null,
//    text3: String,
//    imageResId3: DrawableResource? = null,
//    onClick1: () -> Unit, // Change the lambda type
//    onClick2: () -> Unit,
//    onClick3: () -> Unit
//) {
//    Row(
//        verticalAlignment = Alignment.CenterVertically,
//        horizontalArrangement = Arrangement.SpaceEvenly,
//        modifier = Modifier.fillMaxWidth()
//    ) {
//        // First item
//        Box(
//            modifier = Modifier.clickable {
//                // Call the lambda directly
//                onClick1()
//            }
//        ) {
//            Row(
//                verticalAlignment = Alignment.CenterVertically,
//            ) {
//                imageResId1?.let {
//                    Image(
//                        painter = painterResource(imageResId1),
//                        contentDescription = null,
//                        modifier = Modifier.size(24.dp),
//                        alignment = Alignment.CenterStart,
//                        colorFilter = ColorFilter.tint(Color.Black)
//                    )
//                    Spacer(modifier = Modifier.width(8.dp)) // Add spacing between image and text
//                }
//                Text(
//                    text = text1,
//                    textAlign = TextAlign.Center
//                )
//            }
//        }
//
//        // Second item
//        Box(
//            modifier = Modifier.clickable { onClick2() }
//        ) {
//            Row(
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                imageResId2?.let {
//                    Spacer(modifier = Modifier.width(8.dp)) // Add spacing between items
//                    Image(
//                        painter = painterResource(imageResId2),
//                        contentDescription = null,
//                        modifier = Modifier.size(24.dp),
//                        alignment = Alignment.CenterStart,
//                        colorFilter = ColorFilter.tint(Color.Black)
//                    )
//                    Spacer(modifier = Modifier.width(8.dp)) // Add spacing between image and text
//                }
//                Text(
//                    text = text2,
//                    textAlign = TextAlign.Center
//                )
//            }
//        }
//
//        // Third item
//        Box(
//            modifier = Modifier.clickable { onClick3() }
//        ) {
//            Row(
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                imageResId3?.let {
//                    Spacer(modifier = Modifier.width(8.dp))
//                    Image(
//                        painter = painterResource(imageResId3),
//                        contentDescription = null,
//                        modifier = Modifier.size(24.dp),
//                        alignment = Alignment.CenterStart,
//                        colorFilter = ColorFilter.tint(Color.Black)
//                    )
//                    Spacer(modifier = Modifier.width(8.dp)) // Add spacing between image and text
//                }
//                Text(
//                    text = text3,
//                    textAlign = TextAlign.Center
//                )
//            }
//        }
//    }
//}


@Composable
fun YourComposable(
    onClick1: () -> Unit,
    bottomSheetState: Boolean,
    onDismissRequest: () -> Unit,
    onItemSelected: (Int) -> Unit,
    items: List<String>,
    selectedItemIndex: Int
) {

    if (bottomSheetState) {

        BottomSheet(bottomSheetState, onDismissRequest, onItemSelected, items, selectedItemIndex)

    }

}


@Composable
fun BottomSheet(
    bottomSheetState: Boolean,
    onDismissRequest: () -> Unit,
    onItemSelected: (Int) -> Unit,
    items: List<String>,
    selectedItemIndex: Int
) {
    if (bottomSheetState) {
//        ModalBottomSheet(onDismissRequest = onDismissRequest) {
//            Surface(
//                modifier = Modifier.fillMaxWidth(),
//                shape = RoundedCornerShape(topStart = 16.dp, topEnd = 16.dp),
//                tonalElevation = 16.dp
//            ) {
//                Column(
//                    modifier = Modifier.padding(16.dp)
//                ) {
//                    items.forEachIndexed { index, item ->
//                        Row(
//                            verticalAlignment = Alignment.CenterVertically,
//                            modifier = Modifier.clickable {
//                                onItemSelected(index)
//                                onDismissRequest()
//                            }
//                        ) {
//                            RadioButton(
//                                selected = selectedItemIndex == index,
//                                onClick = {
//                                    onItemSelected(index)
//                                },
//                                modifier = Modifier.padding(end = 8.dp)
//                            )
//                            Text(text = item)
//                        }
//                        Divider() // Add a divider between items
//                    }
//                }
//            }
//        }
//    }
    }

    @Composable
    fun DropdownMenuWithRadioButtons(
        items: List<String>,
        selectedIndex: Int,
        onItemSelected: (Int) -> Unit,
        expanded: Boolean,
        onDismissRequest: () -> Unit
    ) {
        DropdownMenu(
            expanded = expanded, onDismissRequest = onDismissRequest
        ) {
            items.forEachIndexed { index, item ->
                DropdownMenuItem(text = {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        RadioButton(
                            selected = selectedIndex == index,
                            onClick = null // No need to handle click directly within RadioButton
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(text = item)
                    }
                }, onClick = {
//                    expanded = false
                    onItemSelected(index)
                })
            }
        }
    }


//@Composable
//fun ErrorBottomSheetCallConsultation(
//    sheetState: SheetState,
//    onDismissRequest: () -> Unit
//) {
//    ModalBottomSheet(
//        sheetState = sheetState,
//        onDismissRequest = { onDismissRequest() }) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .align(Alignment.CenterHorizontally)
//        ) {
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = "Consultation Details",
//                    style = TextStyle(fontWeight = FontWeight.Bold),
//                    modifier = Modifier.padding(top = 12.dp)
//                )
//            }
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
//                    .padding(top = 16.dp)
//                    .clip(RoundedCornerShape(8.dp)),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//
//                Image(painter = painterResource(Res.drawable.ellipse), contentDescription = null)
//                Spacer(modifier = Modifier.width(16.dp))
//                Column {
//                    Text(
//                        text = "Rahul Kumar",
//                        style = TextStyle(fontWeight = FontWeight.Bold),
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = "Advocate,Cricketer,Bowler and much more thing here",
//                        fontSize = 12.sp,
//                        maxLines = 2,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//            Spacer(modifier = Modifier.height(16.dp))
//            Box(
//                modifier = Modifier
//                    .padding(top = 16.dp, bottom = 20.dp)
//                    .fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = "Oops!",
//                    style = TextStyle(fontWeight = FontWeight.Bold)
//                )
//            }
//            Spacer(modifier = Modifier.height(8.dp))
//            Text(
//                text = "Unable to connect Expert, May be expert is offline or busy. Please try after sometime. you can contact us if you want support.",
//                modifier = Modifier.padding(bottom = 20.dp),
//                maxLines = 3,
//                fontSize = 12.sp,
//                textAlign = TextAlign.Center
//            )
//        }
//    }
//}


//@OptIn(ExperimentalMaterial3Api::class, ExperimentalResourceApi::class)
//@Composable
//fun BalanceNotEnoughLayout(
//    sheetState: SheetState,
//    onDismissRequest: () -> Unit
//) {
//    ModalBottomSheet(sheetState = sheetState, onDismissRequest = { onDismissRequest() }) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .align(Alignment.CenterHorizontally)
//        ) {
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = lowest_balance_to_chat_consultaion_txt,
//                    color = design_default_color_error
//                )
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
//                    .padding(top = 16.dp)
//                    .clip(RoundedCornerShape(8.dp))
//                    .background(Color.LightGray),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//
//                Image(painter = painterResource(Res.drawable.ellipse), contentDescription = null)
//                Spacer(modifier = Modifier.width(16.dp))
//                Column {
//                    Text(
//                        text = "Rahul Kumar",
//                        // style = MaterialTheme.typography.body1,
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = "Advocate,Cricketer,Bowler and much more thing here",
//                        // style = MaterialTheme.typography.body2,
//                        maxLines = 2,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//
//            Text(
//                text = "Recharge Now",
//                // style = MaterialTheme.typography.body2,
//                maxLines = 2,
//                modifier = Modifier.fillMaxWidth().padding(top = 12.dp)
//            )
//
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth(),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                Image(
//                    painter = painterResource(Res.drawable.baseline_tips_and_updates_24),
//                    contentDescription = null,
//                    colorFilter = ColorFilter.tint(md_them_primary)
//                )
//                Spacer(modifier = Modifier.width(4.dp))
//                Text(
//                    text = "Tip - 90% User Recharge More than 8 Minutes",
//                    // style = MaterialTheme.typography.body1,
//                    maxLines = 1,
//                    modifier = Modifier.fillMaxWidth(),
//                    fontSize = 13.sp
//                )
//            }
//            Spacer(modifier = Modifier.height(16.dp))
//            val rechargeDataList = listOf(
//                RechargeData(id = 0, amount = "50", cupon = "20% Extra"),
//                RechargeData(id = 1, amount = "100", cupon = "30% Extra"),
//                RechargeData(id = 2, amount = "300", cupon = "30% Extra"),
//                RechargeData(id = 3, amount = "400", cupon = "30% Extra"),
//                RechargeData(id = 4, amount = "500", cupon = "30% Extra"),
//                RechargeData(id = 5, amount = "600", cupon = "30% Extra"),
//                RechargeData(id = 6, amount = "700", cupon = "30% Extra"),
//                // Add more recharge data as needed
//            )
////            val selectedPosition = PositionManager()
////            val clickListener = RechargePackState(selectedPosition)
//
//            val selectedPosition =
//                remember { mutableStateOf(-1) } // Initialize with default selected index
//
//            RechargePackList(rechargeDataList, selectedPosition)
//
//
//            Spacer(modifier = Modifier.height(16.dp))
//            Button(
//                onClick = {},
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = "Recharge Now", color = Color.White)
//            }
//
//        }
//    }
//
//}


    @Composable
    fun RechargePackCard(
        rechargeData: RechargeData, isSelected: Boolean, onClick: () -> Unit
    ) {
        Box(
            modifier = Modifier.width(90.dp).height(55.dp).padding(3.dp)
                .clickable(onClick = onClick).background(
                    color = if (isSelected) md_them_primary else Color.White,
                    shape = RoundedCornerShape(8.dp)
                )
        ) {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center, // Center vertically
                horizontalAlignment = Alignment.CenterHorizontally // Center horizontally
            ) {
                Text(
                    text = "₹${rechargeData.amount}",
                    color = if (isSelected) Color.White else Color.Black,
                    modifier = Modifier.padding(horizontal = 4.dp)
                )
                Spacer(modifier = Modifier.height(1.dp))
                Text(
                    text = rechargeData.cupon,
                    modifier = Modifier.padding(horizontal = 4.dp),
                    color = if (isSelected) Color.White else md_them_primary,
                    fontSize = 10.sp
                )
            }
        }
    }


    @Composable
    fun RechargePackList(
        rechargeDataList: List<RechargeData>, selectedPosition: MutableState<Int>
    ) {
        LazyVerticalGrid(
            columns = GridCells.Fixed(4)
        ) {
            itemsIndexed(rechargeDataList) { index, rechargeData ->
                RechargePackCard(rechargeData = rechargeData,
                    isSelected = index == selectedPosition.value,
                    onClick = { selectedPosition.value = index })
            }
        }
    }
}


//@Composable
//fun LowBalanceOnFreeChat(
//    sheetState: SheetState,
//    onDismissRequest: () -> Unit
//) {
//    ModalBottomSheet(
//        sheetState = sheetState,
//        onDismissRequest = { onDismissRequest() }
//    ) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .align(Alignment.CenterHorizontally)
//        ) {
//            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
//                Text(
//                    text = "Consultation Details",
//                    style = TextStyle(fontWeight = FontWeight.Bold)
//                )
//            }
//
//            Spacer(modifier = Modifier.height(24.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
////                    .padding(top = 16.dp)
//                    .clip(RoundedCornerShape(8.dp)),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//
//                Image(painter = painterResource(Res.drawable.ellipse), contentDescription = null)
//                Spacer(modifier = Modifier.width(16.dp))
//                Column {
//                    Text(
//                        text = "Rahul Kumar",
//                        style = TextStyle(fontWeight = FontWeight.Bold),
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = "Advocate,Cricketer,Bowler and much more thing here",
//                        // style = MaterialTheme.typography.body2,
//                        maxLines = 2,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//
//            Spacer(modifier = Modifier.height(16.dp))
//            Box(
//                modifier = Modifier.fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Row(
//                    modifier = Modifier.fillMaxWidth(),
//                    verticalAlignment = Alignment.CenterVertically
//                ) {
//                    Image(
//                        painter = painterResource(Res.drawable.baseline_info_24),
//                        contentDescription = null,
//                        colorFilter = ColorFilter.tint(Color.Black)
//                    )
//                    Spacer(modifier = Modifier.width(8.dp)) // Add spacing between the image and text
//                    Text(
//                        text = "Not Enough Wallet Balance",
//                        // style = MaterialTheme.typography.body1,
//                    )
//                }
//            }
//
//            Box(
//                modifier = Modifier.fillMaxWidth().padding(horizontal = 12.dp, vertical = 6.dp)
//                    .padding(8.dp)
//                    .background(color_FFC0C0.copy(alpha = 0.7f), shape = RoundedCornerShape(2.dp)),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = "You should have at least ₹ 30 to connect with an expert",
//                    fontSize = 14.sp,
//                    textAlign = TextAlign.Center,
//                    color = error_color
//                )
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            Box(
//                modifier = Modifier.fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = "Wallet Balance",
//                    fontSize = 12.sp
////                    style = MaterialTheme.typography.body1,
//                )
//            }
//
//
//            Box(
//                modifier = Modifier.fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//
//                Text(
//                    text = "₹500",
//                    style = TextStyle(fontWeight = FontWeight.Bold)
////                    style = MaterialTheme.typography.subtitle1,
//                )
//            }
//            Spacer(modifier = Modifier.height(10.dp))
//            Box(modifier = Modifier.background(primaryLight50)) {
//                Text(
//                    text = "This will not take much time. 10 users recharged the wallet recently and took consultation with experts. Hurry up!",
////                    style = MaterialTheme.typography.caption,
//                    fontSize = 12.sp,
//                    modifier = Modifier.basicMarquee()
//                )
//            }
//
//            Button(
//                onClick = { },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = "Let's Recharge", color = Color.White)
//            }
//        }
//    }
//}


//@OptIn(ExperimentalMaterial3Api::class, ExperimentalResourceApi::class)
//@Composable
//fun CallConsultationBottomSheet(
//    sheetState: SheetState,
//    onDismissRequest: () -> Unit
//) {
//    ModalBottomSheet(
//        sheetState = sheetState,
//        onDismissRequest = { onDismissRequest() }
//    ) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .align(Alignment.CenterHorizontally)
//        ) {
//            Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
//                Text(
//                    text = "Call Consultation",
////                    style = MaterialTheme.typography.subtitle1,
////                    modifier = Modifier.padding(vertical = 16.dp)
//                )
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
////                    .padding(top = 16.dp)
//                    .clip(RoundedCornerShape(8.dp)),
////                    .background(Color.LightGray),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//
//                Image(painter = painterResource("drawable/ellipse.png"), contentDescription = null)
//                Spacer(modifier = Modifier.width(16.dp))
//                Column {
//                    Text(
//                        text = "Rahul Kumar",
//                        // style = MaterialTheme.typography.body1,
//                        maxLines = 1,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = "Advocate,Cricketer,Bowler and much more thing here",
//                        // style = MaterialTheme.typography.body2,
//                        maxLines = 2,
//                        modifier = Modifier.fillMaxWidth()
//                    )
//                }
//            }
//
//            Spacer(modifier = Modifier.height(8.dp))
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(90.dp)
//                    .clip(RoundedCornerShape(8.dp)),
//                horizontalArrangement = Arrangement.SpaceBetween,
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                // Wallet Balance
//                Column(
//                    modifier = Modifier.weight(1f)
//                ) {
//
//                    Row {
//                        Box(modifier = Modifier.background(primaryLight50)) {
//                            Image(
//                                painter = painterResource("ic_wallet.xml"),
//                                contentDescription = null,
//                                modifier = Modifier.height(35.dp).width(35.dp)
//                            )
//                        }
//                        Spacer(modifier = Modifier.width(8.dp))
//                        Column {
//                            Text(
//                                text = wallet_balance,
//                                fontSize = 12.sp,
//                                // style = MaterialTheme.typography.body1,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                            Text(
//                                text = "₹500",
//                                // style = MaterialTheme.typography.body2,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//                        }
//
//                    }
//
//                }
//                // Pricing
//                Column(
//                    modifier = Modifier.weight(1f)
//                ) {
//                    Row {
//                        Box(modifier = Modifier.background(primaryLight50)) {
//                            Image(
//                                painter = painterResource("consultation_call_small_icon.xml"),
//                                contentDescription = null,
//                                modifier = Modifier.size(48.dp),
//                                colorFilter = ColorFilter.tint(md_them_primary)
//                            )
//                        }
//                        Spacer(modifier = Modifier.width(8.dp))
//                        Column {
//                            Text(
//                                text = pricing,
//                                // style = MaterialTheme.typography.body1,
//                                fontSize = 12.sp,
//                                maxLines = 1,
//                                modifier = Modifier.fillMaxWidth()
//                            )
//
//                            Row(verticalAlignment = Alignment.CenterVertically) {
//                                Box(
//                                    modifier = Modifier.background(
//                                        Color(0xFFEF5350),
//                                        shape = RoundedCornerShape(2.dp)
//                                    )
//                                ) {
//                                    Text(text = "Free", fontSize = 10.sp, color = Color.White)
//                                }
//                                Text(
//                                    text = "₹20/Min",
//                                    // style = MaterialTheme.typography.body2,
//                                    maxLines = 1,
//                                    modifier = Modifier.fillMaxWidth()
//                                )
//                            }
//                        }
//                    }
//                }
//            }
//
//            Row(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .clip(RoundedCornerShape(8.dp))
//                    .background(primaryLight50),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                Image(
//                    painter = painterResource("ic_duration_chronic.xml"),
//                    contentDescription = null,
//                    colorFilter = ColorFilter.tint(md_them_primary)
//                )
//                Spacer(modifier = Modifier.width(8.dp))
//                Text(text = maximum_call_duration)
//                Spacer(modifier = Modifier.weight(1f))
//                Box(contentAlignment = Alignment.CenterEnd) {
//                    Text(text = "654", textAlign = TextAlign.End)
//                }
//
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            Box(
//                modifier = Modifier.fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    "This is a one-time free call offered by Winyway",
//                    fontSize = 12.sp,
//                    textAlign = TextAlign.Center
//                )
//            }
//
//            Spacer(modifier = Modifier.height(10.dp))
//
//            Button(
//                onClick = { },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = let_s_connect, color = Color.White)
//            }
//
//            Button(
//                onClick = { },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp)
//                    .padding(bottom = 8.dp),
//                shape = RoundedCornerShape(5.dp), // Set the shape to RectangleShape
//                colors = ButtonDefaults.buttonColors(Color.Transparent),
//                border = BorderStroke(1.dp, md_them_primary)
//            ) {
//                Text(text = "Low Balance? Recharge Wallet", color = md_them_primary)
//            }
//        }
//    }
//}


//@Composable
//fun NotLoginUser(
//    sheetState: SheetState,
//    onDismissRequest: () -> Unit
//) {
//    ModalBottomSheet(
//        sheetState = sheetState,
//        onDismissRequest = { onDismissRequest() }
//    ) {
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(16.dp)
//                .verticalScroll(rememberScrollState())
//        ) {
//            Box(
//                modifier = Modifier.fillMaxWidth(),
//                contentAlignment = Alignment.Center
//            ) {
//                Row(
//                    modifier = Modifier.fillMaxWidth(),
//                    verticalAlignment = Alignment.CenterVertically,
//                    horizontalArrangement = Arrangement.Center
//                ) {
//                    Image(
//                        painter = painterResource(Res.drawable.icon_logo),
//                        contentDescription = null,
//                        colorFilter = ColorFilter.tint(md_them_primary),
//                        modifier = Modifier.size(48.dp) // Adjust the size as needed
//                    )
//                    Spacer(modifier = Modifier.width(8.dp))
//                    Text(
//                        text = "Winyway",
//                        color = md_them_primary,
//                        textAlign = TextAlign.Center,
//                        fontSize = 24.sp,
//                        fontWeight = FontWeight.Bold
//                    )
//                }
//
//            }
//            Spacer(modifier = Modifier.height(16.dp))
//            Column(
//                modifier = Modifier.fillMaxWidth(),
//                horizontalAlignment = Alignment.CenterHorizontally
//            ) {
//                Text(
//                    "You have to login to Continue....",
//                    textAlign = TextAlign.Center,
//                    fontSize = 16.sp
//                )
//                Text(
//                    "Login and get free Expert Consultation",
//                    textAlign = TextAlign.Center,
//                    fontSize = 14.sp
//                )
//            }
//            Spacer(modifier = Modifier.height(20.dp))
//            Button(
//                onClick = { },
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(55.dp),
//                shape = RoundedCornerShape(5.dp),
//                colors = ButtonDefaults.buttonColors(md_them_primary)
//            ) {
//                Text(text = "Login", color = Color.White)
//            }
//            Spacer(modifier = Modifier.height(16.dp))
//            Row(
//                modifier = Modifier.fillMaxWidth(),
//                horizontalArrangement = Arrangement.Center
//            ) {
//                Text("Don't have an account? ")
//                Text(
//                    text = "Sign Up",
//                    style = TextStyle(fontWeight = FontWeight.Bold),
//                    color = md_them_primary,
//                    modifier = Modifier.clickable { /* Handle Sign Up click */ }
//                )
//            }
//        }
//    }
//}

@Composable
fun ConsultantRating(rating: Double?) {
    val roundedRating = rating?.coerceIn(0.0, 5.0)?.let { round(it * 10) / 10 } ?: 5.0
    val ratingText = "$roundedRating"
    Text(
        text = ratingText,
        fontSize = 12.sp,
        style = TextStyle(fontWeight = FontWeight.Bold),
        textAlign = TextAlign.Center,
        color = Color.Black,
        modifier = Modifier.padding(horizontal = 8.dp)
    )
}


@Composable
fun CallChartActionButton(
    text: String,
    //icon: Painter,
    icon: ImageVector, onClick: () -> Unit, buttonColor: Color, consultantCallAvailability: String
) {
    Button(onClick = onClick,
        modifier = Modifier.fillMaxWidth(),
        enabled = (consultantCallAvailability == "Online"),
        colors = ButtonDefaults.buttonColors(buttonColor),
        shape = RoundedCornerShape(4.dp),
        elevation = ButtonDefaults.elevatedButtonElevation(3.dp),
        contentPadding = PaddingValues(horizontal = 5.dp),
        content = {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(
                    imageVector = icon,
                    contentDescription = null,
                    modifier = Modifier.padding(3.dp).size(Scale.GLOBAL_ICON_SIZE),
                    tint = Color.White
                )
                Spacer(modifier = Modifier.width(3.dp))
                Text(
                    text = text.uppercase(),
                    color = Color.White,
                    maxLines = 1,
                    fontSize = 14.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold
                )
            }
        })
}
