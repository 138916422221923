package tta.destinigo.talktoastro.feature_authentication.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class RegisterUserResponse(
    @SerialName("status")
    var status: Boolean = false,
    @SerialName("message")
    var message: String? = "Unexpected Error",
    @SerialName("code")
    var code: Int = 0,
    @SerialName("data") val data: RegisterUserResponseData?
)
@Serializable
data class RegisterUserResponseData(
   @SerialName ("user_id") val userId: String,
   @SerialName ("refer_code") val referCode: String,
   @SerialName ("full_name") val fullName: String,
   @SerialName ("gender") val gender: String?,
   @SerialName ("email") val email: String?,
   @SerialName ("joining_user_coin") val  joiningUserCoin: Int?,
   @SerialName ("profileimg") val profileImg: String?
)
