package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Star
import androidx.compose.material.icons.filled.StarBorder
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.core.theme.star_rating_color
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.ic_wallet
import tta.destinigo.talktoastro.resources.purchase_histor_bag
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.widget.ConsultationLayout
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.widget.CustomOrderDetailsCard
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.widget.PaymentDetailsHeading
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.widget.openDialog

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun TransactionHistorySuccess(
    component: TransactionHistoryComponent,
    data: List<TransactionOrderData>
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }



    val openDialog = remember { mutableStateOf(false) }
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val selectedTransaction = remember { mutableStateOf<TransactionOrderData?>(null) }
    val coroutineScope = rememberCoroutineScope()
    if(openDialog.value) {
        if (windowSizeClass == WindowWidthSizeClass.Compact) {
            ModalBottomSheet(
                sheetState = sheetState,
                containerColor = Color.White,
                onDismissRequest = {
                    openDialog.value = false
                    coroutineScope.launch {
                        sheetState.hide()
                    }
                }
            ) {
                selectedTransaction.value?.let { transaction ->
                    val createdAt = transaction.createdAt ?: "Unknown Date"


                    if (transaction.type?.contains("Custom Order", ignoreCase = true) == true) {
                        CustomOrderDetailsCard(
                            orderDescription = "Custom Order Purchase",
                            orderDate = convertToIndianTime(createdAt),
                            customOrderPrice = transaction.totalAmount.toString(),
                            billedAmount = transaction.amountWithGst.toString(),
                            transactionId = transaction.txnId.toString(),
                            modifier = Modifier
                        )
                    }

                    if (transaction.type?.contains("Recharge", ignoreCase = true) == true) {
                        PaymentDetailsHeading(isVisible = true, data = transaction)
                    }

                    if (transaction.type?.contains(
                            "Call with",
                            ignoreCase = true
                        ) == true || transaction.type?.contains(
                            "Chat with",
                            ignoreCase = true
                        ) == true || transaction.type?.contains(
                            "Call",
                            ignoreCase = true
                        ) == true || transaction.type?.contains("Chat", ignoreCase = true) == true
                    ) {
                        ConsultationLayout(data = transaction)
                    }
                }
            }
        } else {
            AlertDialog(
                containerColor = Color.White,
                onDismissRequest = {
//            consultationComponent.onClosePage()
//            onDismiss.invoke()
                    openDialog.value = false
                },
                text = {
                    selectedTransaction.value?.let { transaction ->
                        val createdAt = transaction.createdAt ?: "Unknown Date"


                        if (transaction.type?.contains("Custom Order", ignoreCase = true) == true) {
                            CustomOrderDetailsCard(
                                orderDescription = "Custom Order Purchase",
                                orderDate = convertToIndianTime(createdAt),
                                customOrderPrice = transaction.totalAmount.toString(),
                                billedAmount = transaction.amountWithGst ?: transaction.totalAmount.toString(),
                                transactionId = transaction.txnId.toString(),
                                modifier = Modifier
                            )
                        }

                        if (transaction.type?.contains("Recharge", ignoreCase = true) == true) {
                            PaymentDetailsHeading(isVisible = true, data = transaction)
                        }

                        if (transaction.type?.contains(
                                "Call with",
                                ignoreCase = true
                            ) == true || transaction.type?.contains(
                                "Chat with",
                                ignoreCase = true
                            ) == true || transaction.type?.contains(
                                "Call",
                                ignoreCase = true
                            ) == true || transaction.type?.contains(
                                "Chat",
                                ignoreCase = true
                            ) == true
                        ) {
                            ConsultationLayout(data = transaction)
                        }
                    }
                },
                shape = RoundedCornerShape(Scale.CORNER_SMALL),
                confirmButton = {},
                dismissButton = {}
            )
        }
    }
    Box(
        Modifier.fillMaxSize().background(background_color),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = widthModifier.fillMaxSize().padding(16.dp, top = 0.dp).background(background_color)
        ) {
            CommonTopAppBar(
                modifier = Modifier,
                title = "Transaction History",
                onBackClick = { component.event(TransactionHistoryEvent.onBackButtonClick) }
            )
            Spacer(modifier = Modifier.height(10.dp))

            if (data.isEmpty()) {
                Box(
                    modifier = Modifier.fillMaxSize(),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "No data found",
                        color = Color.Gray,
                        fontSize = 18.sp,
                        style = MaterialTheme.typography.bodyMedium
                    )
                }
            } else {
                LazyColumn {
                    items(data.size) { transaction ->
                        val dataItem = data[transaction]
                        TransactionItem(dataItem, onTransactionCardClick = {transaction ->
//                            if(transaction.type?.contains("Custom Order", ignoreCase = true) == true ){
//                                selectedTransaction.value = transaction
//                                openDialog.value = true
//                            }

                            selectedTransaction.value = transaction
                            openDialog.value = true
                        })
                        Spacer(modifier = Modifier.height(8.dp))
                    }
                }
            }
        }
    }
}



@Composable
fun TransactionItem(transaction: TransactionOrderData,onTransactionCardClick: (TransactionOrderData) -> Unit) {
    val imgUrl = transaction.callDetails?.expertInfo?.image
        ?: transaction.chatDetails?.expertInfo?.image
        ?: transaction.emergency_call_details?.expertInfo?.image
        ?: transaction.emergency_chat_details?.expertInfo?.image
        ?: transaction.voipCallDetails?.expertInfo?.image
        ?: ""

    val isChatRated = transaction.chatDetails?.userRating == null
    val isCallRated = transaction.callDetails?.userRating == null

    val isRatingRequired = isCallRated && isChatRated

    Card(
        shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_MEDIUM),
        modifier = Modifier.fillMaxWidth().clickable { onTransactionCardClick.invoke(transaction) },
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                if(imgUrl.isEmpty()){
                    if(transaction.type?.contains("Custom Order", ignoreCase = true) == true){
                        Image(
                            painter = painterResource(Res.drawable.purchase_histor_bag),
                            contentDescription = "Transaction Icon",
                            modifier = Modifier.size(40.dp),
                            colorFilter = ColorFilter.tint(primaryColor)
                        )

                    }else {
                        Image(
                            painter = painterResource(Res.drawable.ic_wallet),
                            contentDescription = "Transaction Icon",
                            modifier = Modifier.size(40.dp)
                        )
                    }
                }else {

                    ExpertProfileIcon(profileImageUrl = imgUrl, modifier = Modifier.size(60.dp))
                }

                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    val trType = when {
                        transaction.callDetails?.expertInfo?.name != null -> "Call with ${transaction.callDetails.expertInfo.name}"
                        transaction.chatDetails?.expertInfo?.name != null -> "Chat with ${transaction.chatDetails.expertInfo.name}"
                        transaction.emergency_call_details?.expertInfo?.name != null -> "Emergency Call with ${transaction.emergency_call_details.expertInfo.name}"
                        transaction.emergency_chat_details?.expertInfo?.name != null -> "Emergency Chat with ${transaction.emergency_chat_details.expertInfo.name}"
                        transaction.type?.contains("Custom Order", ignoreCase = true) == true -> "Purchased Custom Order"
                        transaction.type?.contains("Recharge", ignoreCase = true) == true -> "Wallet Recharge"
                        transaction.type?.contains("Voip Call", ignoreCase = true) == true -> "Voip Call with ${transaction.voipCallDetails?.expertInfo?.name}"
                        else -> "Unknown Data"
                    }
                    val createdAt = transaction.createdAt ?: "Unknown Date"
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start,
                    ) {
                        if (transaction.type?.contains("Free", ignoreCase = true) == true) {
                            Text(
                                text = "FREE",
                                style = TextStyle(
                                    fontWeight = FontWeight.SemiBold,
                                    color = Color.White,
                                    fontSize = 10.sp
                                ),
                                modifier = Modifier
                                    .background(Color.Red, shape = RoundedCornerShape(Scale.CORNER_SMALL))
                                    .padding(horizontal = 6.dp, vertical = 2.dp)
                            )
                        }
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(text = trType, style = MaterialTheme.typography.bodyLarge, fontSize = 18.sp)
                    }

                    Text(text = convertToIndianTime(createdAt), style = MaterialTheme.typography.bodyMedium, fontSize = 18.sp)
                }
                Spacer(modifier = Modifier.weight(1f))
                Column(horizontalAlignment = Alignment.End) {
                    val paidAmount = transaction.totalAmount ?: 0
                    Text(text = "${initializeCurrency()} $paidAmount", style = MaterialTheme.typography.bodyMedium, fontSize = 20.sp)
                    StatusChip(transaction.paymentStatus ?: "")
                }
            }

            if (transaction.type.equals("Recharge", true).not()) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(vertical = 8.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "How was the consultation experience?",
                        style = MaterialTheme.typography.bodyMedium,
                        fontSize = 14.sp
                    )
                    // Add RatingBar here if necessary
                    RatingBar(0,{})
                }
            }
        }
    }
}

@Composable
fun StatusChip(status: String) {
    val backgroundColor = when (status) {
        "Pending" -> Color.LightGray
        "Success" -> Color.LightGray
        "Failed" -> Color.Red
        else -> Color.LightGray
    }

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        color = backgroundColor,
        modifier = Modifier
            .padding(4.dp)
            //.border(1.dp, Color.DarkGray, RoundedCornerShape(Scale.CORNER_MEDIUM))
    ) {
        Box(
            modifier = Modifier.padding(horizontal = 8.dp, vertical = 4.dp),
            contentAlignment = Alignment.Center

        ) {
            Text(
                text = status,
                color = Color.Black,
                fontSize = 12.sp,
                modifier = Modifier
                    .wrapContentSize()
                    //.fillMaxSize()
                    .animateContentSize(),
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.titleMedium
            )
        }
    }
}



@Composable
fun RatingBar(rating: Int, onRatingChanged: (Int) -> Unit) {
    Row {
        for (i in 1..5) {
            Icon(
                imageVector = if (i <= rating) Icons.Default.Star else Icons.Default.StarBorder,
                contentDescription = "Star Rating",
                tint = star_rating_color,
                modifier = Modifier.size(20.dp).clickable { onRatingChanged(i) }
            )
        }
    }
}

fun convertToIndianTime(dateTimeString: String): String {
    val instant = Instant.parse(dateTimeString)
    val indiaTimeZone = TimeZone.of("UTC+05:30")
    val indiaDateTime = instant.toLocalDateTime(indiaTimeZone)
    val day = indiaDateTime.dayOfMonth
    val month = indiaDateTime.month.name.lowercase().replaceFirstChar { it.uppercase() }.take(3)
    val year = indiaDateTime.year
    val hour = if (indiaDateTime.hour % 12 == 0) 12 else indiaDateTime.hour % 12
    val minute = indiaDateTime.minute.toString().padStart(2, '0')
    val amPm = if (indiaDateTime.hour < 12) "AM" else "PM"
    return "$day $month $year | $hour:$minute $amPm"
}


