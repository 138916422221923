package tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget

//import VideoPlayer
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowForward
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.Download
import androidx.compose.material.icons.filled.Error
import androidx.compose.material.icons.filled.PlayArrow
import androidx.compose.material.icons.filled.PlayCircle
import androidx.compose.material.icons.filled.Videocam
import androidx.compose.material3.Divider
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.persentation.HomePageLoadingProgressDialog
import tta.destinigo.talktoastro.core.persentation.downloadFileFromUrl
import tta.destinigo.talktoastro.core.persentation.getFileDownloader
import tta.destinigo.talktoastro.core.persentation.playVideo
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.CustomOrderLessonComponent
import tta.destinigo.talktoastro.feature_custom_order.data.response.CustomOrderDetailData

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CustomOrderLessons(component: CustomOrderLessonComponent){
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val state by component.state.collectAsState()
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    Logger.d { "$state" }
    if(state == null){
        Box(
            modifier = Modifier.fillMaxSize().background(background_color),
            contentAlignment = Alignment.Center
        ) {
            HomePageLoadingProgressDialog(
                onDismissRequest = { /* Handle dismiss if necessary */ },
                backgroundColor = Color(0xFF2A2A2A),
                loadingColor = Color.White
            )
        }
    }else{
        if(windowSizeClass == WindowWidthSizeClass.Compact){
            CourseScreenMobile(widthModifier,state,component)
        }else{
            CourseScreen(widthModifier,state,component)
        }
    }

}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CourseScreenMobile(
    widthModifier: Modifier,
    value: CustomOrderDetailData?,
    component: CustomOrderLessonComponent
) {
    val vedioUrl = remember { mutableStateOf("") }
    Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center){
        Column(modifier = widthModifier.fillMaxSize()) {
            CommonTopAppBar(title = "lesson Detail", onBackClick = { component.onBackClick() })
            // Video Player Section
            Box(
                modifier = Modifier
                    .clickable { showToast("Coming Soon") }
                    .fillMaxWidth()
                    .weight(0.4f)
                    .border(
                        width = 2.dp,
                        brush = SolidColor(Color.Gray),
                        shape = RectangleShape
                    )
                    .background(Color.Black),
                contentAlignment = Alignment.Center
            ) {
//                value?.imageUrl?.let {
//                    VideoPlayer(
//                        modifier = Modifier.fillMaxSize(),
//                        url = it,
//                        autoPlay = false
//                    )
//                }
                Icon(
                    imageVector = Icons.Default.PlayArrow,
                    contentDescription = "Play",
                    tint = Color.White,
                    modifier = Modifier.size(48.dp)
                )
            }

            // Course Overview Section
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 8.dp)
            ) {
                Text(
                    text = "${value?.heading}",
                    //text = "Learn Ethical Hacking + Penetration Testing! Use real techniques by black hat hackers then learn to defend against them!",
                    style = MaterialTheme.typography.bodyMedium,
                    fontWeight = FontWeight.Bold,
                    lineHeight = 20.sp,
                    color = Color.Black
                )

                Text(
                    text = "${value?.description}",
                    //text = "Learn Ethical Hacking + Penetration Testing! Use real techniques by black hat hackers then learn to defend against them!",
                    style = MaterialTheme.typography.bodyMedium,
                    fontWeight = FontWeight.SemiBold,
                    lineHeight = 14.sp,
                    color = Color.Black
                )
                Spacer(modifier = Modifier.height(8.dp))
            }

            // Course Content Section
            LazyColumn(
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(0.6f)
                    .padding(horizontal = 16.dp)
            ) {
                item {
                    value?.sections?.forEach { data ->
                        data.lession?.forEach {
                            CourseSectionMobile(
                                title = it.sectionName.toString(),
                                duration = it.duration.toString(),
                                lessons = listOf(it.lessionName.toString()),
                                imageUrl = it.imageUrl.toString(),
                                onDownloadClick = { mediaUrl ->
                                    val fileName = "lesson_video.mp4"
                                    downloadFileFromUrl(mediaUrl, fileName)
                                }
                            )
                        }

                    }

                }
            }
        }
    }

}

@Composable
fun CourseSectionMobile(
    title: String,
    duration: String,
    lessons: List<String>,
    imageUrl: String,
    onDownloadClick: (String) -> Unit
) {
    val icon = when {
        imageUrl.endsWith(".mp3", ignoreCase = true) -> Icons.Default.PlayCircle
        imageUrl.endsWith(".webm", ignoreCase = true) -> Icons.Default.Videocam
        imageUrl.endsWith(".mp4", ignoreCase = true) -> Icons.Default.Videocam
        else -> Icons.Default.Error
    }

    Column(modifier = Modifier.fillMaxWidth().padding(horizontal = 16.dp)) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = title,
                style = MaterialTheme.typography.bodyMedium,
                fontWeight = FontWeight.Bold
            )
            Text(
                text = duration,
                style = MaterialTheme.typography.bodySmall,
                color = Color.Gray
            )
        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 8.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(
                    imageVector = icon,
                    contentDescription = "Media Icon",
                    modifier = Modifier.size(24.dp),
                    tint = MaterialTheme.colorScheme.primary
                )
                Spacer(modifier = Modifier.width(8.dp))
                Column {
                    lessons.forEach { lesson ->
                        Text(
                            text = lesson,
                            style = MaterialTheme.typography.bodySmall,
                            modifier = Modifier.padding(bottom = 4.dp)
                        )
                    }
                }
            }

            IconButton(onClick = { onDownloadClick(imageUrl) }) {
                Icon(
                    imageVector = Icons.Default.Download,
                    contentDescription = "Download Icon",
                    tint = MaterialTheme.colorScheme.primary
                )
            }
        }

        Divider(
            color = Color.LightGray,
            modifier = Modifier.padding(vertical = 8.dp)
        )
    }
}


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CourseScreen(
    widthModifier: Modifier,
    value: CustomOrderDetailData?,
    component: CustomOrderLessonComponent
) {
    var isContentVisible by remember { mutableStateOf(true) }
    Box(Modifier.fillMaxSize(), contentAlignment = Alignment.Center){
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(16.dp)
        ) {
            // Video Player Section
            Column(
                modifier = Modifier
                    .weight(1f)
                    .fillMaxHeight()
            ) {
                CommonTopAppBar(title = "lesson Detail", onBackClick = {component.onBackClick()})
                // Video Player
                Box(
                    modifier = Modifier
                        .clickable { showToast("Coming Soon") }
                        .fillMaxWidth()
                        .height(350.dp) // Fixed height for video player
                        .background(Color.Black),
                    contentAlignment = Alignment.Center
                ) {
                    Icon(
                        imageVector = Icons.Default.PlayArrow,
                        contentDescription = "Play",
                        tint = Color.White,
                        modifier = Modifier.size(48.dp)
                    )
                }

                // Course Overview Section
                LazyColumn(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp)
                    //.background(Color(0xFF1C1C1C)) // Optional: Background for separation
                ) {
                    item {
                        Text(
                            text = "${value?.heading}",
                            //text = "Learn Ethical Hacking + Penetration Testing! Use real techniques by black hat hackers then learn to defend against them!",
                            style = MaterialTheme.typography.bodyMedium,
                            fontWeight = FontWeight.Bold,
                            lineHeight = 20.sp,
                            color = Color.Black
                        )

                        Text(
                            text = "${value?.description}",
                            //text = "Learn Ethical Hacking + Penetration Testing! Use real techniques by black hat hackers then learn to defend against them!",
                            style = MaterialTheme.typography.bodyMedium,
                            fontWeight = FontWeight.SemiBold,
                            lineHeight = 14.sp,
                            color = Color.Black
                        )
                        Spacer(modifier = Modifier.height(8.dp))
                    }
                }
            }

            // Spacer between Video and Content Section
            Spacer(modifier = Modifier.width(16.dp))

            // Course Content Section with collapsible functionality
            if (isContentVisible) {
                Column(
                    modifier = Modifier
                        .weight(1f)
                        .fillMaxHeight()
                        .background(Color(0xFFEFEFEF))
                        .padding(16.dp)
                ) {
                    // Header with Close Button
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = "Course Content",
                            style = MaterialTheme.typography.titleMedium,
                            fontWeight = FontWeight.Bold
                        )
                        IconButton(onClick = { isContentVisible = false }) {
                            Icon(
                                imageVector = Icons.Default.Close,
                                contentDescription = "Close Content Section"
                            )
                        }
                    }

                    Spacer(modifier = Modifier.height(8.dp))

                    LazyColumn(modifier = Modifier.fillMaxSize()) {
                        item {
                            value?.sections?.forEach { data ->
                                data.lession?.forEach {
                                    CourseSection(
                                        title = it.sectionName.toString(),
                                        duration = it.duration.toString(),
                                        lessons = listOf(it.lessionName.toString()),
                                        imageUrl = it.imageUrl.toString(),
                                        onDownloadClick = { mediaUrl ->
                                           // showToast("$mediaUrl")
                                            val fileName = "lesson_video.mp4"
                                            downloadFileFromUrl(mediaUrl, fileName)
                                            //val fileDownloader = getFileDownloader()
                                           // fileDownloader.downloadFile(mediaUrl, "DownloadedFile.mp4")
                                        }

                                    )
                                }

                            }

                        }
                    }
                }
            } else {
                // Button to reopen the Course Content Section
                IconButton(
                    onClick = { isContentVisible = true },
                    modifier = Modifier.align(Alignment.CenterVertically)
                ) {
                    Icon(
                        imageVector = Icons.Default.ArrowForward,
                        contentDescription = "Open Content Section"
                    )
                }
            }
        }
    }


}

@Composable
fun CourseSection(
    title: String,
    duration: String,
    lessons: List<String>,
    imageUrl: String,
    onDownloadClick: (String) -> Unit // Callback for download action
) {
    // Determine the icon based on the file type
    val icon = when {
        imageUrl.endsWith(".mp3", ignoreCase = true) -> Icons.Default.PlayCircle
        imageUrl.endsWith(".webm", ignoreCase = true) -> Icons.Default.Videocam
        imageUrl.endsWith(".mp4", ignoreCase = true) -> Icons.Default.Videocam
        else -> Icons.Default.Error
    }

    Column(modifier = Modifier.fillMaxWidth().padding(16.dp)) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = title,
                style = MaterialTheme.typography.bodyMedium,
                fontWeight = FontWeight.Bold
            )
            Text(
                text = duration,
                style = MaterialTheme.typography.bodySmall,
                color = Color.Gray
            )
        }

        Spacer(modifier = Modifier.height(8.dp))

        Row(
            modifier = Modifier
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Icon(
                imageVector = icon,
                contentDescription = "Media Icon",
                modifier = Modifier.size(24.dp),
                tint = MaterialTheme.colorScheme.primary
            )
            Spacer(modifier = Modifier.width(8.dp))

            Column(modifier = Modifier.weight(1f)) {
                lessons.forEach { lesson ->
                    Text(
                        text = lesson,
                        style = MaterialTheme.typography.bodySmall,
                        modifier = Modifier.padding(bottom = 4.dp)
                    )
                }
            }

            Spacer(modifier = Modifier.width(8.dp))

            IconButton(
                onClick = { onDownloadClick(imageUrl) }
            ) {
                Icon(
                    imageVector = Icons.Default.Download,
                    contentDescription = "Download Icon",
                    tint = MaterialTheme.colorScheme.primary
                )
            }
        }

        Divider(
            color = Color.LightGray,
            modifier = Modifier.padding(vertical = 8.dp)
        )
    }
}


//fun downloadFileFromUrl(fileUrl: String, fileName: String) {
//    // Create a temporary anchor element
//    val anchor = document.createElement("a") as HTMLAnchorElement
//    anchor.href = fileUrl
//    anchor.download = fileName // Suggested file name for the download
//
//    // Trigger the download by clicking the anchor
//    document.body?.appendChild(anchor) // Append to the DOM
//    anchor.click() // Simulate a click event
//    document.body?.removeChild(anchor) // Remove the anchor element
//}