package tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.registration_form

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ClearAll
import androidx.compose.material.icons.filled.Email
import androidx.compose.material.icons.filled.Person
import androidx.compose.material.icons.filled.Phone
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.persentation.CommonLoadingButton
import tta.destinigo.talktoastro.core.theme.GreenDark20
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.primaryDarkerColor
import tta.destinigo.talktoastro.core.theme.white
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.CourseComponent

@Composable
fun RegistrationForm(
    price: String,
    originalPrice: String,
    discountPrice: String,
    modifier: Modifier = Modifier,
    onClickSubmit: (name: String, email: String, mobile: String) -> Unit,
    component: CourseComponent
) {


    // State for email input and error
    var email by remember { mutableStateOf("") }
    var name by remember { mutableStateOf("") }
    var mobile by remember { mutableStateOf("") }
    var emailError by remember { mutableStateOf<String?>(null) }
    var nameError by remember { mutableStateOf<String?>(null) }
    var mobileError by remember { mutableStateOf<String?>(null) }

    fun setInitialDetail() {
        email = UserInfoProvider.getEmail()
        name = UserInfoProvider.getFullName()
        mobile = UserInfoProvider.getPhone()
    }

    setInitialDetail()

    val isLoading by component.formState.collectAsState()
    var shouldShowDialog by remember { mutableStateOf(false) }



    LaunchedEffect(isLoading) {
        if (isLoading == false) shouldShowDialog = true
    }
    if (shouldShowDialog) {
        SuccessDialog(
            onDismiss = {
                name = ""
                email = ""
                mobile = ""
                emailError = null
                nameError = null
                mobileError = null
                component.setNullFormState()
                shouldShowDialog = false
            }
        )
    }

    Surface(
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        color = primaryDarkerColor,
        border = BorderStroke(
            width = 1.dp, color = primaryDarkerColor
        ),
        modifier = modifier
    ) {
        Column(
            modifier = Modifier.background(Color.White)
        ) {
            // Header
            Text(
                text = "REGISTRATION FORM",
                color = Color.White,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.bodySmall.copy(fontSize = 16.sp),
                modifier = Modifier.fillMaxWidth().background(primaryDarkerColor)
                    .padding(vertical = 16.dp)
            )

            Column(
                modifier = Modifier.padding(24.dp)
            ) {

                // Name Field
                OutlinedTextField(
                    value = name,
                    onValueChange = {
                        name = it
                        nameError = null
                    },
                    label = { Text("Enter your name") },
                    placeholder = { Text("e.g., John Doe") },
                    singleLine = true,
                    isError = nameError != null,
                    leadingIcon = {
                        Icon(Icons.Default.Person, contentDescription = "Name Icon")
                    },
                    trailingIcon = {
                        if (name.trim().isNotEmpty()) {
                            IconButton(onClick = {
                                name = ""
                            }) {
                                Icon(Icons.Default.ClearAll, contentDescription = "Check Icon")
                            }
                        }
                    },
                    supportingText = {
                        if (nameError != null) {
                            Text(
                                text = nameError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    colors = OutlinedTextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent,
                        focusedBorderColor = Color(0xFF112B60),
                        unfocusedBorderColor = Color.Gray,
                    )
                )

                Spacer(modifier = Modifier.height(8.dp))

                // Email Field with Validation
                OutlinedTextField(
                    value = email,
                    onValueChange = {
                        email = it
                        emailError = validateEmail(it)
                    },
                    singleLine = true,
                    label = { Text("Enter your email") },
                    placeholder = { Text("e.g., john.doe@example.com") },
                    isError = emailError != null,
                    leadingIcon = {
                        Icon(Icons.Default.Email, contentDescription = "Email Icon")
                    },
                    trailingIcon = {
                        if (email.trim().isNotEmpty()) {
                            IconButton(onClick = {
                                email = ""
                            }) {
                                Icon(Icons.Default.ClearAll, contentDescription = "Clear Icon")
                            }
                        }
                    },
                    supportingText = {
                        if (emailError != null) {
                            Text(
                                text = emailError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        } else {
                            Text(
                                text = "We will not share your email with anyone.",
                                style = MaterialTheme.typography.bodySmall.copy(color = Color.Gray)
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    colors = OutlinedTextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent,
                        focusedBorderColor = if (emailError != null) MaterialTheme.colorScheme.error else Color(
                            0xFF112B60
                        ),
                        unfocusedBorderColor = if (emailError != null) MaterialTheme.colorScheme.error else Color.Gray,
                    )
                )

                Spacer(modifier = Modifier.height(8.dp))

                // Whatsapp Mobile Number Field
                OutlinedTextField(
                    value = mobile,
                    onValueChange = { enter ->
                        if (enter.all { it.isDigit() }) {
                            mobile = enter
                        }
                        mobileError = null
                    },
                    label = { Text("Enter your WhatsApp number") },
                    placeholder = { Text("e.g., 9876543210") },
                    singleLine = true,
                    leadingIcon = {
                        Icon(Icons.Default.Phone, contentDescription = "Phone Icon")
                    },
                    isError = mobileError != null,
                    prefix = { Text("+91 ") },
                    supportingText = {
                        if (mobileError != null) {
                            Text(
                                text = mobileError!!,
                                color = MaterialTheme.colorScheme.error,
                                style = MaterialTheme.typography.bodySmall
                            )
                        } else {
                            Text(
                                text = "WhatsApp is required for instant updates.",
                                style = MaterialTheme.typography.bodySmall.copy(color = Color.Gray)
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    colors = OutlinedTextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent,
                        focusedBorderColor = Color(0xFF112B60),
                        unfocusedBorderColor = Color.Gray,
                    ),
                    keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Number)
                )

                Spacer(modifier = Modifier.height(16.dp))

                // Summary Section
                Text(
                    text = "Summary",
                    style = MaterialTheme.typography.titleMedium,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 8.dp)
                )
                SummaryRow("Actual Price", originalPrice)
                SummaryRow("Offer Discount", "- $discountPrice")
                SummaryRow("Savings", discountPrice)
                SummaryRow("Final Price", price, isHighlight = true)

                Spacer(modifier = Modifier.height(16.dp))

                // Enroll Buttons
                CommonLoadingButton(
                    text = "Enroll Now",
                    onClick = {

                        if (nameError != null || name.trim().isEmpty()) {
                            // Proceed with valid email
                            nameError = "Please enter Name."
                        }

                        if (emailError != null || email.trim().isEmpty()) {
                            // Proceed with valid email
                            emailError = "Please enter a valid email."
                        }

                        if (mobileError != null || mobile.trim().isEmpty()) {
                            // Proceed with valid email
                            mobileError = "Please enter a valid mobile."
                        }

                        if (name.trim().isNotEmpty() && email.trim().isNotEmpty() && mobile.trim()
                                .isNotEmpty()
                        ) {
                            onClickSubmit.invoke(
                                name, email, mobile
                            )
                        }
                    },
                    modifier = Modifier.fillMaxWidth(),
                    buttonColors = ButtonDefaults.buttonColors(
                        containerColor = primaryDarkerColor,
                        contentColor = white,
                    ),
                    isLoading = isLoading ?: false
                )

                Spacer(modifier = Modifier.height(8.dp))
            }
        }
    }
}

// Email validation function
fun validateEmail(email: String): String? {
    val emailRegex = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$".toRegex()
    return if (email.isBlank()) {
        "Email cannot be empty."
    } else if (!email.matches(emailRegex)) {
        "Invalid email format."
    } else {
        null
    }
}


@Composable
fun SummaryRow(label: String, value: String, isHighlight: Boolean = false) {
    Row(
        modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = label,
            style = MaterialTheme.typography.bodyMedium,
            color = if (isHighlight) GreenDark20 else Color.Black
        )
        Text(
            text = value,
            style = MaterialTheme.typography.bodyMedium,
            fontWeight = if (isHighlight) FontWeight.Bold else FontWeight.Normal,
            color = if (isHighlight) GreenDark20 else Color.Black
        )
    }
}
