package tta.destinigo.talktoastro.feature_courses.persentation

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.testimonials
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.HomeScreenTabComponent
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.footer.Footer
import tta.destinigo.talktoastro.feature_courses.persentation.widget.AstrologyClassesScreen
import tta.destinigo.talktoastro.feature_courses.persentation.widget.AstrologyCourseScreen
import tta.destinigo.talktoastro.feature_courses.persentation.widget.CourseDetailsForm
import tta.destinigo.talktoastro.feature_courses.persentation.widget.FaqSection
import tta.destinigo.talktoastro.feature_courses.persentation.widget.TestimonialCarousel
import tta.destinigo.talktoastro.feature_courses.persentation.widget.WhatWillYouLearn


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalFoundationApi::class,
    ExperimentalMaterial3Api::class
)
@Composable
fun LandingPageLayout(component: LandingPageComponent, homeComponent: HomeScreenTabComponent) {

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    val scrollState = rememberLazyListState()

    Box(
        modifier = Modifier.fillMaxSize()
            .background(background_color),
        //.padding(16.dp), // Optional padding for better appearance
        contentAlignment = Alignment.Center
    ) {
        LazyColumn(
            modifier = widthModifier.fillMaxSize().background(background_color),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
            state = scrollState
        ) {
            stickyHeader {
                CommonTopAppBar(modifier = Modifier, title = "Academy Of Winyway", onBackClick = {component.onNavigationBack()})
            }

            item {
                AstrologyCourseScreen()
            }

            item {
                CourseDetailsForm(component)
            }

            item {
                AstrologyClassesScreen()
            }

            item {
                WhatWillYouLearn()
                Spacer(modifier = Modifier.height(10.dp))
            }

            item {
                TestimonialCarousel(testimonials)
            }

            item {
                FaqSection()
            }

            item {
                Footer(homeComponent)
            }
        }
    }
}