package tta.destinigo.talktoastro.feature_custom_order.persentation

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Divider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.core.theme.star_rating_color
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.ConsultantRating
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.CustomOrderListData
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.ic_filled_star

@Composable
fun CustomOrderCard(
    currencyType: Boolean,
    data: CustomOrderListData,
    onCustomOrderCardClick: (CustomOrderListData) -> Unit
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .clickable { onCustomOrderCardClick.invoke(data) }
            .aspectRatio(1f) // Keeps the card square
            .padding(8.dp),
        shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_LARGE),
        colors = CardDefaults.cardColors(containerColor = Color.White)
    ) {
        Column(modifier = Modifier.fillMaxSize()) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(120.dp)
                    .clip(RoundedCornerShape(topStart = Scale.CORNER_MEDIUM, topEnd = Scale.CORNER_MEDIUM))
            ) {
                // Background Image
                AsyncImage(
                    model = data.image,
                    contentDescription = null,
                    modifier = Modifier
                        .fillMaxWidth()
                        .heightIn(min = 100.dp, max = 120.dp) // Constrain the image height
                        .clip(RoundedCornerShape(topStart = 16.dp, topEnd = 16.dp)),
                    contentScale = ContentScale.Crop
                )


                // Content Type (Tag)
                data.contentType?.let {
                    Box(
                        modifier = Modifier
                            .align(Alignment.TopEnd) // Position the tag at the top-right corner
                            .padding(8.dp)
                            .background(primaryColor.copy(alpha = 0.8f), RoundedCornerShape(Scale.CORNER_MEDIUM))
                            .padding(horizontal = 8.dp, vertical = 4.dp)
                    ) {
                        Text(
                            text = it,
                            style = MaterialTheme.typography.labelSmall,
                            color = Color.White
                        )
                    }
                }
            }
            Spacer(modifier = Modifier.height(8.dp))
            Row(
                modifier = Modifier
                    .padding(horizontal = 16.dp)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                AsyncImage(
                    model = data.expertDetail?.profileImageUrl,
                    contentDescription = null,
                    modifier = Modifier
                        .size(40.dp)
                        .clip(CircleShape)
                        .border(2.dp, Color.Gray, CircleShape)
                )

                Spacer(modifier = Modifier.width(8.dp))

                data.expertDetail?.let {
                    Text(
                        text = it.fullName,
                        style = MaterialTheme.typography.bodyMedium,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }
            }

            Spacer(modifier = Modifier.height(8.dp))

            // Heading and Description
            Column(modifier = Modifier.padding(horizontal = 16.dp)) {
                Text(
                    text = data.heading ?: "",
                    style = MaterialTheme.typography.titleMedium,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )

                Spacer(modifier = Modifier.height(4.dp))

                Text(
                    text = data.description ?: "",
                    style = MaterialTheme.typography.bodyMedium,
                    color = Color(0xFF6A6A6A),
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis
                )
            }

            Spacer(modifier = Modifier.height(8.dp))

            // Divider
            Divider(color = Color(0xFFE0E0E0), thickness = 1.dp)

            // Footer Row
            Row(
                modifier = Modifier
                    .padding(12.dp)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (data.service.equals("Free",true)) {
                    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                        Text(
                            text = "Free",
                            style = MaterialTheme.typography.labelMedium.copy(
                                fontWeight = FontWeight.SemiBold,
                                fontSize = 12.sp
                            ),
                            color = MaterialTheme.colorScheme.error,
                            modifier = Modifier
                                .background(MaterialTheme.colorScheme.error.copy(alpha = 0.2f), RoundedCornerShape(Scale.CORNER_SMALL))
                                .padding(horizontal = 8.dp, vertical = 4.dp)
                        )
                        if(!data.displayPrice.isNullOrEmpty() && !data.displayPrice.equals("0",true)){
                            Text(
                                text = "${initializeCurrency()}${data.displayPrice}",
                                color = Color.Black,
                                style = MaterialTheme.typography.titleSmall,
                                modifier = Modifier.padding(start = 8.dp)
                            )
                        }

//                        if (!data.displayPrice.isNullOrEmpty() && !data.displayPrice.equals("0", true)) {
//                            val priceToDisplay = if (initializeCurrency().equals("$", true)) {
//                                data.coInternationalPrice ?: data.displayPrice
//                            } else {
//                                data.displayPrice ?: data.coInternationalPrice
//                            }
//
//                            Text(
//                                text = "${initializeCurrency()} $priceToDisplay",
//                                color = Color.Black,
//                                fontStyle = FontStyle.Italic,
//                                fontSize = 16.sp,
//                                style = MaterialTheme.typography.titleSmall.copy(
//                                    textDecoration = TextDecoration.LineThrough
//                                ),
//                                modifier = Modifier.padding(start = 8.dp)
//                            )
//
//                        }

                    }
                } else {
                    val priceToDisplay = data.coInternationalPrice ?: data.displayPrice
                    Text(
                        //text = if (currencyType) "$${data.displayPrice}" else "₹${data.displayPrice}",
                        text = "${initializeCurrency()}${priceToDisplay}",
                        color = Color.Black,
                        style = MaterialTheme.typography.titleSmall
                    )
                }

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = star_rating_color,
                        modifier = Modifier.size(14.dp)
                    )
                    ConsultantRating(data.expertDetail?.expertReviews?.toDouble())
                }
            }
        }
    }
}

