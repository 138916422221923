package tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatDurationData(
    @SerialName("type") val type: String?,
    @SerialName("balance") val balance: String?,
    @SerialName("expert_id") val expertId: Int?,
    @SerialName("expert_name") val expertName: String?,
    @SerialName("expert_phone") val expertPhone: String?,
    @SerialName("user_id") val userId: String?,
    @SerialName("per_minute_price") val _perMinutePrice: String?,
    @SerialName("call_per_minute_price") val _callPerMinutePrice: String?,
    @SerialName("emergency_price") val _emergency_price: String?,
    @SerialName("total_call_duration") val _totalCallDuration: String?,
    @SerialName("total_chat_duration") val _totalChatDuration: String?,
    @SerialName("plan_amount") val planAmount: Int?,
    @SerialName("plan_name") val planName: String?,
    @SerialName("plan_id") val planId: Int?,
    @SerialName("expert_category") val expertCategory: String?,
    @SerialName("flash_price") val flashPrice: Int?,
    @SerialName("minbalance") val minimumBalance: String? = "",
    @SerialName("minduration") val minimumDuration: String?,
    @SerialName("activestatus") val activeStatus: String?,
){

    val perMinutePrice: String?
        get() =   _emergency_price ?: _perMinutePrice

    val totalChatDuration: String?
        get() = _totalCallDuration ?: _totalChatDuration

//    val balanceAsInt: Number
//        get() = balance?.toIntOrNull() ?: balance?.toFloatOrNull() ?: 0
//
//    val minimumBalanceAsInt: Number
//        get() = minimumBalance?.toIntOrNull() ?: balance?.toFloatOrNull() ?: 0

    val balanceAsInt: Int
        get() = balance?.toIntOrNull() ?: balance?.toFloatOrNull()?.toInt() ?: 0

    val minimumBalanceAsInt: Int
        get() = minimumBalance?.toIntOrNull() ?: minimumBalance?.toFloatOrNull()?.toInt() ?: 0

}
