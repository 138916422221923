package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CallDetailsData(
    @SerialName
        ("amount")
    val amount: String,
    @SerialName
        ("booking_date_time")
    val bookingDateTime: String,
    @SerialName
        ("call_booking_date")
    val callBookingDate: String,
    @SerialName
        ("call_booking_time")
    val callBookingTime: String,
    @SerialName
        ("call_duration")
    val callDuration: String?,
    @SerialName
        ("call_place_again")
    val callPlaceAgain: Int,
    @SerialName
        ("created_at")
    val createdAt: String,
    @SerialName
        ("exotel_call_status")
    val exotelCallStatus: String?,
    @SerialName
        ("expert_id")
    val expertId: Int,
    @SerialName
        ("expert_price")
    val expertPrice: String,
    @SerialName
        ("id")
    val id: Int?,
    @SerialName
        ("parent_id")
    val parentId: Int,
    @SerialName
        ("ramaining_balance")
    val ramainingBalance: String,
    @SerialName
        ("sid")
    val sid: String,
    @SerialName
        ("slot_for")
    val slotFor: String,
    @SerialName
        ("updated_at")
    val updatedAt: String,
    @SerialName
        ("user_id")
    val userId: Int,
    @SerialName
        ("winyway_price")
    val winywayPrice: String,
    @SerialName
        ("expert_info")
    val expertInfo: ExpertInfo?,
    @SerialName("user_rating") val userRating: String?,
    @SerialName("user_review") val userReview: String?
)
