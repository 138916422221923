package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.persentation.HomePageLoadingProgressDialog
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.ExpertListState
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.widget.ExpertListSuccessPage


@Composable
fun ExpertListPage(
    component: ExpertListPageComponent
) {
    val state by component.state.collectAsState()
    val markFavoriteState by component.markedFavoriteState.collectAsState()

    // Handling ExpertListState
    when (val resources = state) {
        is ExpertListState.Loading -> {
            Box(
                modifier = Modifier.fillMaxSize().background(background_color),
                contentAlignment = Alignment.Center
            ) {
                HomePageLoadingProgressDialog(
                    onDismissRequest = { },
                    backgroundColor = Color(0xFF2A2A2A),
                    loadingColor = Color.White
                )
            }
        }

        is ExpertListState.Success -> {

            ExpertListSuccessPage(
                consultants = resources.consultants,
                component = component,
                isFreeApplicable = resources.isFreeApplicable
            )
        }

        is ExpertListState.Error -> {
            Box(
                modifier = Modifier.fillMaxSize().background(Color(0xFF2A2A2A)),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "Error: ${resources.message}",
                    color = Color.Red,
                    style = TextStyle(fontSize = 16.sp)
                )
            }
        }
    }

    // Handling MarkFeavoriteState
//    when (val resource = markFavoriteState) {
//        is MarkFeavoriteState.Error -> {
//            Box(
//                modifier = Modifier
//                    .fillMaxSize()
//                    .background(Color(0xFF2A2A2A)),
//                contentAlignment = Alignment.Center
//            ) {
//                Text(
//                    text = "Error: ${resource.message}",
//                    color = Color.Red,
//                    style = TextStyle(fontSize = 16.sp)
//                )
//            }
//        }
//        is MarkFeavoriteState.Loading -> {
//            Box(
//                modifier = Modifier
//                    .fillMaxSize()
//                    .background(background_color),
//                contentAlignment = Alignment.Center
//            ) {
//                HomePageLoadingProgressDialog(
//                    onDismissRequest = { },
//                    backgroundColor = Color(0xFF2A2A2A),
//                    loadingColor = Color.White
//                )
//            }
//        }
//        is MarkFeavoriteState.Success -> {
//            // Handle successful marking of favorite here, perhaps show a toast or update UI
//        }
//    }
}

