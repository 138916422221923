package tta.destinigo.talktoastro.core.persentation

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.google_play
import org.jetbrains.compose.resources.painterResource


@Composable
fun SampleDialog(
    onDismissRequest: () -> Unit,
    onPlayStore: () -> Unit,
    //onAppStore: () -> Unit
) {
    AlertDialog(
        onDismissRequest = onDismissRequest,
        containerColor = Color.White,
        title = {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(8.dp) // Added padding around title
            ) {
                Text(
                    text = "Welcome to Our Website!",
                    style = MaterialTheme.typography.titleLarge,
                    fontSize = 16.sp,
                    color = MaterialTheme.colorScheme.onSurface
                )
                IconButton(onClick = onDismissRequest) {
                    Icon(Icons.Filled.Close, contentDescription = "Close")
                }
            }
        },
        text = {
            Text(
                text ="Thank you for joining us. We hope you enjoy using our Website. Please download our app from your preferred store.",
                style = MaterialTheme.typography.bodyMedium,
                color = MaterialTheme.colorScheme.onSurface,
                fontSize = 14.sp,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(horizontal = 5.dp) // Added padding to text
            )
        },
        confirmButton = {
            //AppButton("Play Store",modifier = Modifier.padding(8.dp), onClick = onPlayStore,Res.drawable.google_play,false)
        Image(painter = painterResource(Res.drawable.google_play),
            contentDescription = "Play Store",
            modifier = Modifier.wrapContentWidth().wrapContentHeight().clickable {  onPlayStore() },
            contentScale = ContentScale.Crop
        )

        },
        dismissButton = {
  //          AppOutlineButton("Cancel",modifier = Modifier.padding(8.dp), onClick = onDismissRequest,null,false)
        },
        shape = RoundedCornerShape(16.dp),
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 5.dp)
    )
}

