package tta.destinigo.talktoastro.feature_call_consultation.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_call_consultation.data.api.CallConsultationApi
import tta.destinigo.talktoastro.feature_call_consultation.domain.CallConsultationInfoRepo
import tta.destinigo.talktoastro.feature_call_consultation.domain.VoIPCallRepo

val callConsultationModule = module {
    factory { CallConsultationApi() }
    factory { CallConsultationInfoRepo(get()) }
    factory { VoIPCallRepo(get()) }
}