package tta.destinigo.talktoastro.feature_courses.persentation.courses_page


import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.DateRange
import androidx.compose.material.icons.filled.Timeline
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import tta.destinigo.talktoastro.core.persentation.CommonButton
import tta.destinigo.talktoastro.core.persentation.DottedLineDivider
import tta.destinigo.talktoastro.core.persentation.StarRating
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.core.theme.primaryDarkerColor

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CategoryChips(
    modifier: Modifier,
    categories: List<String>,
    selectedCategory: String,
    onCategorySelected: (String) -> Unit
) {

    val windowWidth = calculateWindowSizeClass().widthSizeClass
    val horizontalArrangment =
        if (windowWidth == WindowWidthSizeClass.Compact) Arrangement.Start else Arrangement.End


    LazyRow(
        modifier = modifier.fillMaxWidth(),
        horizontalArrangement = horizontalArrangment,
        contentPadding = PaddingValues(horizontal = 8.dp, vertical = 12.dp)
    ) {

        if (windowWidth == WindowWidthSizeClass.Compact) {
            item {
                Spacer(modifier = Modifier.width(8.dp))
            }

        }

        items(categories.size) { index ->
            val category = categories[index]
            Chip(
                text = category,
                isSelected = category == selectedCategory,
                onClick = { onCategorySelected(category) }
            )
            Spacer(modifier = Modifier.width(8.dp))
        }
    }
}

@Composable
fun Chip(
    text: String,
    isSelected: Boolean,
    onClick: () -> Unit
) {
    Surface(
        modifier = Modifier
            .clickable { onClick() },
        color = if (isSelected) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.surface,
        shape = RoundedCornerShape(16.dp),
        shadowElevation = Scale.ELEVATION_MEDIUM
    ) {
        Text(
            text = text,
            style = MaterialTheme.typography.bodyMedium.copy(
                color = if (isSelected) MaterialTheme.colorScheme.onPrimary else MaterialTheme.colorScheme.onSurface,
                fontWeight = FontWeight.Bold
            ),
            modifier = Modifier.padding(horizontal = 16.dp, vertical = 8.dp)
        )
    }
}

@Composable
fun MasterclassCard(
    masterclass: CourseModel,
    modifier: Modifier,
    onClickRegister: (courseData: CourseModel) -> Unit
) {
    Card(
        modifier = modifier,
        shape = RoundedCornerShape(Scale.CORNER_SMALL),
        colors = CardDefaults.cardColors(
            containerColor = Color.White
        ),
        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_MEDIUM)
    ) {
        Column(
            modifier = Modifier.fillMaxWidth().padding(12.dp)
        ) {

            AsyncImage(
                model = masterclass.imagePath.toString(),
                modifier = Modifier.fillMaxWidth().height(180.dp).clip(RoundedCornerShape(10f)),
                contentScale = ContentScale.Crop,
                contentDescription = null,
            )

            Spacer(modifier = Modifier.height(8.dp))

            DateAndTime(
                modifier = Modifier,
                date = masterclass.date ?: "",
                time = masterclass.durationDays.toString()
            )

            DottedLineDivider()

            Spacer(modifier = Modifier.height(8.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {

                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    Box(
                        modifier = Modifier.background(
                            shape = CircleShape, color = primaryColor.copy(0.2f)
                        )
                    ) {
                        Text(
                            text = masterclass.categoryType ?: "New",
                            style = TextStyle(
                                fontSize = 13.sp,
                                fontWeight = FontWeight.SemiBold,
                                color = primaryDarkerColor
                            ),
                            modifier = Modifier.padding(horizontal = 8.dp, vertical = 2.dp)
                        )
                    }
                }

                Box(
                    modifier = Modifier.weight(2f),
                    contentAlignment = Alignment.TopEnd
                ) {

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        StarRating(
                            rating = masterclass.rating?.toFloatOrNull() ?: 5F,
                            filledStarColor = primaryDarkerColor
                        )

                        Spacer(modifier = Modifier.width(4.dp))

                        Text(
                            text = "${masterclass.rating} ${masterclass.reviewsCount}",
                            style = TextStyle(
                                fontSize = 13.sp,
                                fontWeight = FontWeight.SemiBold,
                                color = Color.Black
                            ),
                        )
                    }
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Column {
                Text(
                    text = masterclass.title ?: "",
                    style = TextStyle(fontSize = 18.sp, fontWeight = FontWeight.Bold),
                    modifier = Modifier.padding(bottom = 4.dp)
                )

                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.weight(1f)
                    ) {
                        Text(
                            text = masterclass.price.toString(),
                            style = MaterialTheme.typography.bodyMedium.copy(
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                color = primaryDarkerColor
                            ),
                            modifier = Modifier.padding(top = 4.dp)
                        )

                        Spacer(
                            modifier = Modifier.width(4.dp)
                        )

                        Text(
                            text = masterclass.originalPrice.toString(),
                            fontSize = 15.sp,
                            fontWeight = FontWeight.SemiBold,
                            textDecoration = TextDecoration.LineThrough,
                            modifier = Modifier.padding(top = 4.dp)
                        )

                    }

                    Spacer(modifier = Modifier.height(8.dp))

                    CommonButton(
                        onClick = {
                            onClickRegister.invoke(masterclass)
                        },
                        modifier = Modifier.weight(1f),
                        buttonText = "Register Now",
                        buttonColor = primaryDarkerColor
                    )
                }


            }
        }
    }
}

@Composable
fun DateAndTime(
    modifier: Modifier,
    date: String,
    time: String
) {
    Row(
        horizontalArrangement = Arrangement.Start,
        modifier = modifier.padding(8.dp).fillMaxWidth()
    ) {

        TextWithStartIcon(
            modifier = Modifier.weight(2f),
            icon = Icons.Default.DateRange,
            text = date,
        )

        Spacer(modifier = Modifier.width(8.dp))

        TextWithStartIcon(
            horizontalArrangement = Arrangement.End,
            modifier = Modifier.weight(1f),
            icon = Icons.Default.Timeline,
            text = time,
        )
    }
}

@Composable
fun TextWithStartIcon(
    modifier: Modifier,
    text: String,
    horizontalArrangement: Arrangement.Horizontal = Arrangement.Start,
    icon: ImageVector,
    iconModifier: Modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL),
    textStyle: TextStyle = TextStyle(
        fontSize = 14.sp,
        fontWeight = FontWeight.SemiBold,
        color = Color.Black
    )
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = horizontalArrangement,
        modifier = modifier
    ) {
        Icon(
            imageVector = icon,
            contentDescription = null,
            tint = Color.Black,
            modifier = iconModifier
        )

        Spacer(modifier = Modifier.width(4.dp))

        Text(
            text = text,
            style = textStyle,
        )
    }
}

