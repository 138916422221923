package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Error
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.SheetState
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.CORNER_LARGE
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.ChatHistoryComponent
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.request.RequestReviewSubmit
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.widget.CustomRatingBar
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_review_submit.ExpertReviewSubmitHost
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun UserFeedback(
    expert_id: String,
    profileIcon: String,
    name: String,
    onRateNow: () -> Unit,
    onSkip: () -> Unit,
    component: ChatHistoryComponent
) {
    val sheetState = remember { SheetState(skipPartiallyExpanded = true) }
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> {
            ModalBottomSheet(
                sheetState = sheetState,
                onDismissRequest = { onSkip.invoke() },
                shape = RoundedCornerShape(topStart = CORNER_LARGE, topEnd = CORNER_LARGE),
                modifier = Modifier
                    .fillMaxWidth()
                    .wrapContentHeight()
            ) {
                CharReviewContent(
                    expert_id = expert_id,
                    profileIcon = profileIcon,
                    name = name,
                    onRateNow = onRateNow,
                    onSkip = onSkip,
                    component = component
                )
            }
        }
        else -> {
            Dialog(onDismissRequest = { onSkip() }) {
                Surface(
                    shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
                    color = Color.White,
                    modifier = Modifier.padding(16.dp).background(Color.White)
                ) {
                    CharReviewContent(
                        expert_id = expert_id,
                        profileIcon = profileIcon,
                        name = name,
                        onRateNow = onRateNow,
                        onSkip = onSkip,
                        component = component
                    )
                }
            }
        }
    }

}
@Composable
private fun CharReviewContent(
    expert_id: String,
    profileIcon: String,
    name: String,
    onRateNow: () -> Unit,
    onSkip: () -> Unit,
    component: ChatHistoryComponent
) {
    var reviewText by remember { mutableStateOf("") }
    var reviewErrorMessage by remember { mutableStateOf("") }
    //val reviewState = expertDetailsPageComponent.submitReviewState.value
   // val reviewState = expertDetailsPageComponent.submitReviewStateConfirmation.value

//    if(reviewState == true){
//        showToast("Review Submit Successful")
//        onSkip.invoke()
//    }
//    when(val currentState = reviewState){
//        is SubmitExpertReviewState.Error -> {
//            Logger.d { currentState.msg }
//        }
//        is SubmitExpertReviewState.Loading -> {
//            Logger.d { "${currentState.isLoading}" }
//        }
//        is SubmitExpertReviewState.Success -> {
//            showToast("Review Submit Successful")
//            onSkip.invoke()
//        }
//    }
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
            .wrapContentHeight(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        // Profile section with profile image and name
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {

            ExpertProfileIcon(
                profileImageUrl = profileIcon,
                modifier = Modifier.size(70.dp)
            )

            Spacer(modifier = Modifier.width(16.dp))

            Column(
                verticalArrangement = Arrangement.Center
            ) {
                Text(
                    text = name.ifEmpty { "Unknown" },
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    style = MaterialTheme.typography.titleMedium.copy(fontWeight = FontWeight.Bold)
                )
//                Text(
//                    text = "Please rate us!",
//                    style = MaterialTheme.typography.bodySmall,
//                    color = Color.Gray
//                )
            }
        }

        Spacer(modifier = Modifier.height(16.dp))
        ChatHistoryRatingScreen(onLoginClick = {}, component = component, id = expert_id, onClickSubmit = {onSkip.invoke()})
        Spacer(modifier = Modifier.height(8.dp))

        // Not Now button
        TextButton(
            onClick = onSkip,
            modifier = Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, bottom = 16.dp),
            colors = ButtonDefaults.buttonColors(primaryColor.copy(alpha = 0.14f)),
            shape = RoundedCornerShape(Scale.CORNER_SMALL)
        ) {
            Text("Not Now", style = MaterialTheme.typography.labelSmall, color = MaterialTheme.colorScheme.primary)
        }

        Spacer(modifier = Modifier.height(16.dp))
    }
}




@Composable
fun ChatHistoryRatingScreen(
    onLoginClick: () -> Unit,
    component: ChatHistoryComponent,
    id: String?,
    onClickSubmit: () -> Unit
) {
    var rating by remember { mutableStateOf(0) }
    var reviewText by remember { mutableStateOf("") }
    var errorMessage by remember { mutableStateOf("") }
    var ratingErrorMessage by remember { mutableStateOf("") }
    var reviewErrorMessage by remember { mutableStateOf("") }

    var allReviewFieldCorrect by remember { mutableStateOf(false) }

    Column(modifier = Modifier.padding(16.dp)) {
        Text(
            text = "Your opinion matters to us!",
            style = TextStyle(fontSize = 16.sp, fontWeight = FontWeight.Bold),
            modifier = Modifier
                .padding(bottom = 10.dp)
                .align(Alignment.CenterHorizontally)
        )
        CustomRatingBar(
            maxRating = 5,
            initialRating = rating,
            onRatingChanged = { newRating ->
                rating = newRating
                ratingErrorMessage = ""
            },
        )
        if (ratingErrorMessage.isNotEmpty()) {
            Text(
                text = ratingErrorMessage,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.padding(start = 16.dp, top = 4.dp)
            )
        }
        Spacer(modifier = Modifier.height(16.dp))

        OutlinedTextField(
            value = reviewText,
            onValueChange = {
                if (it.length <= 2000) {
                    reviewText = it
                    reviewErrorMessage = ""
                } else {
                    reviewErrorMessage = "Max Text Limit 2000"
                }
            },
            label = { Text("Write your review here") },
            isError = reviewErrorMessage.isNotEmpty(),
            modifier = Modifier.fillMaxWidth(),
            trailingIcon = {
                if (reviewErrorMessage.isNotEmpty()) {
                    Icon(
                        imageVector = Icons.Default.Error,
                        contentDescription = "Error",
                        tint = MaterialTheme.colorScheme.error
                    )
                }
            }
        )

        if (reviewErrorMessage.isNotEmpty()) {
            Text(
                text = reviewErrorMessage,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.padding(start = 16.dp, top = 4.dp)
            )
        }
        Spacer(modifier = Modifier.height(16.dp))

        if (allReviewFieldCorrect) {
            ExpertReviewSubmitHost(
                { onLoginClick.invoke() },
                { allReviewFieldCorrect = false },
                {
                    CoroutineScope(Dispatchers.Default).launch {
                        val result = component.postExpertReview(
                            RequestReviewSubmit(
                                rating = rating.toString(),
                                remarks = reviewText,
                                //consultantId = data.id.toString()
                                consultantId = (id ?: 0).toString()
                            )
                        )
                    }
                    onClickSubmit.invoke()
                }
            )
        }

        Button(
            onClick = {
                // Validate the inputs when the button is clicked
                var isValid = true

                if (rating == 0) {
                    ratingErrorMessage = "Please select a rating"
                    isValid = false
                }

                if (reviewText.isBlank()) {
                    reviewErrorMessage = "Review text cannot be empty"
                    isValid = false
                } else if (reviewText.length > 2000) {
                    reviewErrorMessage = "Max Text Limit 2000"
                    isValid = false
                }

                if (isValid) {
                    allReviewFieldCorrect = true
                } else {
                    errorMessage = "Please correct the errors above"
                }
            },
            modifier = Modifier.fillMaxWidth(),
            colors = ButtonDefaults.buttonColors(Color(0xFF1069BC)),
            shape = RoundedCornerShape(Scale.CORNER_SMALL)
        ) {
            Text("Submit")
        }

        if (errorMessage.isNotEmpty()) {
            Text(
                text = errorMessage,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.padding(top = 8.dp)
            )
        }
    }
}

