package tta.destinigo.talktoastro.feature_courses.persentation.widget

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@Composable
fun FaqSection() {
    Column(
        modifier = Modifier.fillMaxWidth().padding(16.dp)
    ) {
        Text(
            text = "Frequently asked questions?",
            fontSize = 24.sp,
            fontWeight = FontWeight.Bold,
            color = Color(0xFF6A1B9A), // Purple
            modifier = Modifier.align(Alignment.CenterHorizontally)
        )
        Spacer(modifier = Modifier.height(16.dp))

        // FAQ Items
        FaqItem(question = "Who can take this astrology course?", answer = "Astrology classes are for everyone who wants to learn astrology and also those who want to practice it professionally.")
        FaqItem(question = "How long is the course?", answer = "This course is of 12 classes.")
        FaqItem(question = "Can I practice professionally after this course?", answer = "Yes, you can practice professionally. Winyway even offers placement support after undergoing specific training and clearing the tests.")
        FaqItem(question = "Is the course certified?", answer = "Yes, all Winyway courses are certified.")
        FaqItem(question = "Will I get any assignments?", answer = "Yes, assignments will be given regularly.")
        FaqItem(question = "When will the classes be conducted?", answer = "Classes will be conducted on Zoom on weekends. We have flexible timing options.")
        FaqItem(question = "Who will be the instructors?", answer = "Instructors are the best in the industry. Multiple instructors like Astro Priyanka, Bhawesh Srivastava, Astro Saurabh, and more will take classes.")
        FaqItem(question = "If I miss some classes, will a recording be provided?", answer = "Yes, recordings of the classes will be provided by us.")
        FaqItem(question = "How can I know more about it?", answer = "Please get in touch through the customer care chat. We will contact you over a call and will clarify your queries. You can even press the button to Know More.")
    }
}

@Composable
fun FaqItem(question: String, answer: String) {
    var isExpanded by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        // Question Row
        Row(
            modifier = Modifier.fillMaxWidth().padding(vertical = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                modifier = Modifier.weight(0.7f),
                text = question,
                fontSize = 18.sp,
                fontWeight = FontWeight.Medium
            )
            IconButton(onClick = { isExpanded = !isExpanded }) {
                Icon(
                    modifier = Modifier.weight(0.3f),
                    imageVector = if (isExpanded) Icons.Default.Remove else Icons.Default.Add,
                    contentDescription = if (isExpanded) "Collapse" else "Expand"
                )
            }
        }

        // Answer Section
        AnimatedVisibility(visible = isExpanded) {
            Column(modifier = Modifier.padding(start = 16.dp)) {
                //Divider(color = Color.Gray, thickness = 1.dp)
                Spacer(modifier = Modifier.height(4.dp))
                Text(text = answer, fontSize = 16.sp)
                Spacer(modifier = Modifier.height(4.dp))
            }
        }

        HorizontalDivider(thickness = 1.dp, color = Color.Gray)
    }
}