package tta.destinigo.talktoastro.feature_expert_consultation.data.remote.request


import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RequestConsultInfo(
    @SerialName("code") val code: Int?, // 200
    @SerialName("data") val `data`: Data?,
    @SerialName("message") val message: String?, // Slider data fetched successfully
    @SerialName("status") val status: Boolean// true
)


@Serializable
data class Data(
    @SerialName("free_call_availability") val freeCallAvailability: Int? = null,
    val consultant: RequestConsultant?
)
