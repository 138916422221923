package tta.destinigo.talktoastro.feature_common.persentation.more_tab.component

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.ChatConversationState
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.api.ChatHistoryApi
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.request.ChatHistoryDetailRequest
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.persentation.BasePagingSource
import tta.destinigo.talktoastro.core.persentation.PaginationItems
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.response.ChatConversationData

class ChatConversationComponent(
    context: ComponentContext,
    private val _chatId: String,
    private val _expertName: String,
    private val _expertImage: String,
    private val _chatDuration: String,
    private val onBackPress: () -> Unit
) : ComponentContext by context, KoinComponent, BasePagingSource<ChatConversationData>() {

    // Public getters for data
    val chatId get() = _chatId
    val expertName get() = _expertName
    val expertImage get() = _expertImage
    val chatDuration get() = _chatDuration
    fun onBackPress() = onBackPress.invoke()

    // Coroutine scope
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)

    // State for managing UI
    private val _state = MutableStateFlow<ChatConversationState>(ChatConversationState.Loading)
    val state: StateFlow<ChatConversationState> = _state

    // Dependency injection of API
    private val api: ChatHistoryApi by inject()

    // Pagination properties
    private var currentPage = 1
    var isLastPage = false
    var isLoading = false

    init {
        lifecycle.doOnStart {
            Logger.d { "Chat Conversation History Screen Component onStart" }
            loadChatHistory()
        }

        lifecycle.doOnDestroy {
            Logger.d { "Chat History Component onDestroy" }
            coroutineScope.cancel()
        }
    }

    // Load chat history
    fun loadChatHistory() {
        coroutineScope.launch {
            try {
                fetchData(currentPage, 10) // Assuming a limit of 20 messages per page
            } catch (e: Exception) {
                Logger.e { "Failed to load chat history: ${e.message}" }
                _state.value = ChatConversationState.Error(e.message ?: "Unknown error")
            }
        }
    }

    // Implement fetchData from BasePagingSource
    override suspend fun fetchData(page: Int, limit: Int): PaginationItems<ChatConversationData> {
        return try {
            val request = ChatHistoryDetailRequest(page = page, chatId = chatId)
            val response = api.loadChatConversationData(request)

            when (response) {
                is Resources.Error -> {
                    Logger.d { "Error fetching chat history: ${response.msg}" }
                    throw Exception(response.msg ?: "Unknown error")
                }
                is Resources.Loading -> {
                    Logger.d { "Loading chat data..." }
                    PaginationItems(emptyList())
                }
                is Resources.Success -> {
                    val chats = response.data
                    val chatData: List<ChatConversationData> = listOfNotNull(chats)

                    Logger.d { "Fetched chat history: ${response.data}" }

                    if (chats == null) {
                        isLastPage = true
                        _state.value = ChatConversationState.Success(emptyList())
                    } else {
                        val currentState = (state.value as? ChatConversationState.Success)?.chatMessages ?: emptyList()
                        val newChatHistory = currentState + chatData
                        _state.value = ChatConversationState.Success(newChatHistory)
                        currentPage++
                    }
                    PaginationItems(items = chatData)
                }
            }
        } catch (e: Exception) {
            Logger.e { "Exception occurred: ${e.message}" }
            throw e
        }
    }
}