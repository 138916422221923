package tta.destinigo.talktoastro.feature_common.data.response

import tta.destinigo.talktoastro.feature_common.data.request.RequestSlider
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SliderData(
    @SerialName("slider") val slider: List<RequestSlider>
)
