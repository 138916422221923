package tta.destinigo.talktoastro.feature_authentication.persentation.login_page.widget

import kotlinx.serialization.Serializable

@Serializable
data class Country(
    val name: String,
    val dial_code: String,
    val emoji: String,
    val code: String,
)
