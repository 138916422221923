package tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PurchaseHistoryListResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("message") val message: String, // Data fetched successfully
    @SerialName("status") val status: Boolean, // true
    @SerialName("data") val data: PurchaseHistoryPagingData
)
