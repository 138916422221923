package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_room_view.widget

import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.window.DialogProperties
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
fun EndChatConfirmationDialog(
    onConfirm: () -> Unit,
    onDismiss: () -> Unit,
    isDialogOpen: Boolean
) {
    if (isDialogOpen) {
        AlertDialog(
            onDismissRequest = onDismiss,
            title = {
                Text(text = "End Chat")
            },
            text = {
                Text(text = "Are you sure you want to end the chat?")
            },
            confirmButton = {
                TextButton(
                    onClick = {
                        onConfirm()
                        onDismiss()
                    }
                ) {
                    Text(text = "End Chat")
                }
            },
            dismissButton = {
                TextButton(
                    onClick = onDismiss
                ) {
                    Text(text = "Cancel")
                }
            }
        )
    }
}

@Composable
fun ChatEndedDialog(
    onGoToDashboard: () -> Unit,
    isDialogOpen: Boolean
) {
    if (isDialogOpen) {
        AlertDialog(
            onDismissRequest = {
                // Do nothing to prevent the dialog from dismissing when clicking outside
            },
            title = {
                Text(text = "Chat Ended")
            },
            text = {
                Text(text = "The chat has ended. You can go to the dashboard.")
            },
            confirmButton = {
                TextButton(
                    onClick = {
                        onGoToDashboard()
                    }
                ) {
                    Text(text = "Go to Dashboard")
                }
            },
            properties = DialogProperties(
                dismissOnClickOutside = false, // Disable dismissing the dialog when clicking outside
                dismissOnBackPress = false // Allow dismissing with back press (optional)
            )
        )
    }
}

@Preview
@Composable
fun PreviewEndChatConfirmationDialog() {
    var isDialogOpen by remember { mutableStateOf(true) }

    EndChatConfirmationDialog(
        onConfirm = {
            // Handle end chat logic
            isDialogOpen = false
        },
        onDismiss = {
            // Handle dismiss logic
            isDialogOpen = false
        },
        isDialogOpen = isDialogOpen
    )
}