package tta.destinigo.talktoastro.feature_call_consultation.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StartVoiceCallRequest(
    @SerialName("call_id") val consultationId: String?,
//    @SerialName("start_time") val startConsultationTimeStamp: String?,
//    @SerialName("end_time") val endConsultationTimeStamp: String?,
//    @SerialName("expert_id")val expertId:String?,
    @SerialName("status") val status: String?,
)
