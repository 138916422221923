package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_waiting_room

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation.data.request.StartChatRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatWaitingRepo
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_waiting_room.ChatWaitingEvent

class ChatWaitingComponent(
    context: ComponentContext,
    private val _expertId: String,
    private val _expertName: String,
    private val _expertImage: String,
    private val _expertSkills: String,
    private val maxChatDuration:String,
    private val chatId:String,
    private val userUid:String,
    private val event:(ChatWaitingEvent)->Unit
) : ComponentContext by context, KoinComponent {


    val expertId get() = _expertId
    val expertName get() = _expertName
    val expertImage get() = _expertImage
    val expertSkills get() = _expertSkills
    val chatDuration get() = maxChatDuration

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatWaitingRepo by inject()
    private val _state =
        MutableStateFlow<ChatInfoBottomSheetState>(ChatInfoBottomSheetState.Loading(false))
    val state get() = _state.asStateFlow()

    fun onEndConsultation() = onEndChatApi()
    private fun onClosePage() = event.invoke(ChatWaitingEvent.CloseThis)

    init {
        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            coroutineScope.launch {
                event.invoke(ChatWaitingEvent.ConnectToExpert(
                    uid = userUid,
                    onError = {

                    },
                    onSuccess = {
                        event.invoke(
                            ChatWaitingEvent.StartConsultation(
                                ""
                            )
                        )
                    }
                ))
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private fun onLoading(isLoading:Boolean){
        coroutineScope.launch {
            _state.emit(ChatInfoBottomSheetState.Loading(isLoading))
        }
    }


    private fun onEndChatApi() {
        coroutineScope.launch {
            val request = StartChatRequest(
                expertId = chatId, type = null
            )
            api.endChat(request).collectLatest {

                when (it) {
                    is Resources.Error -> {
                        Logger.d("On Error -> ${it.msg}")
                        onClosePage()
                    }

                    is Resources.Loading -> {
                        Logger.d("On Loading -> ${it.isLoading}")
                        onLoading(it.isLoading)
                    }

                    is Resources.Success -> {
                        Logger.d("on Success -> ${it.data}")
                        onClosePage()
                    }
                }
            }
        }
    }


}