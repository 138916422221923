package tta.destinigo.talktoastro.feature_article.persentation.posts_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch

import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_article.data.response.Post
import tta.destinigo.talktoastro.feature_article.domain.ArticlesRepo

class ArticlesComponent(
    componentContext: ComponentContext,
    private val onClosePage:()->Unit,
    private val openPost:(String)->Unit,
):ComponentContext by componentContext, KoinComponent   {

    private val coroutineScope by lazy { CoroutineScope(SupervisorJob() + Dispatchers.Main) }
    private val api: ArticlesRepo by inject()

    private val _state = MutableStateFlow<Resources<List<Post?>>>(Resources.Loading(false))
    val state get() = _state.asStateFlow()
    private val articleState = MutableStateFlow<List<Post?>?>(null)
    val article get() = articleState.asStateFlow()


    fun onClosePage() = onClosePage.invoke()
    fun openPost(slug:String) = openPost.invoke(slug)


    init {
        lifecycle.doOnCreate {
            coroutineScope.launch {
                getPosts()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun getPosts() {
        _state.emit(Resources.Loading(true)) // Explicitly emit loading before starting

        api.getArticleList().collect { resource ->
            when (resource) {
                is Resources.Error -> {
                    Logger.d("log -> ${resource.msg}")
                    _state.emit(Resources.Error(resource.msg))
                }
                is Resources.Success -> {
                    Logger.d("log -> success")
                    articleState.emit(resource.data)
                }

                is Resources.Loading -> Unit
            }
        }
        _state.emit(Resources.Loading(false)) // Emit loading false when done
    }


}