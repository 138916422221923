package tta.destinigo.talktoastro.feature_authentication.persentation.otp_validation_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.persentation.getDeviceType
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.user_feature_authentication.data.request.RequestOtpRequest
import tta.destinigo.talktoastro.user_feature_authentication.data.request.VerifyOtpRequest
import tta.destinigo.talktoastro.user_feature_authentication.data.response.VerifyOtpResponseData
import tta.destinigo.talktoastro.user_feature_authentication.domain.CurrentScreen
import tta.destinigo.talktoastro.user_feature_authentication.domain.OtpValidationUseCases
import tta.destinigo.talktoastro.user_feature_authentication.persentation.otp_validation_page.OtpValidationState
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class OtpValidationComponent(
    private val componentContext: ComponentContext,
    private val mobileNumber: String,
    private val mobileCode: String,
    private val isWhatsappUpdatesChecked: Boolean,
    private val onRegisterEvent: () -> Unit,
    private val onDashboardEvent: () -> Unit
) : ComponentContext by componentContext, KoinComponent {

    val _mobileNumber get() = mobileNumber

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val otpValidationUseCases: OtpValidationUseCases by inject()
    private val _state = MutableStateFlow<OtpValidationState>(OtpValidationState.OnLoading(false))
    val state: StateFlow<OtpValidationState> get() = _state

    private var _otpValidationState =
        MutableStateFlow<Resources<VerifyOtpResponseData>>(Resources.Loading(false))
    val otpValidationState: StateFlow<Resources<VerifyOtpResponseData>> get() = _otpValidationState

    private fun onOtpSentState() {
        _state.value = OtpValidationState.OnOtpSent("Otp Sent")
    }

    private fun onErrorState(message: String) {
        _state.value = OtpValidationState.OnError(message)
    }

    private fun onLoadingState(isLoading: Boolean) {
        _state.value = OtpValidationState.OnLoading(isLoading)
    }

    init {
        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            requestOpt()

        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }

    }

    private fun requestOpt() {
        coroutineScope.launch {
            val otpRequest = RequestOtpRequest(
                mobile = mobileNumber,
                whatsappUpdate = if (isWhatsappUpdatesChecked) 1 else 0,
                mobileCode = mobileCode,
                type = "user",
                domain = "talktoastro.com"
            )
            otpValidationUseCases.requestOtp(otpRequest).collectLatest {
                when (it) {
                    is Resources.Error -> onErrorState(it.msg ?: "Unexpected Error")
                    is Resources.Loading -> onLoadingState(it.isLoading)
                    is Resources.Success -> onOtpSentState()
                }
            }
        }
    }

    fun event(event: OtpValidationEvent) {
        Logger.d("event hit")
        when (event) {
            is OtpValidationEvent.OnLoginRegister -> {
                Logger.d("On Otp Validate by page")
                onRegisterEvent.invoke()
            }

            is OtpValidationEvent.OnResendOTP -> {
                requestOpt()
            }

            is OtpValidationEvent.OnValidateOTP -> {
                val getDeviceType = getDeviceType()

                submitOtpValidation(
                    VerifyOtpRequest(
                        mobileCode = mobileCode,
                        mobile = mobileNumber,
                        otp = event.otpFields.joinToString(""),
                        type = "user",
                        whatsappUpdates = if (isWhatsappUpdatesChecked) 1 else 0,
                        notificationToken = "",
                        deviceDetails = getDeviceType.name,
                        loginDevice = "android"
                    )
                )
            }
        }
    }

    private fun submitOtpValidation(request: VerifyOtpRequest) {
        coroutineScope.launch {

            val response = otpValidationUseCases.onOtpValidation(request)
            response.collect {

                when (it) {
                    is Resources.Error -> {
                        Logger.d("submit otp response error -> ${it.msg}")
                        onErrorState(it.msg ?: "Unexpected Error")
                    }

                    is Resources.Loading -> {
                        Logger.d("submit otp response loading -> ${it.isLoading}")
                        onLoadingState(it.isLoading)
                    }

                    is Resources.Success -> {
                        if (it.data == CurrentScreen.LOGIN_USER) onDashboardEvent.invoke()
                        else onRegisterEvent.invoke()
                    }
                }
            }
        }
    }

}