package tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PurchaseHistoryPagingData(
    @SerialName("current_page") val currentPage: Int, // 1
    @SerialName("data") val `data`: List<PurchaseHistoryData>,
    @SerialName("first_page_url") val firstPageUrl: String, // http://localhost/winywayapi/public/api/v1/custom-order/purchase-history?page=1
    @SerialName("from") val from: Int, // 1
    @SerialName("last_page") val lastPage: Int, // 1
    @SerialName("last_page_url") val lastPageUrl: String, // http://localhost/winywayapi/public/api/v1/custom-order/purchase-history?page=1
    @SerialName("next_page_url") val nextPageUrl: String?, // null
    @SerialName("path") val path: String, // http://localhost/winywayapi/public/api/v1/custom-order/purchase-history
    @SerialName("per_page") val perPage: Int, // 10
    @SerialName("prev_page_url") val prevPageUrl: String?, // null
    @SerialName("to") val to: Int, // 2
    @SerialName("total") val total: Int // 2
)
