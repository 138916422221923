package tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottom_sheet_info

import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

sealed class CallConsultationEvent {
    data class onNavigateRechargeCheckOut(
        val rechargeData: RechargeData
    ): CallConsultationEvent()

    data object onNavigateChoseRechargePack : CallConsultationEvent()
    data class onNavigateCallWaitingPage(
         val _expertId: String,
         val _expertName: String,
         val _expertImage: String,
         val _expertSkills: String,
         val onClose: () -> Unit,
    ):CallConsultationEvent()
}