package tta.destinigo.talktoastro.feature_common.persentation.home_tab

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.HomePageLoadingProgressDialog
import tta.destinigo.talktoastro.core.platform.isJS
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryDark
import tta.destinigo.talktoastro.core.zoho_sales_iq.injectZohoSales
import tta.destinigo.talktoastro.core.zoho_sales_iq.onClickSupport
import tta.destinigo.talktoastro.feature_common.data.request.RequestCategory
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.footer.Footer
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.ConsultantListItemUI
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.SearchFieldPreview
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.ToolBar
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.TopCategoryList
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.TopLiveSessionUI
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.WelcomeSlide
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_support_agent_24

fun HomeScreenTabComponent.onCategoryClick(categoryId: RequestCategory) {
    event(HomePageEvent.OnClickCategory(categoryId))
}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun HomeScreenTab(component: HomeScreenTabComponent) {
    val isLoadingComplete by component.isLoadingComplete.collectAsState()

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    LaunchedEffect(Unit) {
        injectZohoSales()
    }

    if (isLoadingComplete) {
        Box(
            modifier = Modifier.fillMaxSize().background(background_color).graphicsLayer(scaleX = 1f, scaleY = 1f),
            contentAlignment = Alignment.Center,

        ) {
            HomePageLoadingProgressDialog(
                onDismissRequest = { /* Handle dismiss if necessary */ },
                backgroundColor = Color(0xFF2A2A2A),
                loadingColor = Color.White
            )
        }
    } else {

        Scaffold(

            containerColor = background_color,
            topBar = {
                ToolBar(
                    onClickLogin = {
                        component.event(HomePageEvent.OnClickLogin)
                    },
                    onClickProfile = { component.event(HomePageEvent.OnClickProfileIcon) },
                    onClickWallet = { component.event(HomePageEvent.OnClickWalletIcon) },
                    component = component
                )
            }, floatingActionButton = {
                if (!isJS()) {
                    FloatingActionButton(
                        onClick = { onClickSupport() },
                        containerColor = primaryDark,
                        shape = CircleShape
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.baseline_support_agent_24),
                            contentDescription = "Support",
                            tint = Color.White
                        )
                    }
                }
            }) { innerPadding ->

            LazyColumn(
                modifier = Modifier.fillMaxSize().padding(innerPadding)
            ) {
                item {
                    SearchFieldPreview(modifier = Modifier, onClick = {
                        component.event(HomePageEvent.OnSearchClick(""))
                    })
                }
                item {
                    WelcomeSlide(component, modifier = widthModifier)
                }
                item {
                    TopCategoryList(
                        onCategoryClick = component::onCategoryClick,
                        component = component,
                        modifier = widthModifier
                    )
                }
                item {

                    ConsultantListItemUI(
                        modifier = widthModifier,
                        component = component, onLoginClick = {
                            component.event(HomePageEvent.OnClickLogin)
                        }, onChatConsultation = { chatData, duration, category ->
                            component.event(
                                HomePageEvent.OnChatConsultationStart(
                                    consultantId = chatData.id!!,
                                    consultantName = chatData.full_name!!,
                                    consultantImage = chatData.profile_image!!,
                                    consultantSkills = chatData.skills!!,
                                    chatDuration = duration,
                                    category = category
                                )
                            )
                        }, onItemClick = { consultant ->
                            val category_id: Int? = consultant.id
                            if (category_id != null) {
                                component.event(
                                    HomePageEvent.OnClickTopConsultant(
                                        category_id
                                    )
                                )
                            }
                        }, onCallConsultation = { callData ->
                            component.event(
                                HomePageEvent.OnCallConsultationStart(
                                    consultantId = callData.id!!,
                                    consultantName = callData.full_name!!,
                                    consultantImage = callData.profile_image!!,
                                    consultantSkills = callData.skills!!
                                )
                            )
                        }, onRechargeRequired = { rechargeData ->
                            component.event(
                                HomePageEvent.OnRequiredRecharge(
                                    rechargeData
                                )
                            )
                        }, onVoIPClick = { duration, expertInfo ->
                            component.event(
                                HomePageEvent.OnVoIPCallConsultationStart(
                                    duration = duration, expertInfo = expertInfo
                                )
                            )
                        }, onNavigateCallWaitingPage = { CommonExpertInfo, onClose ->
                            component.event(
                                HomePageEvent.OnNavigateCallWaitingPage(
                                    onClose = onClose, expertInfo = CommonExpertInfo
                                )
                            )
                        }, onLowBalance = {
                            component.event(HomePageEvent.OnClickWalletIcon)
                        }, onSeeAllClick = { category ->
                            component::onCategoryClick.invoke(category)
                        })
                }
                item {
                    TopLiveSessionUI(
                        component = component,
                        onClick = { component.event(HomePageEvent.OnLiveSessionClick(0)) },
                        modifier = widthModifier
                    )
                }

                item { Spacer(modifier = Modifier.height(10.dp)) }

                if (isJS()) {
                    item {
                        Footer(component)
                    }
                }

            }
        }
    }
}
