package tta.destinigo.talktoastro.feature_common.persentation.more_tab.component


import com.arkivanov.decompose.ComponentContext
import co.touchlab.kermit.Logger
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob

class PrivacyPolicyComponent(
    context: ComponentContext,
    private val onPrivacyPolicyComponentEvent: (PrivacyPolicyEvent) -> Unit
) : ComponentContext by context {
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
   // private val api = HomeApiService()
    init {
        Logger.d { "PrivacyPolicy Screen Tab Component initialized" }

        lifecycle.doOnStart {
            Logger.d { "PrivacyPolicy Screen Tab Component onStart" }
        }

        lifecycle.doOnDestroy {
            Logger.d { "PrivacyPolicy Screen Tab Component onDestroy" }
        }
    }

    fun event(event: PrivacyPolicyEvent){
        onPrivacyPolicyComponentEvent.invoke(event)
    }
}