package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("data") val `data`: List<DataX>,
    @SerialName("pagination") val pagination: Pagination
)
