package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page

import tta.destinigo.talktoastro.core.data.CommonExpertInfo
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData

sealed class ExpertListPageEvent {
    data object OnErrorBottomSheetCallConsultation : ExpertListPageEvent()
    data object OnExpertDetailsPage : ExpertListPageEvent()
    data object OnBackPressed : ExpertListPageEvent()
    data class OnSubCategoryClick(val subCategoryName: String) : ExpertListPageEvent()
    data object OnSearchFieldClick : ExpertListPageEvent()
    data class onExpertCardClick(val expertId: Int) : ExpertListPageEvent()
    data object onLoginClick : ExpertListPageEvent()
    data class RequiredRecharge(val rechargeData:RechargeData?):ExpertListPageEvent()
    data class onCallClick(
        val expertId: String,
        val expertName: String,
        val expertImage: String,
        val expertSkills: String,
    ) : ExpertListPageEvent()

    data class OnChatClick(
        val expertId: String,
        val expertName: String,
        val expertImage: String,
        val expertSkills: String,
        val duration: String,
        val category:String
    ) : ExpertListPageEvent()

    data class OnVoIPCallConsultationStart(val duration: String, val commonExpertInfo: CommonExpertInfo) :
        ExpertListPageEvent()
}