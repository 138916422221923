package tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun EnrolErrorBottomSheet(){
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    Box(modifier = Modifier.fillMaxWidth().background(background_color), contentAlignment = Alignment.Center) {
        Column(
            modifier = widthModifier.fillMaxSize().background(background_color),
            horizontalAlignment = Alignment.CenterHorizontally,
//            verticalArrangement = Arrangement.Center
        ) {
            CommonTopAppBar(title = "CustomOrder Perches", onBackClick = { })
            Spacer(modifier = Modifier.height(64.dp))

            Text(
                text = "Payment could not be processed.",
                style = MaterialTheme.typography.headlineSmall,
                textAlign = TextAlign.Center
            )

            Text(
                text = "Your custom order has failed. If your payment was successful, please wait for confirmation.",
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(bottom = 32.dp)
            )
        }
    }
}