package tta.destinigo.talktoastro.feature_courses.persentation.course_page

import kotlinx.serialization.SerialName
import tta.destinigo.talktoastro.feature_courses.data.response.courses.CourseDetails
import tta.destinigo.talktoastro.feature_courses.data.response.courses.Instructors

data class CourseInfo(
    @SerialName("id") var id: Int? = null,
    @SerialName("title") var title: String? = null,
    @SerialName("description") var description: String? = null,
    @SerialName("date") var date: String? = null, // 30 December Monday
    @SerialName("duration_days") var durationDays: String? = null, //25 Days
    @SerialName("price") var price: String, //₹3,500
    @SerialName("original_price") var originalPrice: String, //₹3,500
    @SerialName("category_type") var categoryType: String? = null,
    @SerialName("slug") var slug: String? = null,
    @SerialName("rating") var rating: String? = null,
    @SerialName("reviews_count") var reviewsCount: String? = null,  //(2k+ Students Count)
    @SerialName("image_path") var imagePath: String? = null,
    @SerialName("status") var status: Int? = null,
    @SerialName("instructors") var instructors: Instructors? = null,
    @SerialName("overview_details") var overviewSection: List<CourseDetails> = arrayListOf(),
    @SerialName("curriculam_details") var curriculamSection: List<CourseDetails> = arrayListOf(),
    @SerialName("fqq_details") var faqSection: List<CourseDetails> = arrayListOf(),
    @SerialName("discount_price") var discountPrice: String? = null,
)
