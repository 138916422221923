package tta.destinigo.talktoastro.feature_chat_consultation.data.response.end_consultation

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Created by Prince on 14-06-2023.
 * @Author Suraj Sharma
 */
@Serializable
data class ChatConsultationEndResponse(
    @SerialName("code") val code: Int,
    @SerialName("message") val message: String,
    @SerialName("status") val status: Boolean?,
    @SerialName("data") val data: ChatConsultationEndData?
)
