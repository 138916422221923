package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ExpertRecommendItem(
    @SerialName("created_at") val createdAt: String, // 2024-07-03 17:23:36
//    @SerialName("custom_order") val customOrder: CustomOrder,
    @SerialName("custom_order") val customOrder: CustomOrderListData?,
    @SerialName("custom_order_id") val customOrderId: Int, // 2
    @SerialName("deleted_at") val deletedAt:String?=null, // null
    @SerialName("expert_id") val expertId: Int, // 200
    @SerialName("id") val id: Int, // 21
    @SerialName("txn_id") val txnId: Int, // 98472
    @SerialName("type") val type: String, // Chat
    @SerialName("updated_at") val updatedAt: String, // 2024-07-03 17:23:36
    @SerialName("user_id") val userId: Int // 53641
    
)
