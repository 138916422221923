package tta.destinigo.talktoastro.feature_expert_consultation.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.ExpertApi
import tta.destinigo.talktoastro.feature_expert_notify.data.request.ExpertAvailableRequest

class ExpertListRepo(val api: ExpertApi) {
//    fun markExpertFav(id: String): Flow<MarkFeavoriteState> {
//        return flow {
//            emit(MarkFeavoriteState.Loading(true))
//            try {
//                val response = api.postMarkFavorite(id)
//                if (response is Resources.Success) {
//                    emit(MarkFeavoriteState.Success(response.data!!))
//                } else if (response is Resources.Error) {
//                    emit(MarkFeavoriteState.Error(response.msg))
//                }
//            } catch (e: Exception) {
//                emit(MarkFeavoriteState.Error(e.message ?: "Unknown error"))
//            } finally {
//                emit(MarkFeavoriteState.Loading(false))
//            }
//        }
//    }

    fun notifyWhenExpertAvailable(request: ExpertAvailableRequest): Flow<Resources<Boolean>> {
        return flow {
            emit(Resources.Loading(true))
            val response = api.getNotifyWhenExpertAvailable(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}