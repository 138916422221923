package tta.destinigo.talktoastro.feature_common.di

import tta.destinigo.talktoastro.feature_common.data.remote.HomeApiService
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.HomeScreenTabComponent
import org.koin.dsl.module

val homeCommonModule = module {
//    scope<HomeScreenTabComponent> {
//        scoped{HomeApiService()}
//    }


    factory { HomeApiService() }
}