package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderLIstResponseCategoryWise(
    @SerialName("code") val code: Int, // 200
    @SerialName("data") val `data`: Data,
    @SerialName("message") val message: String, // Data fetched successfully
    @SerialName("status") val status: Boolean // true
)
