package tta.destinigo.talktoastro.feature_courses.data.response.courses

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.core.data.CommonResponse

@Serializable
data class CoursesResponse(
    @SerialName("data") val data: List<CourseData> = listOf()
) : CommonResponse()
