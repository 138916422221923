package tta.destinigo.talktoastro.feature_call_consultation.persentation.widget

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.persentation.CommonButton
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.persentation.RechargeButton
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale.CORNER_LARGE
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.core.theme.initializeCurrencyName
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallDurationData
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallRechargeNowDialog(
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        // Show AlertDialog for compact window size
        CallRechargeNowBottomSheetDialog(
            rechargeData = rechargeData,
            onRechargeSelected = onRechargeSelected,
            data = data,
            onDismissRequest = onDismissRequest
        )

    } else {
        // Show ModalBottomSheet for medium and expanded window sizes
        CallRechargeNowAlertDialog(
            rechargeData = rechargeData,
            onRechargeSelected = onRechargeSelected,
            data = data,
            onDismissRequest = onDismissRequest
        )
    }
}

val content: @Composable () -> Unit = {

}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CallRechargeNowBottomSheetDialog(
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    var selectedRecharge by remember { mutableStateOf<RechargeData?>(null) }

    ModalBottomSheet(
        containerColor = Color.White,
        onDismissRequest = { onDismissRequest() },
        shape = RoundedCornerShape(topStart = CORNER_LARGE, topEnd = CORNER_LARGE)
    ) {

//        RechargeForConsultationCall(
//            modifier = Modifier,
//            data = data,
//            expertImage = data.,
//            expertName = consultationComponent.expertName,
//            expertSkills = consultationComponent.expertSkills,
//            rechargeData = rechargeData,
//            onRechargeSelected = { rechargeData ->
//                consultationComponent.onRechargeAgain(rechargeData)
//            }
//        )
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 24.dp)
        ) {
            Spacer(modifier = Modifier.height(8.dp))

            // Warning Text
            AnimatedVisibility(visible = data != null) {
                Text(
                    text = "Minimum balance of ${data?.minimumDuration} minutes (${initializeCurrencyName()} ${data?.minimumBalance}) is required to start ${data?.type}",
                    color = MaterialTheme.colorScheme.error,
                    fontSize = 14.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier
                        .align(Alignment.CenterHorizontally)
                        .padding(vertical = 8.dp),
                    textAlign = TextAlign.Center
                )
            }

            Spacer(modifier = Modifier.height(16.dp))

            // Tip Row
            TipRow()

            Spacer(modifier = Modifier.height(24.dp))

            // Recharge options grid
            RechargeOptionsGrid(
                selectedRecharge = selectedRecharge,
                onSelect = { selectedRecharge = it },
                rechargeData = rechargeData
            )

            Spacer(modifier = Modifier.height(24.dp))

            // Recharge button
            RechargeButton(
                selectedRecharge = selectedRecharge,
                onRechargeSelected = onRechargeSelected
            )
        }
    }
}

@Composable
fun CallRechargeNowAlertDialog(
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
    data: CallDurationData?,
    onDismissRequest: () -> Unit
) {
    var selectedRecharge by remember { mutableStateOf<RechargeData?>(null) }

    AlertDialog(
        onDismissRequest = { onDismissRequest() },
        title = {
            //Text(text = "Recharge Now", fontWeight = FontWeight.Bold)
        },
        text = {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .wrapContentHeight()
            ) {
                Spacer(modifier = Modifier.height(8.dp))

                // Warning Text
                AnimatedVisibility(visible = data != null) {
                    Text(
                        text = "Minimum balance of ${data?.minimumDuration} minutes (${initializeCurrencyName()} ${data?.minimumBalance}) is required to start ${data?.type}",
                        color = MaterialTheme.colorScheme.error,
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier
                            .align(Alignment.CenterHorizontally)
                            .padding(vertical = 8.dp),
                        textAlign = TextAlign.Center
                    )
                }

                Spacer(modifier = Modifier.height(16.dp))

                // Tip Row
                TipRow()

                Spacer(modifier = Modifier.height(24.dp))

                // Recharge options grid
                RechargeOptionsGrid(
                    selectedRecharge = selectedRecharge,
                    onSelect = { selectedRecharge = it },
                    rechargeData = rechargeData
                )

                Spacer(modifier = Modifier.height(24.dp))
            }
        },
        confirmButton = {
            RechargeButton(
                selectedRecharge = selectedRecharge,
                onRechargeSelected = onRechargeSelected
            )
        },
        dismissButton = {
//            TextButton(onClick = onDismissRequest) {
//                Text(text = "Cancel")
//            }
        }
    )
}

@Composable
fun TipRow() {
    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            Icons.Default.Info,
            contentDescription = "Information Icon",
            tint = MaterialTheme.colorScheme.primary
        )
        Spacer(modifier = Modifier.width(8.dp))
        Text(
            text = "Tip: 90% users recharge more than 8 minutes.",
            color = MaterialTheme.colorScheme.onSurfaceVariant,
            fontSize = 12.sp
        )
    }
}

@Composable
fun RechargeOptionsGrid(
    selectedRecharge: RechargeData?,
    onSelect: (RechargeData) -> Unit,
    rechargeData: List<RechargeData>
) {
    if (rechargeData.isEmpty()) {
        Text(
            text = "No recharge options available.",
            color = MaterialTheme.colorScheme.onSurface
        )
        return
    }

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        rechargeData.chunked(3).forEach { rowItems ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                rowItems.forEach { rechargeOption ->
                    RechargeOption(
                        rechargeOption = rechargeOption,
                        isSelected = rechargeOption == selectedRecharge,
                        onSelect = onSelect
                    )
                }
            }
        }
    }
}

@Composable
fun RechargeOption(
    rechargeOption: RechargeData,
    isSelected: Boolean,
    onSelect: (RechargeData) -> Unit
) {
    val backgroundColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceVariant
    )
    val textColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onSurface
    )
    val scale by animateFloatAsState(
        targetValue = if (isSelected) 1.1f else 1f
    )

    Column(
        modifier = Modifier
            .width(100.dp)
            .height(80.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(backgroundColor)
            .border(2.dp, if (isSelected) MaterialTheme.colorScheme.primary else Color.Transparent, RoundedCornerShape(8.dp))
            .clickable { onSelect(rechargeOption) }
            .scale(scale)
            .padding(8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(
            text = "${initializeCurrency()}${rechargeOption.amount}",
            fontWeight = FontWeight.Bold,
            color = textColor
        )
        rechargeOption.cupon?.cuponText?.let {
            Spacer(modifier = Modifier.height(4.dp))
            Text(text = it, fontSize = 10.sp, color = MaterialTheme.colorScheme.secondary)
        }
    }
}




@Composable
fun RechargeForConsultationCall(
    modifier: Modifier,
    expertImage: String,
    expertName: String,
    expertSkills: String,
    data: CallDurationData?,
    rechargeData: List<RechargeData>,
    onRechargeSelected: (RechargeData) -> Unit,
) {

    var selectedRecharge by remember { mutableStateOf<RechargeData?>(null) }

    Column(
        modifier = modifier
            .wrapContentWidth()
            .wrapContentHeight()
    ) {

        // Show warning if `data` is provided
        AnimatedVisibility(visible = data != null) {
            Text(
                text = "Minimum balance of ${data?.minimumDuration} minutes (${initializeCurrencyName()} ${data?.minimumBalance}) is required to start ${data?.type}",
                color = MaterialTheme.colorScheme.error,
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier
                    .align(Alignment.CenterHorizontally)
                    .padding(vertical = 8.dp),
                textAlign = TextAlign.Center
            )
        }

        Spacer(modifier = Modifier.height(8.dp))

        // Tip Row
        ExpertCard(
            modifier = Modifier,
            expertName = expertName,
            expertImage = expertImage,
            expertSkills = expertSkills
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Recharge options grid
        RechargeOptionsGridCall(
            selectedRecharge = selectedRecharge,
            onSelect = { selectedRecharge = it },
            rechargeData = rechargeData
        )

        Spacer(modifier = Modifier.height(8.dp))

        // Recharge button
        CommonButton(
            buttonText = "Recharge Now",
            modifier = Modifier.fillMaxWidth(),
            onClick = {
                selectedRecharge?.let {
                    onRechargeSelected(it)
                } ?: run {

                    showToast("Please Select Recharge Value")
                    // Handle case where no amount is selected
                }
            }
        )
    }
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun RechargeOptionsGridCall(
    selectedRecharge: RechargeData?,
    onSelect: (RechargeData) -> Unit,
    rechargeData: List<RechargeData>
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    // Check if the list is empty or null
    if (rechargeData.isEmpty()) {
        Text(
            text = "No recharge options available.",
            color = MaterialTheme.colorScheme.onSurface,
            //modifier = Modifier.align(Alignment.CenterHorizontally)
        )
        return
    }

    val itemInRow = if (windowSizeClass == WindowWidthSizeClass.Compact) 3
    else 4

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        rechargeData.chunked(itemInRow).forEach { rowItems ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                rowItems.forEach { rechargeOption ->
                    RechargeOptionCall(
                        rechargeOption = rechargeOption,
                        isSelected = rechargeOption == selectedRecharge,
                        onSelect = onSelect
                    )
                }
            }
        }
    }
}

@Composable
fun RechargeOptionCall(
    rechargeOption: RechargeData,
    isSelected: Boolean,
    onSelect: (RechargeData) -> Unit
) {
    val backgroundColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceVariant
    )
    val textColor by animateColorAsState(
        targetValue = if (isSelected) MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onSurface
    )
    val scale by animateFloatAsState(
        targetValue = if (isSelected) 1.1f else 1f
    )

    Column(
        modifier = Modifier
            .width(100.dp)
            .height(80.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(backgroundColor)
            .border(
                2.dp,
                if (isSelected) MaterialTheme.colorScheme.primary else Color.Transparent,
                RoundedCornerShape(8.dp)
            )
            .clickable { onSelect(rechargeOption) }
            .scale(scale)
            .padding(8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Text(text = "${initializeCurrency()}${rechargeOption.amount}", fontWeight = FontWeight.Bold, color = textColor)
        rechargeOption.cupon?.cuponText?.let {
            Spacer(modifier = Modifier.height(4.dp))
            Text(text = it, fontSize = 10.sp, color = MaterialTheme.colorScheme.secondary)
        }
    }
}

@Composable
fun ExpertCard(
    modifier: Modifier,
    expertName: String,
    expertImage: String,
    expertSkills: String,
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .border(
                width = 2.dp, // Thickness of the border
                color = primaryColor.copy(alpha = 0.15f), // Color of the stroke
                shape = RoundedCornerShape(8.dp) // Ensuring the border has rounded corners
            )
            .clip(RoundedCornerShape(8.dp))
            .padding(12.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        ExpertProfileIcon(
            profileImageUrl = expertImage, modifier = Modifier.size(50.dp)
        )
        Spacer(modifier = Modifier.width(16.dp))
        Column {
            Text(
                text = expertName,
                maxLines = 1,
                style = MaterialTheme.typography.titleMedium,
                modifier = Modifier.fillMaxWidth()
            )

            Text(
                text = expertSkills,
                maxLines = 2,
                style = MaterialTheme.typography.titleSmall,
                modifier = Modifier.fillMaxWidth()
            )
        }
    }
}

