package tta.destinigo.talktoastro.feature_custom_order.data.response

import androidx.compose.ui.graphics.drawscope.Stroke
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.Expert
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.Lession
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.Section

@Serializable
data class CustomOrderDetailData(
    @SerialName("category") val category: String?, // Scientific Astrology
    @SerialName("content_type") val contentType: String?, // Video
    @SerialName("created_at") val createdAt: String?, // 2024-04-29 17:21:20
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("description") val description: String?, // loreum ipsum dummy text
    @SerialName("display_price") val displayPrice: Int?, // 0
    @SerialName("duration") val duration: Int?, // 30
    @SerialName("expert") val expert: Expert?,
    @SerialName("expert_id") val expertId: Int?, // 52631
    @SerialName("heading") val heading: String?, // test custom order
    @SerialName("id") val id: Int?, // 6
    @SerialName("image") val image: String?, // 1714391480.jpg
    @SerialName("image_url") val imageUrl: String?, // https://prod.winyway.com/customorder/1714391480.jpg
    @SerialName("language") val language: String?, // English
    @SerialName("live_session") val liveSession: List<Lession>?,
    @SerialName("sections") val sections: List<Section>?,
    @SerialName("service") val service: String?, // Free
    @SerialName("status") val status: String?, // 1
    @SerialName("updated_at") val updatedAt: String?,// 2024-05-08 16:28:48
    @SerialName("is_free") val isFree: Int?, // 2024-04-15 17:21:24
    @SerialName("enrollment_status") val enrollmentStatus: Int?, // 2024-04-15 17:21:24
) {
    fun isFreeApplicable(): Boolean {
        return service == "Free"
    }

    fun isEnrolledByUser(): Boolean {
        return enrollmentStatus == 1
    }
}
