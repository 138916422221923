package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_concern.widget

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.relocation.BringIntoViewRequester
import androidx.compose.foundation.relocation.bringIntoViewRequester
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.ExposedDropdownMenuBox
import androidx.compose.material3.ExposedDropdownMenuDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldColors
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.date_time.CommonDatePickerDialog
import tta.destinigo.talktoastro.core.date_time.CommonTimePickerDialog
import tta.destinigo.talktoastro.core.date_time.convertMillisToDate
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.geo_details.GeoName
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_concern.ChatConcernComponent

@Composable
fun OutlinedInputField(
    modifier: Modifier,
    label: String,
    inputType: KeyboardType,
    maxLength: Int,
    isRequired: Boolean,
    isMultiLine: Boolean = false,
    onValueChange: (String) -> Unit
) {
    var text by remember { mutableStateOf("") }

    OutlinedTextField(
        value = text,
        onValueChange = {
            if (it.length <= maxLength) { // Limit the length of input text
                text = it
                onValueChange(it) // Call the callback even if the text is empty
            }
        },
        label = {
            val finalText = if (isRequired) "$label (Required)" else label
            Text(finalText)
        },
        keyboardOptions = KeyboardOptions.Default.copy(
            keyboardType = inputType,
            imeAction = if (isMultiLine) ImeAction.Default else ImeAction.Done
        ),
        maxLines = if (isMultiLine) 5 else 1,
        modifier = modifier,
        singleLine = !isMultiLine,
    )
}


@Composable
fun DocumentPickerField(
    modifier: Modifier,
    label: String,
    isRequired: Boolean,
    onValueChange: (String) -> Unit
) {
    val selectedDocument by remember { mutableStateOf("") }

    Column(modifier = modifier.padding(8.dp)) {
        Text(text = label, style = MaterialTheme.typography.bodySmall)
        Button(onClick = { /* Implement logic to open document picker */ }) {
            Text(text = selectedDocument.ifEmpty { "Select Document" })
        }
        if (isRequired && selectedDocument.isEmpty()) {
            Text(text = "This field is required", color = MaterialTheme.colorScheme.error)
        }
    }
}


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DatePickerField(
    modifier: Modifier,
    label: String,
    isRequired: Boolean,
    onValueChange: (String) -> Unit
) {
    val selectedDate = remember { mutableStateOf("") }
//    val datePickerState = rememberDatePickerState(initialDisplayMode = DisplayMode.Picker)
    val showDatePicker = rememberSaveable { mutableStateOf(false) }

    // Conditional display of the DatePickerDialog based on the openDialog state
    if (showDatePicker.value) {

        CommonDatePickerDialog(
            onDialogDismiss = {
                showDatePicker.value = false
            },
            selectedDate = { timeInMillies ->
                val date = timeInMillies?.convertMillisToDate()
                if (date.isNullOrEmpty().not()) {
                    onValueChange.invoke(date!!)
                    selectedDate.value = timeInMillies.convertMillisToDate() ?: ""
                }
            }
        )
    }

    OutlinedTextField(
        value = selectedDate.value,
        onValueChange = {},
        label = { Text(label) },
        modifier = modifier.clickable {
            showDatePicker.value = true
        },
        readOnly = true,
        enabled = false,
        singleLine = true,
    )
}


@Composable
fun TimePickerField(
    modifier: Modifier,
    label: String,
    isRequired: Boolean,
    onValueChange: (String) -> Unit
) {
    val selectedTime = remember { mutableStateOf("") }
    var showDatePicker by rememberSaveable { mutableStateOf(false) }

    if (showDatePicker) {
        CommonTimePickerDialog(
            is24Hour = true,
            selectedTime = { hour, minute ->
//                val time = setAmPmByTime(hour, minute)
                val time = "$hour:$minute"
                onValueChange.invoke(time)
                selectedTime.value = time
            },
            onDialogDismiss = {
                showDatePicker = false
            }
        )
    }

    OutlinedTextField(
        value = selectedTime.value,
        onValueChange = {},
        label = { Text(label) },
        modifier = modifier
            .clickable {
                showDatePicker = true
            },
        readOnly = true,
        isError = isRequired && selectedTime.value.isEmpty(),
        enabled = false,
        singleLine = true
    )
}

/** A text field that allows the user to type in to filter down options. */
@OptIn(ExperimentalMaterial3Api::class, ExperimentalFoundationApi::class)
@Composable
fun <T> TextFieldMenu(
    modifier: Modifier = Modifier,
    /** The label for the text field */
    label: String,
    /** All the available options. */
    options: List<T>,
    /** The selected option. */
    selectedOption: T?,
    /** When the option is selected via tapping on the dropdown option or typing in the option. */
    onOptionSelected: (T?) -> Unit,
    /** Converts [T] to a string for populating the initial text field value. */
    optionToString: (T) -> String,
    /** Returns the filtered options based on the input. This where you need to implement your search. */
    filteredOptions: (searchInput: String) -> List<T>,
    /** Creates the row for the filtered down option in the menu. */
    optionToDropdownRow: @Composable (T) -> Unit = { option ->
        Text(optionToString(option))
    },
    /** Creates the view when [filteredOptions] returns a empty list. */
    noResultsRow: @Composable () -> Unit = {
        // By default, wrap in a menu item to get the same style
        DropdownMenuItem(
            onClick = {},
            text = {
                Text(
                    "No Matches Found",
                    style = MaterialTheme.typography.bodySmall,
                    color = MaterialTheme.colorScheme.secondary,
                    fontStyle = FontStyle.Italic,
                )
            },
        )
    },
    focusRequester: FocusRequester = remember { FocusRequester() },
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default,
    trailingIcon: @Composable (expanded: Boolean) -> Unit = { expanded ->
        ExposedDropdownMenuDefaults.TrailingIcon(expanded = expanded)
    },
    textFieldColors: TextFieldColors = ExposedDropdownMenuDefaults.textFieldColors(),
    bringIntoViewRequester: BringIntoViewRequester = remember { BringIntoViewRequester() },
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
) {
    // Get our text for the selected option
    val selectedOptionText = remember(selectedOption) {
        selectedOption?.let { optionToString(it) }.orEmpty()
    }

    // Default our text input to the selected option
    var textInput by remember(selectedOptionText) {
        mutableStateOf(selectedOptionText)
    }

    var dropDownExpanded by remember { mutableStateOf(false) }

    // Update our filtered options everytime our text input changes
    val filteredOptions = remember(textInput, dropDownExpanded) {
        when (dropDownExpanded) {
            true -> filteredOptions(textInput)
            // Skip filtering when we don't need to
            false -> emptyList()
        }
    }

    val keyboardController = LocalSoftwareKeyboardController.current
    val focusManager = LocalFocusManager.current

    ExposedDropdownMenuBox(
        expanded = dropDownExpanded,
        onExpandedChange = { dropDownExpanded = !dropDownExpanded },
        modifier = modifier,
    ) {
        // Text Input
        OutlinedTextField(
            value = textInput,
            onValueChange = {
                // Dropdown may auto hide for scrolling but it's important it always shows when a user
                // does a search
                dropDownExpanded = true
                textInput = it
            },
            modifier = Modifier
                // Match the parent width
                .fillMaxWidth()
                .bringIntoViewRequester(bringIntoViewRequester)
                .menuAnchor()
                .focusRequester(focusRequester)
                .onFocusChanged { focusState ->
                    // When only 1 option left when we lose focus, selected it.
                    if (!focusState.isFocused) {
                        // Whenever we lose focus, always hide the dropdown
                        dropDownExpanded = false

                        when (filteredOptions.size) {
                            // Auto select the single option
                            1 -> if (filteredOptions.first() != selectedOption) {
                                onOptionSelected(filteredOptions.first())
                            }
                            // Nothing to we can auto select - reset our text input to the selected value
                            else -> textInput = selectedOptionText
                        }
                    } else {
                        // When focused:
                        // Ensure field is visible by scrolling to it
                        coroutineScope.launch {
                            bringIntoViewRequester.bringIntoView()
                        }
                        // Show the dropdown right away
                        dropDownExpanded = true
                    }
                },
            label = { Text(label) },
            trailingIcon = { trailingIcon(dropDownExpanded) },
            colors = textFieldColors,
            keyboardOptions = keyboardOptions.copy(
                imeAction = when (filteredOptions.size) {
                    // We will either reset input or auto select the single option
                    0, 1 -> ImeAction.Done
                    // Keyboard will hide to make room for search results
                    else -> ImeAction.Search
                }
            ),
            keyboardActions = KeyboardActions(
                onAny = {
                    when (filteredOptions.size) {
                        // Remove focus to execute our onFocusChanged effect
                        0, 1 -> focusManager.clearFocus(force = true)
                        // Can't auto select option since we have a list, so hide keyboard to give more room for dropdown
                        else -> keyboardController?.hide()
                    }
                }
            )
        )

        // Dropdown
        if (dropDownExpanded) {
            val dropdownOptions = remember(textInput) {
                if (textInput.isEmpty()) {
                    // Show all options if nothing to filter yet
                    options
                } else {
                    filteredOptions(textInput)
                }
            }

            ExposedDropdownMenu(
                expanded = dropDownExpanded,
                onDismissRequest = { dropDownExpanded = false },
            ) {
                if (dropdownOptions.isEmpty()) {
                    noResultsRow()
                } else {
                    dropdownOptions.forEach { option ->
                        DropdownMenuItem(
                            onClick = {
                                dropDownExpanded = false
                                onOptionSelected(option)
                                focusManager.clearFocus(force = true)
                            },
                            text = {
                                optionToDropdownRow(option)
                            }
                        )
                    }
                }
            }
        }
    }
}

@OptIn(
    ExperimentalFoundationApi::class,
    ExperimentalMaterial3Api::class
)
@Composable
fun DropdownField(
    component: ChatConcernComponent,
    modifier: Modifier = Modifier,
    label: String,
    isRequired: Boolean,
    onSelectedItem: (GeoName) -> Unit
) {

    val townState by component.stateTown.collectAsState()
    var textInput by remember { mutableStateOf("") }
    var dropDownExpanded by remember { mutableStateOf(false) }
    val focusRequester = remember { FocusRequester() }
    val bringIntoViewRequester = remember { BringIntoViewRequester() }
    val coroutineScope = rememberCoroutineScope()
    val focusManager = LocalFocusManager.current
    val keyboardController = LocalSoftwareKeyboardController.current

    if (textInput.isNotEmpty() && textInput.length >= 3) {
        component.onChangeTownInput(textInput)
    }
    // Filter options based on text input
    val filteredOptions = remember(textInput, townState) {
        townState?.filter { it.placeName.contains(textInput, ignoreCase = true) } ?: emptyList()
    }

    // Compose the label text
    val finalLabel = if (isRequired) "$label (Required)" else label

    ExposedDropdownMenuBox(
        expanded = dropDownExpanded,
        onExpandedChange = { dropDownExpanded = !dropDownExpanded },
        modifier = modifier
    ) {
        OutlinedTextField(
            value = textInput,
            onValueChange = {
                textInput = it
                onSelectedItem(
                    GeoName(
                        countryCode = "",
                        latitude = 0.0,
                        longitude = 0.0,
                        placeName = it,
                        timezoneId = ""
                    )
                )
                dropDownExpanded =
                    filteredOptions.isNotEmpty() // Only expand dropdown if there are matching options
            },
            label = { Text(finalLabel) },
            trailingIcon = {
                ExposedDropdownMenuDefaults.TrailingIcon(expanded = dropDownExpanded)
            },
            modifier = Modifier
                .fillMaxWidth()
                .bringIntoViewRequester(bringIntoViewRequester)
                .focusRequester(focusRequester)
                .menuAnchor()
                .onFocusChanged { focusState ->
                    dropDownExpanded = focusState.isFocused && filteredOptions.isNotEmpty()
                    if (focusState.isFocused) {
                        coroutineScope.launch {
                            bringIntoViewRequester.bringIntoView()
                        }
                    }
                },
            singleLine = true,
            keyboardOptions = KeyboardOptions.Default.copy(
                imeAction = ImeAction.Done
            ),
            keyboardActions = KeyboardActions(
                onDone = {
                    focusManager.clearFocus()
                    dropDownExpanded = false
                    keyboardController?.hide()
                }
            ),
            colors = TextFieldDefaults.outlinedTextFieldColors()
        )

        // Dropdown menu for suggestions
        ExposedDropdownMenu(
            expanded = dropDownExpanded,
            onDismissRequest = { dropDownExpanded = false }
        ) {
            if (filteredOptions.isEmpty()) {
                DropdownMenuItem(
                    text = {
                        Text(
                            "No Matches Found",
                            style = MaterialTheme.typography.bodySmall,
                            color = MaterialTheme.colorScheme.secondary
                        )
                    },
                    onClick = {}
                )
            } else {
                filteredOptions.forEach { suggestion ->
                    DropdownMenuItem(
                        text = { Text(suggestion.placeName) },
                        onClick = {
                            textInput = suggestion.placeName
                            onSelectedItem(suggestion)
                            dropDownExpanded = false
                            focusManager.clearFocus()
                        }
                    )
                }
            }
        }
    }
}



