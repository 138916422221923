package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomOrderTxnDetails(
    @SerialName("created_at") val createdAt: String?, // 2024-05-09 18:38:54
    @SerialName("custom_order_id") val customOrderId: String?, // 10
    @SerialName("expert_id") val expertId: Int?, // 49721
    @SerialName("id") val id: Int?, // 98305
    @SerialName("total_amount") val totalAmount: Int?, // 1299
    @SerialName("type") val type: String?, // Paid Custom Order
    @SerialName("updated_at") val updatedAt: String?, // 2024-05-09 18:38:54
    @SerialName("user_id") val userId: Int? // 84
)
