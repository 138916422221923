package tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.api

import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.response.PurchaseHistoryData
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.response.PurchaseHistoryPagingData
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.request.PurchaseHistoryRequest
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.data.response.PurchaseHistoryListResponse

class PurchaseHstoryApi : KtorApi() {
    companion object{
        private const val CUSTOM_ORDER_PREFIX = "custom-order/"
        const val GET_PURCHASE_HISTORY_LIST = CUSTOM_ORDER_PREFIX + "purchase-history"
    }

    private suspend fun getPurchaseHistoryList(request: PurchaseHistoryRequest) = client.post {
        url(GET_PURCHASE_HISTORY_LIST)
        setBody(request)
    }.body<PurchaseHistoryListResponse>()


    suspend fun getPurchaseHistoryListData(request: PurchaseHistoryRequest): Resources<List<PurchaseHistoryData>> {
        return withContext(Dispatchers.Default) {
            try {
                val response = getPurchaseHistoryList(request)

                if (response.status.not()) {
                    return@withContext Resources.Error(
                        response.message
                    )
                }

                return@withContext Resources.Success(response.data.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}