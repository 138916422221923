package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EmergencyCallDetails(
    @SerialName("amount") val amount: String, // 45
    @SerialName("booking_date_time") val bookingDateTime: String, // 2024-05-29 15:09:00
    @SerialName("call_booking_date") val callBookingDate: String, // 29-05-2024
    @SerialName("call_booking_time") val callBookingTime: String, // 15:09:01
    @SerialName("call_duration") val callDuration: Int?, // 139
    @SerialName("call_minutes") val callMinutes: Int, // 2
    @SerialName("call_place_again") val callPlaceAgain: Int, // 0
    @SerialName("created_at") val createdAt: String, // 2024-05-29 15:09:01
    @SerialName("currency") val currency: String, // INR
    @SerialName("exotel_call_duration") val exotelCallDuration: String?, // 139
    @SerialName("exotel_call_status") val exotelCallStatus: String, // completed
    @SerialName("expert_id") val expertId: Int, // 200
    @SerialName("expert_payment") val expertPayment: Int, // 42
    @SerialName("expert_price") val expertPrice: String, // 14
    @SerialName("flash_off") val flashOff: String?, // null
    @SerialName("id") val id: Int, // 15679
    @SerialName("international_rate") val internationalRate: String?, // null
    @SerialName("parent_id") val parentId: Int, // 0
    @SerialName("ramaining_balance") val ramainingBalance: String, // 109.5
    @SerialName("sid") val sid: String, // 073557737fde56be8bcae13205c9185t
    @SerialName("slot_for") val slotFor: String, // Emergency Call
    @SerialName("updated_at") val updatedAt: String, // 2024-05-29 15:11:42
    @SerialName("user_id") val userId: Int, // 53619
    @SerialName("user_rating") val userRating: String?, // null
    @SerialName("user_review") val userReview: String?, // null
    @SerialName("voip_end_by") val voipEndBy: String?, // null
    @SerialName("voip_end_datetime") val voipEndDatetime: String?, // null
    @SerialName("voip_start_datetime") val voipStartDatetime: String?, // null
    @SerialName("winyway_payment") val winywayPayment: Int, // 2
    @SerialName("winyway_price") val winywayPrice: String, // 1,
    @SerialName("expert_info") val expertInfo: ExpertInfo?,
)
