package tta.destinigo.talktoastro.feature_chat_consultation.data.response.end_consultation

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Created by Prince on 14-06-2023.
 * @Author Suraj Sharma
 */

@Serializable
data class ChatConsultationEndData(
    @SerialName("chat_id")
    val chatId: Int,
    @SerialName("created_at")
    val createdAt: String,
    @SerialName("duration")
    val duration: String,
    @SerialName("expert_end_chat_date_time")
    val expertEndChatDateTime: String?,
    @SerialName("expert_id")
    val expertId: Int,
    @SerialName("expert_price")
    val expertPrice: String,
    @SerialName("expert_start_chat_date_time")
    val expertStartChatDateTime: String,
    @SerialName("id")
    val id: Int,
    @SerialName("paid_amount")
    val paidAmount: String,
    @SerialName("remaining_wallet_balance")
    val remainingWalletBalance: String,
    @SerialName("status")
    val status: String,
    @SerialName("updated_at")
    val updatedAt: String,
    @SerialName("user_end_chat_date_time")
    val userEndChatDateTime: String,
    @SerialName("user_id")
    val userId: Int,
    @SerialName("user_start_chat_date_time")
    val userStartChatDateTime: String,
    @SerialName("winyway_price")
    val winywayPrice: String
)
