package tta.destinigo.talktoastro.feature_common.persentation.favourite_tab

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_language_24
import tta.destinigo.talktoastro.resources.baseline_notifications_24
import tta.destinigo.talktoastro.resources.baseline_person_4_24
import tta.destinigo.talktoastro.resources.call_icon_small
import tta.destinigo.talktoastro.resources.chat_icon_small
import tta.destinigo.talktoastro.resources.coin_balance
import tta.destinigo.talktoastro.resources.ic_filled_star
import tta.destinigo.talktoastro.resources.ic_heart_primary_color
import tta.destinigo.talktoastro.resources.ic_heart_primary_color_fill
import tta.destinigo.talktoastro.resources.ic_wallet
import tta.destinigo.talktoastro.resources.person_small_icon
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource

//
//expert: Consultant,
//position: Int,
//listener: CategoryConsultListRowClickListener,
//isFreeCallApplicable: Boolean
@Composable
fun FavouriteScreenTab(){
    Column {
        ToolBar(true)
//        ConsultantItem(
//            expert = ,
//            position = 0,
//            listener = object : CategoryConsultListRowClickListener {
////                override fun onItemClicks(position: Int, expert: Consultant) {
////                    // Handle item clicks here
////                }
////
////                override fun onChatClick(position: Int, expert: Consultant) {
////                    // Handle chat click here
////                }
////
////                override fun onCallClick(position: Int, expert: Consultant) {
////
////                }
////
////                override fun onConsultNowClick(position: Int, expert: Consultant) {
////                    // Handle consult now click here
////                }
//
//                override fun onItemClicks(position: Int, expert: RequestConsultant) {
//                    //TODO("Not yet implemented")
//                }
//
//                override fun onChatClick(position: Int, expert: RequestConsultant) {
//                    //TODO("Not yet implemented")
//                }
//
//                override fun onCallClick(position: Int, expert: RequestConsultant) {
//                    //TODO("Not yet implemented")
//                }
//
//                override fun onConsultNowClick(position: Int, expert: RequestConsultant) {
//                    //TODO("Not yet implemented")
//                }
//            },
//            isFreeCallApplicable = true
//        )
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ToolBar(isLoggedIn: Boolean) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 10.dp)
            .padding(horizontal = 16.dp), // Adjust padding as needed
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
//        Box(
//            modifier = Modifier
//                .size(45.dp)
//                .clip(RoundedCornerShape(4.dp))
//        ) {
//            // Uncomment the Image composable below and replace the resource ID
//            /*Image(
//                painter = painterResource("drawable/icon_logo.png"),
//                contentDescription = "Logo",
//                contentScale = ContentScale.Crop,
//                modifier = Modifier.fillMaxSize()
//            )*/
//        }

        Text(
            text = "My Favourites",
            modifier = Modifier
                .weight(1f)
                .padding(start = 16.dp),
            color = Color.Black,
            fontSize = 18.sp,
            maxLines = 1,
            overflow = TextOverflow.Ellipsis,
            fontWeight = FontWeight.Bold
        )

        Image(
            painter = painterResource(Res.drawable.baseline_notifications_24),
            contentDescription = null,
            modifier = Modifier
                .size(24.dp)
                .align(Alignment.CenterVertically)
                .background(Color(0xFFEEEEEE), CircleShape)
                .clickable {  },
            contentScale = ContentScale.Crop,
            colorFilter = ColorFilter.tint(Color(0xFF1B98F0))
        )

        if (isLoggedIn) {
            Spacer(modifier = Modifier.width(15.dp))
            Box(
                modifier = Modifier
                    .size(36.dp)
                    .clip(RoundedCornerShape(4.dp))
                    .background(Color(0xFFEEF6FC)).clickable {  }
            ) {
                Row(modifier = Modifier.align(Alignment.Center)) {
                    Image(
                        painter = painterResource(Res.drawable.ic_wallet),
                        contentDescription = null,
                        colorFilter = ColorFilter.tint(Color(0xFF1B98F0)),
                        modifier = Modifier.size(24.dp)
                    )
                    Spacer(modifier = Modifier.width(3.dp))
                    Text(text = "0")
                }
            }
            Spacer(modifier = Modifier.width(18.dp))

            Box(
                modifier = Modifier
                    .size(36.dp)
                    .clip(RoundedCornerShape(4.dp))
                    .background(Color(0xFFEEF6FC)).clickable {  }
            ) {
                Row(modifier = Modifier.align(Alignment.Center)) {
                    Image(
                        painter = painterResource(Res.drawable.coin_balance),
                        contentDescription = null,
                        colorFilter = ColorFilter.tint(Color(0xFFF2BC36)),
                        modifier = Modifier.size(24.dp)
                    )
                    Spacer(modifier = Modifier.width(3.dp))
                    Text(text = "0")
                }

            }
        } else {
            TextWithImageAsButton(
                onClick = {
                    // Action on button click
                },
                text = "Login/Register",
                imageVector = painterResource(Res.drawable.person_small_icon),
                textColor = Color.Blue
            )
        }
    }
}




@Composable
fun TextWithImageAsButton(
    onClick: () -> Unit,
    text: String,
    imageVector: Painter,
    textColor: Color,
    contentPadding: PaddingValues = PaddingValues(8.dp)
) {
    Button(
        onClick = onClick,
        colors = ButtonDefaults.buttonColors(Color.LightGray),
        contentPadding = contentPadding,
        elevation = ButtonDefaults.elevatedButtonElevation(
            defaultElevation = 2.dp,
            pressedElevation = 3.dp
        ),
        shape = RoundedCornerShape(4.dp)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                painter = imageVector,
                contentDescription = null,
                modifier = Modifier.size(24.dp),
                alignment = Alignment.Center,

                )
            Spacer(modifier = Modifier.width(4.dp))
            Text(
                text = text,
                color = Color(0xFF1B98F0), // Set text color to #1B98F0
                fontSize = 14.sp
            )
        }
    }
}


@OptIn(ExperimentalResourceApi::class)
@Composable
fun ConsultantItem(
    expert: RequestConsultant,
    position: Int,
    listener: CategoryConsultListRowClickListener,
    isFreeCallApplicable: Boolean
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 8.dp, vertical = 8.dp)
            .clickable { listener.onItemClicks(position, expert) },
        shape = RoundedCornerShape(8.dp),
        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_SMALL)
    ) {
        Row(
            modifier = Modifier.padding(8.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            // User Image Column
            Column(
                modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                // User Image
                Image(
                    painter = painterResource(Res.drawable.baseline_person_4_24),
                    contentDescription = null,
                    modifier = Modifier
                        .size(74.dp)
                        .border(2.dp, Color(0xFFDBA514), shape = CircleShape).background(Color(0xFFEEEEEE), CircleShape),
                    contentScale = ContentScale.Crop
                )

                Spacer(modifier = Modifier.height(8.dp))

                // Experience Text
                Text(
                    text = "${expert.year_of_experience} years",
                )

                Spacer(modifier = Modifier.height(4.dp))

                // Rating Text
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_filled_star),
                        contentDescription = null,
                        tint = Color(0xFFFF9C27),
                        modifier = Modifier.size(18.dp)
                    )
                    Text(
                        text = expert.consultant_rating.toString(),
                        modifier = Modifier.padding(start = 4.dp)
                    )
                }

                Spacer(modifier = Modifier.height(4.dp))
            }

            Spacer(modifier = Modifier.width(16.dp))

            // Details Column
            Column(
                modifier = Modifier.weight(1f)
            ) {
                // User Name Text
                Text(
                    text = expert.full_name.toString(),
                    maxLines = 1
                )

                Spacer(modifier = Modifier.height(4.dp))

                // Expertise Text
                Text(
                    text = expert.skills.toString(),
                    maxLines = 2
                )

                Spacer(modifier = Modifier.height(4.dp))

                // Language Text
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_language_24),
                        contentDescription = null,
                        modifier = Modifier.size(14.dp),
                        tint = Color.Black
                    )
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(
                        text = expert.language.toString(),
                        maxLines = 2
                    )
                }

                Spacer(modifier = Modifier.height(4.dp))

                // Pricing Text
                Text(
                    text =  "₹85/Min",
                )

                Spacer(modifier = Modifier.height(16.dp))

//                // Action Buttons
//                Row(modifier = Modifier.fillMaxWidth()) {
//                    ActionButton(
//                        text = "CHAT",
//                        icon = painterResource(Res.drawable.chat_icon_small),
//                        onClick = { listener.onChatClick(position, expert) },
//                        buttonColor = Color(0xFF1B98F0)
//                    )
//
//                    Spacer(modifier = Modifier.width(3.dp))
////
////                    ActionButton(
////                        text = "CALL",
////                        icon = painterResource(Res.drawable.call_icon_small),
////                        onClick = { listener.onChatClick(position, expert) },
////                        buttonColor = Color(0xFF28a745)
////                    )
//                }
            }

            // Other Actions Column

            Box(modifier = Modifier.align(Alignment.Top)) {
                ActionIconButton(
                    icon = painterResource(Res.drawable.ic_heart_primary_color),
                    onClick = { listener.onConsultNowClick(position, expert) },
                    onClickSuccess = false
                )

                Spacer(modifier = Modifier.height(5.dp))

//                ActionIconButton(
//                    icon = painterResource("baseline_notifications_24.xml"),
//                    onClick = { listener.onConsultNowClick(position, expert) }
//                )
            }
        }
    }
}

@Composable
fun ActionButton(
    text: String,
    icon: Painter,
    onClick: () -> Unit,
    buttonColor: Color
) {
    Button(
        onClick = onClick,
        modifier = Modifier
            .height(40.dp)
            .fillMaxWidth(),
        colors = ButtonDefaults.buttonColors(buttonColor),
        shape = RoundedCornerShape(4.dp),
        elevation = ButtonDefaults.elevatedButtonElevation(3.dp),
        contentPadding = PaddingValues(horizontal = 8.dp, vertical = 12.dp),
        content = {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Icon(
                    painter = icon,
                    contentDescription = null,
                    modifier = Modifier.size(24.dp),
                    tint = Color.White
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(
                    text = text,
                    color = Color.White,
                    maxLines = 1,
                    textAlign = TextAlign.Center,
                    style = MaterialTheme.typography.displayMedium
                )
            }
        }
    )
}




@OptIn(ExperimentalResourceApi::class)
@Composable
fun ActionIconButton(
    icon: Painter,
    onClick:  () -> Unit,
    onClickSuccess: Boolean
) {
    var clicked by remember { mutableStateOf(false) }

    val iconToShow = if (onClickSuccess) {
        painterResource(Res.drawable.ic_heart_primary_color_fill)
    } else {
       icon
    }

    IconButton(
        onClick = {
            clicked = !clicked
            onClick()
        },
        modifier = Modifier.size(48.dp),
        content = {
            Icon(
                painter = iconToShow,
                contentDescription = null,
                tint = if (onClickSuccess) Color(0xFF1B98F0) else Color(0xFF1B98F0),
                modifier = Modifier.size(24.dp)
            )
        }
    )
}



