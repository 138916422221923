package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_search_page.data

import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.text.input.TextFieldValue
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant

//@Stable
//class SearchState(
//    query: TextFieldValue,
//    focused: Boolean,
//    searching: Boolean,
//    suggestions: List<RequestConsultant>,
//    searchResults: List<RequestConsultant>
//) {
//    var query by mutableStateOf(query)
//    var focused by mutableStateOf(focused)
//    var searching by mutableStateOf(searching)
//    var suggestions by mutableStateOf(suggestions)
//    var searchResults by mutableStateOf(searchResults)
//
//    val searchDisplay: SearchDisplay
//        get() = when {
//            !focused && query.text.isEmpty() -> SearchDisplay.InitialResults
//            focused && query.text.isEmpty() -> SearchDisplay.Suggestions
//            searchResults.isEmpty() -> SearchDisplay.NoResults
//            else -> SearchDisplay.Results
//        }
//
//    override fun toString(): String {
//        return "🚀 State query: $query, focused: $focused, searching: $searching " +
//                "suggestions: ${suggestions.size}, " +
//                "searchResults: ${searchResults.size}, " +
//                " searchDisplay: $searchDisplay"
//
//    }
//}

@Stable
class SearchState(
    query: TextFieldValue,
    focused: Boolean,
    searching: Boolean,
    suggestions: List<RequestConsultant>,
    searchResults: List<RequestConsultant>
) {
    var query by mutableStateOf(query)
    var focused by mutableStateOf(focused)
    var searching by mutableStateOf(searching)
    var suggestions by mutableStateOf(suggestions)
    var searchResults by mutableStateOf(searchResults)

    val searchDisplay: SearchDisplay
        get() = when {
            query.text.isEmpty() && !focused -> SearchDisplay.InitialResults
            query.text.isEmpty() && focused -> SearchDisplay.Suggestions
            searching -> SearchDisplay.Suggestions // Show loading state during search
            query.text.isNotEmpty() && searchResults.isEmpty() -> SearchDisplay.NoResults
            query.text.isNotEmpty() -> SearchDisplay.Results
            else -> SearchDisplay.InitialResults
        }
}
