package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_bottom_sheet_info


import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation.data.request.GetChatDurationRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatConsultationInfoRepo
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import tta.destinigo.talktoastro.feature_winyway_wallet.data.api.WalletRechargeApi
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_pack.data.response.RechargeData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ChatConsultationComponent(
    context: ComponentContext,
    private val _expertId: String,
    private val _expertName: String,
    private val _expertImage: String,
    private val _expertSkills: String,
    private val onClose: () -> Unit,
    private val onConnect: (String,String) -> Unit,
    private val onRechargeAgain:(RechargeData?)->Unit,
    private val lowBalanceRecharge:() -> Unit,
) : ComponentContext by context, KoinComponent {

    companion object {
        private const val FREE_CHAT_TEXT = "Free Chat"
        private const val CHAT_TYPE = "Chat_Type"
    }

    val expertId get() = _expertId
    val expertName get() = _expertName
    val expertImage get() = _expertImage
    val expertSkills get() = _expertSkills
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatConsultationInfoRepo by inject()
    private val _state =
        MutableStateFlow<ChatInfoBottomSheetState>(ChatInfoBottomSheetState.Loading(false))
    val state get() = _state.asStateFlow()

    fun onClosePage() = onClose.invoke()
    fun onLowBalanceRecharge() = lowBalanceRecharge.invoke()
    fun startConsultation(chatDuration:String,category:String) = onConnect.invoke(chatDuration,category)
    fun onRechargeAgain(rechargeData:RechargeData?) = onRechargeAgain.invoke(rechargeData)

    init {
        lifecycle.doOnStart {
            Logger.d { "Home Screen Tab Component onStart" }
            coroutineScope.launch {
                getConsultationInfo()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Home Screen Tab Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun onError(msg: String) = coroutineScope.launch {
        _state.emit(ChatInfoBottomSheetState.Error(msg))
    }

    private fun onSuccess(data: ChatDurationData?) = coroutineScope.launch {

        if (data == null) {
            Logger.d("Data Null State")
            onError("Something Went Wrong")
            return@launch
        }

        if (data.activeStatus.equals("Busy")) {
            Logger.d("Busy State")
            onError("Sorry, our expert is currently occupied. Please try again later.")
            return@launch
        }

        if (data.type.equals(FREE_CHAT_TEXT, true) &&
            (data.balanceAsInt  ?: 0) <= (data.minimumBalanceAsInt ?: 0)
        ) {
            // Free Chat Low Balance
            Logger.d("Free Chat Low Balance State")
            _state.emit(ChatInfoBottomSheetState.FreeChatLowBalance(data))
            return@launch
        }

        Logger.d("minimum Balance -> ${data.minimumBalance}")
        val minBalance = data.minimumBalance?.trim().isNullOrEmpty()
        if (minBalance.not() && data.type.equals(FREE_CHAT_TEXT, true).not()) {
            // Check if minimumBalanceAsInt is not null and type is not FREE_CHAT_TEXT
            // Low Balance
            Logger.d("Normal Chat Low Balance")
            if (data.balance != null && data.balance.toFloat() < (data.minimumBalance?.toFloat()
                    ?: 0F)
            ) {
                // Check if balanceAsInt is not null and is less than minimumBalanceAsInt
                Logger.d("Recharge Plan Loading")
                getRechargePlans(data)
                return@launch
            }
        }

//        if (data.balance <= data.minimumBalance && data.type.equals(ChatInfoBottomSheet.FREE_CHAT_TEXT, true).not()) {
//            Log.d(TAG, "onSuccess: minBal -> ${data.minimumBalance}, type -> ${data.type}")
//            //Low Balance
//            getRechargePlans(data)
//            return@launch
//        }
        Logger.d("Chat Info Success State")
        _state.emit(ChatInfoBottomSheetState.ChatInfoSuccess(data))
    }

    private suspend fun getConsultationInfo() {
        val request = GetChatDurationRequest(
            expertId = expertId
        )

        api.getChatConsultationInfo(request).collect {
            when (it) {
                is Resources.Error -> onError(it.msg ?: "Unexpected Error")
                is Resources.Loading -> _state.emit(ChatInfoBottomSheetState.Loading(it.isLoading))
                is Resources.Success -> onSuccess(it.data)
            }
            Logger.d("component state -> $it")
        }
    }

    private suspend fun onRechargeListLoaded(data: ChatDurationData, list: List<RechargeData>) {
        _state.emit(ChatInfoBottomSheetState.NormalChatLowBalance(data, list))
    }

    private suspend fun getRechargePlans(chatDurationData: ChatDurationData) {
        Logger.d("Recharge Plans remote")
        val rechargePackState = WalletRechargeApi().requestRechargePlanList().data?.data
        Logger.d("Recharge Plan - $rechargePackState")
        if (rechargePackState.isNullOrEmpty()) {
            onError("Something Went Wrong While Loading")
            return
        }

        onRechargeListLoaded(chatDurationData, rechargePackState)
    }

}