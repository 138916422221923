package tta.destinigo.talktoastro.feature_article.persentation.post_page

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_article.data.response.Post
import tta.destinigo.talktoastro.feature_article.domain.ArticleRepo

class ArticleComponent(
    componentContext: ComponentContext,
    private val slug: String,
    private val onClose: () -> Unit,
):ComponentContext by componentContext,KoinComponent  {

    private val coroutineScope by lazy { CoroutineScope(SupervisorJob() + Dispatchers.Main) }
    private val api: ArticleRepo by inject()

    private val _state = MutableStateFlow<Resources<Post?>>(Resources.Loading(false))
    val state get() = _state.asStateFlow()

    private val articleState = MutableStateFlow<Post?>(null)
    val article get() = articleState.asStateFlow()


    fun onClosePage() = onClose.invoke()


    init {
        lifecycle.doOnStart {
            coroutineScope.launch { getPosts() }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun getPosts() {
        api.getArticleDetail(slug).collect{
            when(it){
                is Resources.Error -> {
                    _state.emit(Resources.Error(it.msg))
                }
                is Resources.Loading -> {
                    _state.emit(Resources.Loading(it.isLoading))
                }
                is Resources.Success -> {
                    articleState.emit(it.data)
                }

            }
        }
    }

}