package tta.destinigo.talktoastro.feature_custom_order.custom_order_details

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent

class EnrollCustomOrderConfirmationBottomSheetComponent(
    context: ComponentContext,
    private val onBackPress:()->Unit,
    private val onClickLesson:(String)->Unit,
    private val _customOrderId:String,
): ComponentContext by context, KoinComponent {


    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()
    }
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    val customOrderId get() = _customOrderId
    fun onBackClick() = onBackPress.invoke()
    fun onLessonClick() = onClickLesson.invoke(customOrderId)
    init {
        lifecycle.doOnStart {
            Logger.d { "Custom Order Details Screen Component onStart" }
            coroutineScope.launch {

            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Custom Order Details Screen Component onDestroy" }
            coroutineScope.cancel()
        }
    }
}