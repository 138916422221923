package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.CustomOrderListData

@Serializable
data class EnrollResponseData(
    @SerialName("Amount") val amount: Int, // 200
    @SerialName("customOrderDetails") val customOrderDetails: CustomOrderDetails,
//    @SerializedName("CustomPurchaseHistory") val customPurchaseHistory: List<Any>,
    @SerialName("Service") val service: String, // Paid
    @SerialName("TxnHistory") val txnDetails: CustomOrderTxnDetails // Paid
)
