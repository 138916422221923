package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.verify_recharge_status

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerifyRechargeStatusResponse(
    @SerialName("code")
    val code: Int?,
    @SerialName("message")
    val message: String?,
    @SerialName("status")
    val status: Boolean?,
    @SerialName("data")
    val data: VerifyRechargeData?
)