package tta.destinigo.talktoastro.feature_call_consultation.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CallDurationResponse(
    @SerialName("status")val status:Boolean,
    @SerialName("code")val code:Int?,
    @SerialName("message")val message:String?,
    @SerialName("data")val data:CallDurationData?,
)

@Serializable
data class CallDurationData(
    @SerialName("plan_amount") val plan_amount: Int? = null,
    @SerialName("plan_name") val plan_name: String? = null,
    @SerialName("plan_id") val planId: Int? = null,
    @SerialName("type") val type: String? = null,
    @SerialName("expert_id") val expertId: String? = null,
    @SerialName("expert_name") val expertName: String? = null,
    @SerialName("balance") val balance: String? = null,
    @SerialName("per_minute_price") val _perMinutePrice: String? = null,
    @SerialName("emergency_price") val _emergency_price: String?,
    @SerialName("total_chat_duration") val _totalChatDuration: String?,
    @SerialName("total_call_duration") val _totalCallDuration: String? = null,
    @SerialName("flash_price") val flashOff: Int? = null,
    @SerialName("minbalance") val minimumBalance: String?,
    @SerialName("minduration") val minimumDuration: String? = "",
    @SerialName("activestatus") val activeStatus: String? = ""
){

    val perMinutePrice: String?
        get() =   _emergency_price ?: _perMinutePrice



    val totalCallDuration: String?
        get() = _totalCallDuration ?: _totalChatDuration

//    val balanceAsInt: Int
//        get() = balance?.toIntOrNull() ?: balance?.toFloatOrNull()?.toInt() ?: 0
//
//    val minimumBalanceAsInt: Int
//        get() = minimumBalance?.toIntOrNull() ?: balance?.toFloatOrNull()?.toInt() ?: 0


    val balanceAsInt: Int
        get() = balance?.toIntOrNull() ?: balance?.toFloatOrNull()?.toInt() ?: 0

    val minimumBalanceAsInt: Int
        get() = minimumBalance?.toIntOrNull() ?: minimumBalance?.toFloatOrNull()?.toInt() ?: 0
}