package tta.destinigo.talktoastro.feature_courses.persentation.course_page

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ContentPasteGo
import androidx.compose.material.icons.filled.Info
import androidx.compose.material.icons.filled.QuestionAnswer
import androidx.compose.material.icons.filled.ViewAgenda
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Tab
import androidx.compose.material3.TabRow
import androidx.compose.material3.TabRowDefaults.SecondaryIndicator
import androidx.compose.material3.TabRowDefaults.tabIndicatorOffset
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInRoot
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryDarkerColor
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.CourseHeaderSection
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.registration_form.RegistrationForm
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.sections.CurriculumSection
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.sections.FaqSection
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.sections.InstructorsSection
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.sections.OverViewSection
import tta.destinigo.talktoastro.feature_courses.persentation.widget.CourseTopBar

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CoursePage(
    component: CourseComponent
) {

    val state by component.state.collectAsState()
    val courses by component.courses.collectAsState()
    val tabs = listOf("Overview", "Curriculum", "Instructor", "Faq")
    var selectedTabIndex by remember { mutableStateOf(0) }

    val lazyListState = rememberLazyListState()
    val coroutineScope = rememberCoroutineScope()
    val windowWidth = calculateWindowSizeClass().widthSizeClass

    val shouldShow = windowWidth == WindowWidthSizeClass.Compact
    val isTableMode = windowWidth == WindowWidthSizeClass.Medium

    if ((state as Resources.Loading).isLoading) {
        LoadingProgressDialog { }
    }

    Scaffold(
        containerColor = background_color,
        topBar = {
            CourseTopBar { component.onBackPress() }
        }
    ) { paddingValues ->

        if (courses != null) {

            val lastIndex = lazyListState.layoutInfo.totalItemsCount - 1

            Box(
                modifier = Modifier.fillMaxSize().padding(paddingValues)
            ) {
                val paddingHorizontal = if (isTableMode) 1f else 2f
                if (shouldShow.not()) {

                    val paddingHorizontal = if (isTableMode) 18 else 36

                    Row(
                        modifier = Modifier.fillMaxSize().padding(horizontal = paddingHorizontal.dp)
                    )
                    {

                        val weight = if (isTableMode) 1f else 2f
                        // Scrollable content
                        LazyColumn(
                            state = lazyListState,
                            modifier = Modifier
                                .weight(weight)
                        ) {

                            item {
                                Spacer(modifier = Modifier.height(24.dp))
                            }
                            // Header section
                            item {
                                CourseHeaderSection(
                                    courses!!,
                                    windowWidth
                                )
                            }

                            // Content Section
                            // Sticky Header for Tab Row
                            item {
                                Box(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                ) {
                                    TabRow(
                                        selectedTabIndex = selectedTabIndex,
                                        containerColor = Color.Transparent,
                                        indicator = { tabPositions ->
                                            SecondaryIndicator(
                                                Modifier.tabIndicatorOffset(tabPositions[selectedTabIndex]),
                                                color = primaryDarkerColor
                                            )
                                        }
                                    ) {
                                        tabs.forEachIndexed { index, title ->
                                            Tab(
                                                selected = selectedTabIndex == index,
                                                onClick = {
                                                    selectedTabIndex = index
                                                    coroutineScope.launch {
                                                        // Scroll to content when tab is clicked
                                                        lazyListState.animateScrollToItem(index + 1)
                                                    }
                                                },
                                                text = {
                                                    Text(
                                                        text = title,
                                                        style = MaterialTheme.typography.bodyLarge.copy(
                                                            color = Color.Black,
                                                            fontWeight = if (selectedTabIndex == index) FontWeight.SemiBold else FontWeight.ExtraBold,
                                                        )
                                                    )
                                                }
                                            )
                                        }
                                    }
                                }
                            }

                            // Tab Content Section
                            items(tabs.size) { index ->
                                AnimatedContent(
                                    targetState = selectedTabIndex,
                                    transitionSpec = { fadeIn() togetherWith fadeOut() },
                                    label = "Tab Animation"
                                ) { targetIndex ->
                                    if (index == targetIndex) {
                                        when (targetIndex) {
                                            0 -> OverViewSection(
                                                modifier = Modifier,
                                                description = courses?.description ?: "",
                                                list = courses?.overviewSection!!
                                            )

                                            1 -> CurriculumSection(
                                                list = courses?.curriculamSection!!
                                            )

                                            2 -> InstructorsSection(
                                                modifier = Modifier,
                                                instructors = courses?.instructors!!
                                            )

                                            3 -> FaqSection(
                                                modifier = Modifier,
                                                list = courses?.faqSection!!
                                            )
                                        }
                                    }
                                }
                            }

                            item {
                                Spacer(modifier = Modifier.height(32.dp))
                            }
                        }

                        // Registration Form
                        RegistrationForm(
                            modifier = Modifier.weight(1f)
                                .padding(top = 24.dp, start = 8.dp, end = 8.dp).wrapContentHeight(),
                            originalPrice = courses?.originalPrice!!,
                            price = courses?.price!!,
                            discountPrice = courses?.discountPrice!!,
                            onClickSubmit = { name, email, mobile ->
                                component.onSubmitForm(
                                    name, mobile, email, courses?.id.toString()
                                )
                            },
                            component = component
                        )
                    }
                } else {

                    // Track the offset of the registration form
                    var registrationFormOffset by remember { mutableStateOf(0) }
                    val icons = listOf(
                        Icons.Filled.ViewAgenda,  // Replace with your actual icons
                        Icons.Filled.ContentPasteGo,
                        Icons.Filled.Info,
                        Icons.Filled.QuestionAnswer
                    )

                    // this is for Mobile View
                    // Scrollable content
                    LazyColumn(
                        state = lazyListState,
                        modifier = Modifier.padding(horizontal = 16.dp)
                    ) {

                        item {
                            Spacer(modifier = Modifier.height(16.dp))
                        }
                        // Header section
                        item {
                            CourseHeaderSection(courses!!,
                                windowWidth,
                                onButtonClick = {
                                    coroutineScope.launch {
                                        lazyListState.animateScrollToItem(lastIndex)
                                    }
                                }
                            )
                        }

                        // Content Section
                        // Sticky Header for Tab Row
                        item {
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                            ) {
                                TabRow(
                                    selectedTabIndex = selectedTabIndex,
                                    containerColor = Color.Transparent,
                                    indicator = { tabPositions ->
                                        SecondaryIndicator(
                                            Modifier.tabIndicatorOffset(tabPositions[selectedTabIndex]),
                                            color = primaryDarkerColor
                                        )
                                    }
                                ) {
                                    tabs.forEachIndexed { index, title ->
                                        Tab(
                                            selected = selectedTabIndex == index,
                                            onClick = {
                                                selectedTabIndex = index
                                                coroutineScope.launch {
                                                    // Scroll to content when tab is clicked
                                                    lazyListState.animateScrollToItem(index + 1)
                                                }
                                            },
                                            text = {
                                                Text(
                                                    text = title,
                                                    overflow = TextOverflow.Ellipsis,
                                                    maxLines = 1,
                                                    style = MaterialTheme.typography.labelSmall.copy(
                                                        color = Color.Black,
                                                        fontWeight = if (selectedTabIndex == index) FontWeight.SemiBold else FontWeight.ExtraBold,
                                                    )
                                                )
                                            },
                                            icon = {
                                                Icon(
                                                    imageVector = icons[index], // Set the corresponding icon for each tab
                                                    contentDescription = null, // Add a content description for accessibility if needed
                                                    modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL) // You can customize the size of the icon
                                                )
                                            }
                                        )
                                    }
                                }
                            }
                        }

                        // Tab Content Section
                        items(tabs.size) { index ->
                            AnimatedContent(
                                targetState = selectedTabIndex,
                                transitionSpec = { fadeIn() togetherWith fadeOut() },
                                label = "Tab Animation"
                            ) { targetIndex ->
                                if (index == targetIndex) {
                                    when (targetIndex) {
                                        0 -> OverViewSection(
                                            modifier = Modifier,
                                            description = courses?.description ?: "",
                                            list = courses?.overviewSection!!
                                        )

                                        1 -> CurriculumSection(
                                            list = courses?.curriculamSection!!
                                        )

                                        2 -> InstructorsSection(
                                            modifier = Modifier,
                                            instructors = courses?.instructors!!
                                        )

                                        3 -> FaqSection(
                                            modifier = Modifier,
                                            list = courses?.faqSection!!
                                        )
                                    }
                                }
                            }
                        }

                        item {
                            // Registration Form
                            RegistrationForm(
                                modifier = Modifier.fillMaxWidth()
                                    .padding(top = 16.dp).wrapContentHeight()
                                    .onGloballyPositioned { coordinates ->
                                        val formPosition =
                                            coordinates.positionInRoot() // Get the position in root coordinates
                                        registrationFormOffset = formPosition.y.toInt()
                                    },
                                originalPrice = courses?.originalPrice!!,
                                price = courses?.price!!,
                                discountPrice = courses?.discountPrice!!,
                                onClickSubmit = { name, email, mobile ->
                                    component.onSubmitForm(
                                        name, mobile, email, courses?.id.toString()
                                    )
                                },
                                component = component
                            )
                        }

                        item {
                            Spacer(modifier = Modifier.height(42.dp))
                        }
                    }

                }
            }
        }
    }

}
