package tta.destinigo.talktoastro.feature_courses.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CourseDetailsSubmitForm(
    @SerialName("full_name") val fullName: String,
    @SerialName("mobile") val mobile: String,
    @SerialName("email") val email: String,
    @SerialName("course_id") val courseId: String,
)
