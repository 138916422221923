package tta.destinigo.talktoastro.feature_expert_consultation.data.remote.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ExpertListRequest(
    @SerialName("category_id") val categoryId:String?,
    @SerialName("type") val type:String?
)
