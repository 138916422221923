package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_getway

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.razorpay.startPayment
import tta.destinigo.talktoastro.feature_winyway_wallet.data.api.WalletRechargeApi
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.GetRazorPayRequest
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.VerifyRechargeFailRequest
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.VerifyRechargeStatusRequest
import tta.destinigo.talktoastro.feature_winyway_wallet.data.response.razorpay_order_id.RazorPayOrderData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.local.getPlatformType
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.CustomOrderPurchaseStatus
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.CustomOrderPurchaseSuccess
import kotlin.coroutines.cancellation.CancellationException

class PaymentGatewayComponent(
    context: ComponentContext,
    private val paymentGatewayArg: PaymentGatewayArg,
    private val onRechargeSuccess: (String,String) -> Unit, //Required Top-Up Amount
    private val onRechargeFailed: (String) -> Unit, // Required Top-Up Amount,
    private val _isCustomOrder:Boolean = false,
    private val _txnId:String = "",
    private val _cusOrderId:String = "",
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: WalletRechargeApi by inject()
    private val _state = MutableStateFlow<RazorPayOrderData?>(null)
    val state get() = _state.asStateFlow()
    private val isCustomOrder get() = _isCustomOrder
    val cusOrderId get() = _cusOrderId
    val txnId get() = _txnId
    init {
        lifecycle.doOnStart {
//            onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
            Logger.d { "PaymentGatewayComponent on start" }
            getRazorpayOrderId()
        }

        lifecycle.doOnDestroy {
            Logger.d { "PaymentGatewayComponent on destroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

//    private fun initRazorpayPayment(razorpayOrderId: String) {
//        startPayment(
//            razorpayOrderId,
//            onPaymentSuccess = { paymentId, orderId, signature ->
//                verifySuccessStatus(orderId, paymentId, signature)
//            },
//            onPaymentFailed = { throwable ->
//                verifyFailStatus(
//                    throwable.message ?: "Unexpected Payment Error"
//                )
//            })
//    }
private fun initRazorpayPayment(razorpayOrderId: String) {
    startPayment(
        razorpayOrderId,
        onPaymentSuccess = { paymentId, orderId, signature ->
            if(isCustomOrder){
//                Logger.d { "orderId: $orderId" }
//                Logger.d { "cusOrderId: $cusOrderId" }
//                Logger.d { "paymentGatewayArg.orderId new: ${paymentGatewayArg.orderId}" }
//                Logger.d { "paymentGatewayArg.txnId new: ${paymentGatewayArg.txnId}" }
                onCustomOrderStatusChange(orderId = orderId,signature = signature, paymentId = paymentId, txnId = paymentGatewayArg.txnId, cusOrderId = paymentGatewayArg.orderId)
            }else{
                verifySuccessStatus(orderId, paymentId, signature)
            }

        },
        onPaymentFailed = { throwable ->
            Logger.d { "onPaymentFailed ${throwable.message}" }
            // Handle user-canceled payment and log the error
            val errorMessage = throwable.message ?: "Unexpected Payment Error"
            if (errorMessage.contains("Payment cancelled", ignoreCase = true)) {
                //Timber.e("Payment was canceled by the user.")
                Logger.d {  "initRazorpayPayment:-  Payment was canceled by the user" }
                verifyFailStatus("User canceled the payment.")
            } else {
                Logger.d { "initRazorpayPayment:- Payment failed: $errorMessage" }
                verifyFailStatus(errorMessage)
            }
        }
    )
}


    private fun getRazorpayOrderId() {
        ///val platformType = @androidx.compose.runtime.Composable { getPlatformType() }
        val getRazorPayRequest = GetRazorPayRequest(
            taxnId = paymentGatewayArg.txnId,
            isCoinUsed = if (paymentGatewayArg.coinUsed > 0) 1 else 0,
            //isCoinUsed = 1,
            domain = "talktoastro.com",
            ///platformType = platformType.toString()
        )
        coroutineScope.launch {
            api.getRazorPayOrderId(getRazorPayRequest).collectLatest {
                when (it) {
                    is Resources.Success -> {
                        if (it.data?.razorpayOrderId == null) {
                            onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                            return@collectLatest
                        }
                        initRazorpayPayment(it.data.razorpayOrderId)
                        _state.value = it.data
                        Logger.d { "getRazorpayOrderId success part:- ${it.data.razorpayOrderId}" }
                    }

                    is Resources.Error -> {
                        Logger.d { "getRazorpayOrderId error part :- ${paymentGatewayArg.cashPayment}" }
                        onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                    }
                    else -> {
                        Logger.d { "getRazorpayOrderId else part :- ${it.data?.razorpayOrderId}" }
                    }
                }
            }
        }
    }




    private fun onCustomOrderStatusChange(orderId: String, signature: String, paymentId: String, txnId: String, cusOrderId: String) {
        Logger.d { "onCustomOrderStatusChange normal order id: $orderId" }
        Logger.d { "onCustomOrderStatusChange normal signature id: $signature" }
        Logger.d { "onCustomOrderStatusChange normal paymentId id: $paymentId" }

        val request = CustomOrderPurchaseStatus(
            razorpayPaymentId = paymentId,
            razorpayOrderId = orderId,
            razorpaySignature = signature,
            Domain = "talktoastro.com"
        )

        // Ensure the coroutineScope is properly defined
        coroutineScope.launch {
            try {
                api.onGetCustomOrderPurchaseStatus(request).collect { result ->
                    when (result) {
                        is Resources.Error -> {
                            onRechargeFailed.invoke("CustomOrder")
                            Logger.d { "onCustomOrderStatusChange Error ${result.msg}" }
                        }
                        is Resources.Loading -> {
                            Logger.d { "onCustomOrderStatusChange Loading ${result.isLoading}" }
                        }
                        is Resources.Success -> {
                            if (result.data != null) {
                                Logger.d { "onCustomOrderStatusChange: $txnId" }
                                Logger.d { "onCustomOrderStatusChange: $orderId" }

                                // Call enrollCustomOrder after success
                                enrollCustomOrder(txnId = txnId, orderId = cusOrderId)
                            }
                            Logger.d { "onCustomOrderStatusChange Success ${result.data}" }
                        }
                    }
                }
            } catch (e: CancellationException) {
                // Handle cancellation exception gracefully, log it or manage according to your needs
                Logger.d { "onCustomOrderStatusChange Flow was cancelled: ${e.message}" }
            } catch (e: Exception) {
                // Catch any other exceptions
                Logger.d { "onCustomOrderStatusChange Error: ${e.message}" }
            }
        }
    }

    private suspend fun enrollCustomOrder(txnId: String, orderId: String) {
        Logger.d { "enrollCustomOrder: $txnId" }
        Logger.d { "enrollCustomOrder: $orderId" }
        val request = CustomOrderPurchaseSuccess(
            txnId = txnId,
            orderId = orderId
        )

        // Safe collection of the flow with cancellation exception handling
        try {
            api.customOrderPaymentPurchaseSuccess(request).collect { result ->
                when (result) {
                    is Resources.Error -> {
                        onRechargeFailed.invoke("CustomOrder")
                        Logger.d { "enrollCustomOrder on Error State ${result.msg}" }
                    }
                    is Resources.Loading -> {
                        Logger.d { "enrollCustomOrder on Loading State ${result.isLoading}" }
                    }
                    is Resources.Success -> {
                        Logger.d { "enrollCustomOrder on Success State ${result.data}" }
                        // Notify success via callback
                        onRechargeSuccess.invoke("CustomOrder",orderId)
                    }
                }
            }
        } catch (e: CancellationException) {
            // Handle cancellation gracefully
            Logger.d { "enrollCustomOrder Flow was cancelled: ${e.message}" }
        } catch (e: Exception) {
            // Handle any other errors
            Logger.d { "enrollCustomOrder Error: ${e.message}" }
        }
    }



//    private fun onCustomOrderStatusChange(
//        orderId: String,
//        signature: String,
//        paymentId: String,
//        txnId: String,
//        cusOrderId: String
//    ) {
//        Logger.d { "onCustomOrderStatusChange normal order id: $orderId" }
//        Logger.d { "onCustomOrderStatusChange normal signature id: $signature" }
//        Logger.d { "onCustomOrderStatusChange normal paymentId id: $paymentId" }
//
//        val request = CustomOrderPurchaseStatus(
//            razorpayPaymentId = paymentId,
//            razorpayOrderId = orderId,
//            razorpaySignature = signature,
//            Domain = "talktoastro.com"
//        )
//
//        // Launch coroutine in a safe scope (e.g., viewModelScope if this is a ViewModel)
//        viewModelScope.launch {
//            try {
//                api.onGetCustomOrderPurchaseStatus(request).collectLatest { result ->
//                    when (result) {
//                        is Resources.Error -> {
//                            Logger.d { "onCustomOrderStatusChange Error: ${result.msg}" }
//                        }
//                        is Resources.Loading -> {
//                            Logger.d { "onCustomOrderStatusChange Loading: ${result.isLoading}" }
//                        }
//                        is Resources.Success -> {
//                            result.data?.let { data ->
//                                Logger.d { "onCustomOrderStatusChange Success: $data" }
//                                Logger.d { "onCustomOrderStatusChange: $txnId" }
//                                Logger.d { "onCustomOrderStatusChange: $orderId" }
//                                enrollCustomOrder(txnId = txnId, orderId = cusOrderId)
//                            } ?: Logger.d { "onCustomOrderStatusChange: Data is null" }
//                        }
//                    }
//                }
//            } catch (e: CancellationException) {
//                Logger.d { "onCustomOrderStatusChange was cancelled: ${e.message}" }
//            } catch (e: Exception) {
//                Logger.d { "Unexpected error: ${e.message}" }
//            }
//        }
//    }
//
//    private suspend fun enrollCustomOrder(txnId: String, orderId: String) {
//        Logger.d { "enrollCustomOrder: $txnId" }
//        Logger.d { "enrollCustomOrder: $orderId" }
//
//        val request = CustomOrderPurchaseSuccess(
//            txnId = txnId,
//            orderId = orderId
//        )
//
//        try {
//            api.customOrderPaymentPurchaseSuccess(request).collectLatest { result ->
//                when (result) {
//                    is Resources.Error -> {
//                        Logger.d { "enrollCustomOrder Error: ${result.msg}" }
//                    }
//                    is Resources.Loading -> {
//                        Logger.d { "enrollCustomOrder Loading: ${result.isLoading}" }
//                    }
//                    is Resources.Success -> {
//                        Logger.d { "enrollCustomOrder Success: ${result.data}" }
//                        onRechargeSuccess.invoke("CustomOrder")
//                    }
//                }
//            }
//        } catch (e: CancellationException) {
//            Logger.d { "enrollCustomOrder was cancelled: ${e.message}" }
//        } catch (e: Exception) {
//            Logger.d { "Unexpected error in enrollCustomOrder: ${e.message}" }
//        }
//    }

    private fun verifySuccessStatus(orderId: String, paymentId: String, signature: String) {
        val getRazorPayRequest = VerifyRechargeStatusRequest(
            razorPaySignature = signature,
            razorPayOrderId = orderId,
            paymentId = paymentId,
            domain = "talktoastro.com"
        )
        coroutineScope.launch {
            api.getVerifyRechargeStatus(getRazorPayRequest).collectLatest {
                when (it) {
                    is Resources.Success -> {
                        it.data?.totalWalletBalance?.let { totalMoney ->
                            UserInfoProvider.setTotalWalletMoney(totalMoney)
                        }
                      //  Logger.d { "verifySuccessStatus ${paymentGatewayArg.cashPayment}" }
                        onRechargeSuccess.invoke(paymentGatewayArg.cashPayment,"")
                    }

                    is Resources.Error -> onRechargeFailed.invoke(
                        it.msg ?: "Unexpected Error Happening"
                    )

                    else -> Unit
                }
            }
        }
    }

    private fun verifyFailStatus(status: String) {
        val getRazorPayRequest = VerifyRechargeFailRequest(
            orderId = paymentGatewayArg.orderId,
            status = status
        )
        coroutineScope.launch {
            api.getRechargeStatusFailed(getRazorPayRequest).collectLatest {

                when (it) {
                    is Resources.Success -> onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                    else -> onRechargeFailed.invoke(paymentGatewayArg.cashPayment)
                }
            }
        }
    }

}