package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.verify_recharge_status

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class VerifyRechargeData(
    @SerialName("totalwalletbalance") val totalWalletBalance: String?,
    @SerialName("razorpay_order_id") val razorpayOrderId: String?,
    @SerialName("reamining_coins") val remainingCoin: Float?
)
