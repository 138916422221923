package tta.destinigo.talktoastro.feature_courses.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.date_time.formatDateDayOfWeek
import tta.destinigo.talktoastro.core.date_time.formatPrice
import tta.destinigo.talktoastro.core.date_time.formatReviewCount
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_courses.data.CoursesApi
import tta.destinigo.talktoastro.feature_courses.data.request.CourseDetailsSubmitForm
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.CourseInfo

class CourseRepo(
    val api: CoursesApi
) {

    fun getCourse(slug: String): Flow<Resources<CourseInfo>> {
        return flow {
            emit(Resources.Loading())
            when (val response = api.getCourseApi(slug)) {
                is Resources.Error -> emit(Resources.Error(response.msg))
                is Resources.Loading -> emit(Resources.Loading(response.isLoading))
                is Resources.Success -> {
                    if (response.data == null) {
                        emit(Resources.Error("No Data Found"))
                        return@flow
                    } else {
                        with(response.data) {
                            emit(
                                Resources.Success(
                                    CourseInfo(
                                        id = id,
                                        title = title,
                                        description = description,
                                        date = formatDateDayOfWeek(date),
                                        durationDays = "$durationDays Days",
                                        price = "₹${formatPrice(price)}",
                                        originalPrice = "₹${formatPrice(originalPrice)}",
                                        categoryType = categoryType,
                                        slug = slug,
                                        rating = rating,
                                        reviewsCount = "(${formatReviewCount(reviewsCount)} Students)",
                                        imagePath = imagePath,
                                        status = status,
                                        instructors = instructors[0].copy(
                                            reviews = "${formatReviewCount(instructors[0].reviews?.toIntOrNull())} Reviews",
                                            students = "${formatReviewCount(instructors[0].students?.toIntOrNull())} Students",
                                            experience = "${instructors[0].experience}+ Years of Experience"

                                        ),
                                        overviewSection = courseDetails.filter {
                                            it.section == "Overview"
                                        },
                                        curriculamSection = courseDetails.filter {
                                            it.section == "Curriculum"
                                        },
                                        faqSection = courseDetails.filter {
                                            it.section == "faq"
                                        },
                                        discountPrice = "₹${
                                            calculateDifferenceFormatted(
                                                originalPrice,
                                                price
                                            )
                                        }"
                                    )
                                )
                            )
                        }
                    }
                }
            }
            emit(Resources.Loading(false))
        }
    }

    fun submitCourseDetails(request: CourseDetailsSubmitForm): Flow<Resources<Boolean>> {
        return flow {
            emit(Resources.Loading())
            val response = api.postCourseDetailsRemote(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}

fun calculateDifferenceFormatted(originalPrice: String?, price: String?): String {
    val original = originalPrice?.toDoubleOrNull() ?: 0.0
    val current = price?.toDoubleOrNull() ?: 0.0
    val difference = original - current

    // Format result
    return if (difference % 1 == 0.0) {
        difference.toInt().toString() // Convert to Int if whole number
    } else {
        difference.toString() // Keep as Double if decimal
    }
}