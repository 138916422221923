package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatConversationResponse(
    @SerialName("code")
    val code: Int?,
    @SerialName("data")
    val chatConversationData: ChatConversationData?,
    @SerialName("message")
    val message: String?,
    @SerialName("status")
    val status: Boolean
)
