package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.custom_order_parches

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PurchaseSuccessData(
    @SerialName("CustomOrderPurchaseHistory") val customOrderPurchaseHistory: CustomOrderPurchaseHistory,
    @SerialName("txnHistory") val txnHistory: TxnHistory
)
