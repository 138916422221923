package tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.save_chat

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.ChatMessage

@Serializable
data class SaveChatConversationRequest(
    @SerialName("messages") val msg:Array<ChatMessage>,
    @SerialName("chat_id")val chatId:String
)