package tta.destinigo.talktoastro.feature_chat_consultation_new.data.api


import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.place_chat_concern.ChatUserConcernResponse
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.ConcernQuestionRequest
import tta.destinigo.talktoastro.feature_chat_consultation.data.request.DeleteChatRequest
import tta.destinigo.talktoastro.feature_chat_consultation.data.request.GetChatDurationRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.PlaceChatRequestBody
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration.ChatDurationResponse
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.concern_question.ConcerQuestionsResponse
import tta.destinigo.talktoastro.feature_chat_consultation.data.request.StartChatRequest
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.end_consultation.ChatConsultationEndData
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.end_consultation.ChatConsultationEndResponse
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.place_chat_concern.ChatUserConcernData
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.start_consultation.StartChatConsultationResponse
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.start_consultation.StartConsultationData
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.concern_question.ConcernQuestions
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.accept
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.util.InternalAPI
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import tta.destinigo.talktoastro.core.data.CommonResponse

class ChatConsultationApi : KtorApi() {

    companion object {
        const val GET_AGORA_CHAT_TOKEN = "GET_AGORA_TOKEN"
        private const val CHAT_PREFIX = "chat/"
        const val GET_CHAT_DURATION_INFO = CHAT_PREFIX + "user-consultation-duration"
        const val PLACE_CHAT_WITH_CONCERN = CHAT_PREFIX + "user-place-with-concern"
        const val START_CHAT_ROOM = CHAT_PREFIX + "user-consultation-start"
        const val END_CHAT_ROOM = CHAT_PREFIX + "user-consultation-end"
        const val END_CHAT_BEFORE_JOIN = CHAT_PREFIX + "end-consultation"
        const val SAVE_CHAT_CONVERSATION = CHAT_PREFIX + "save-history"
        const val GET_CHAT_LIST = CHAT_PREFIX + "list"
        const val GET_CHAT_CONVERSATION = CHAT_PREFIX + "view-history"
        const val ADD_CHAT_RATING = CHAT_PREFIX + "add-review"
        const val ADD_CONCERN_QUESTION = CHAT_PREFIX + "concern-questions"
        const val UPLOAD_CHAT_IMAGE_TO_SERVER = CHAT_PREFIX + "save-file"
        const val DELETE_CHAT_MSG = CHAT_PREFIX + "delete-history"
    }

    private suspend fun chatDurationInfoApi(request: GetChatDurationRequest) = client.post {
        url(GET_CHAT_DURATION_INFO)
        setBody(request)
    }.body<ChatDurationResponse>()

    private suspend fun uploadImageToServerApi(request: GetChatDurationRequest) = client.get {
        url(UPLOAD_CHAT_IMAGE_TO_SERVER)
        setBody(request)
    }.body<ChatDurationResponse>()

    private suspend fun concernQuestionApi(request: ConcernQuestionRequest) = client.post {
        url(ADD_CONCERN_QUESTION)
        setBody(request)
    }.body<ConcerQuestionsResponse>()

    private suspend fun startChatConsultationApi(request: StartChatRequest) = client.post {
        accept(ContentType.Application.Json)
        url(START_CHAT_ROOM)
        setBody(request)
    }.body<StartChatConsultationResponse>()

    private suspend fun enChatConsultationApi(request: StartChatRequest) = client.post {
        url(END_CHAT_ROOM)
        setBody(request)
    }.body<ChatConsultationEndResponse>()

    private suspend fun endChatBeforeJoinConsultationApi(request: StartChatRequest) = client.post {
        setBody(request)
        url(END_CHAT_BEFORE_JOIN)
    }.body<ChatConsultationEndResponse>()


    @OptIn(InternalAPI::class)
    private suspend fun placeChatRequestApi(request: PlaceChatRequestBody) = client.post {
        url(PLACE_CHAT_WITH_CONCERN)
        body = MultiPartFormDataContent(
            // Add `question_id`
            formData {
                // Add `question_id`
                request.questionId?.let {
                    append("question_id", it)
                }

                // Add `expert_id`
                append("expert_id", request.expertId)

                // Add `category`
                request.category?.let {
                    append("category", it)
                }

                // Add `flash_off`
                request.flashOff?.let {
                    append("flash_off", it)
                }

                // Add `type`
                request.type?.let {
                    append("type", it)
                }

                // Assuming request.images is a list of image file paths or byte arrays
                request.images?.forEach { image ->
                    append("images", image) // Ensure images are formatted correctly
                }

                // Prepare answers in JSON format
                request.answers?.let { answers ->
                    val answerJson = Json.encodeToString(answers)
                    append("answers", answerJson)
                }

            }

        )
    }.body<ChatUserConcernResponse>()

    private suspend fun agoraChatTokenApi(request: PlaceChatRequestBody) = client.get {
        url(GET_AGORA_CHAT_TOKEN)
    }.body<ChatUserConcernResponse>()

    private suspend fun endEmergencyChatConsultationApi(request: PlaceChatRequestBody) =
        client.get {
            url(END_CHAT_ROOM)
        }.body<ChatUserConcernResponse>()

    private suspend fun endChatBeforeJoinConsultationApi(request: PlaceChatRequestBody) =
        client.get {
            url(END_CHAT_BEFORE_JOIN)
        }.body<ChatUserConcernResponse>()

    private suspend fun saveChatConversationApi(request: PlaceChatRequestBody) = client.get {
        url(SAVE_CHAT_CONVERSATION)
    }.body<ChatConsultationEndResponse>()

    private suspend fun chatHistoryListApi() = client.get {
        url(GET_CHAT_LIST)
    }

    private suspend fun chatHistoryConversationApi() = client.post {
        url(GET_CHAT_CONVERSATION)
    }

    private suspend fun addChatRatingApi() = client.post {
        url(ADD_CHAT_RATING)
    }

    private suspend fun emergencyConcernQuestionApi(request: ConcernQuestionRequest) = client.post {
        url(ADD_CONCERN_QUESTION)
        setBody(request)
    }.body<ConcerQuestionsResponse>()

    private suspend fun deleteChatApi(request: DeleteChatRequest) = client.post {
        url(DELETE_CHAT_MSG)
        setBody(request)
    }.body<CommonResponse>()

    suspend fun startChatConsultation(request: StartChatRequest): Resources<StartConsultationData> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse = startChatConsultationApi(request)

                if (topConsultantResponse.status?.not() == true) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun endChatConsultation(request: StartChatRequest): Resources<ChatConsultationEndData> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse = enChatConsultationApi(request)

                if (topConsultantResponse.status?.not() == true) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
    suspend fun saveChatConsultation(request: PlaceChatRequestBody): Resources<ChatConsultationEndData> {
        return withContext(Dispatchers.Default) {
            try {
                val saveChatConsultationResponse = saveChatConversationApi(request)

                if (saveChatConsultationResponse. status?.not() == true) {
                    return@withContext Resources.Error(
                        saveChatConsultationResponse.message
                    )
                }

                return@withContext Resources.Success(saveChatConsultationResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun endConsultationBeforeJoin(request: StartChatRequest): Resources<ChatConsultationEndData> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse = endChatBeforeJoinConsultationApi(request)

                if (topConsultantResponse.status?.not() == true) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun getChatDurationInfo(request: GetChatDurationRequest): Resources<ChatDurationData> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse = chatDurationInfoApi(request)

                if (topConsultantResponse.status.not()) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun placeChatWithConcern(request: PlaceChatRequestBody): Resources<ChatUserConcernData> {
        return withContext(Dispatchers.Default) {
            try {

                val topConsultantResponse = placeChatRequestApi(request)

                if (topConsultantResponse.status?.not() == true) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message ?: "Unexpected Error"
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            } catch (e: ClientRequestException) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getChatConcernQuestion(request: ConcernQuestionRequest): Resources<List<ConcernQuestions>> {
        return withContext(Dispatchers.Default) {
            try {

                val topConsultantResponse = concernQuestionApi(request)

                if (topConsultantResponse.status?.not() == true) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message ?: "Unexpected Error"
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            } catch (e: ClientRequestException) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}