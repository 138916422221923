package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


//
//@Serializable
//data class CustomOrderEnrollResponse(
//    @SerialName(value = "data") val data: EnrollResponseData?
//)

//@Serializable
//data class CustomOrderEnrollResponse(
//    @SerialName("code") val code: Int, // 200
//    @SerialName("data") @Contextual val data: EnrollResponseData?,
//    @SerialName("message") val message: String, // Data fetched successfully
//    @SerialName("status") val status: Boolean // true
//
//)

@Serializable
data class CustomOrderEnrollResponse(
    @SerialName("code") val code: Int, // 200
    @SerialName("data") val data: EnrollResponseData?, // No @Contextual needed here
    @SerialName("message") val message: String, // Data fetched successfully
    @SerialName("status") val status: Boolean // true
)


