package tta.destinigo.talktoastro.core.agora

import tta.destinigo.talktoastro.core.agora.chat.AgoraRtmClientJS

// commonMain/src/commonMain/kotlin/AgoraRtmClient.kt
actual class AgoraRtmClientApp actual constructor(appId: String) {

    private val agoraClient = AgoraRtmClientJS(appId)

    actual fun logout() {
        agoraClient.logout()
    }

    // Set the message receive callback
    actual fun onReceiveMessage(callback: (String?, String) -> Unit) {
        agoraClient.onReceiveMessage = callback
    }

    actual fun initialize(
        token: String, userId: String, onLoginSuccess: () -> Unit, onLoginError: (String) -> Unit
    ) {

        agoraClient.initialize(
            token = token,
            userId = userId,
            onLoginSuccess = onLoginSuccess,
            onLoginError = onLoginError
        )
    }

    actual fun sendMessage(
        peerId: String,
        messageText: String,
        onMessageSentSuccess: () -> Unit,
        onMessageSentError: (String) -> Unit
    ) {

        agoraClient.sendMessage(
            peerId = peerId,
            messageText = messageText,
            onMessageSentSuccess = onMessageSentSuccess,
            onMessageSentError = onMessageSentError
        )
    }
}