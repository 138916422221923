package tta.destinigo.talktoastro.core.razorpay
import tta.destinigo.talktoastro.core.persentation.WINYWAY_IMAGE_URL
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLScriptElement
import kotlin.js.json


fun startPaymentInBrowser(
    orderId: String,
    onResponsePayment: (String, String, String) -> Unit,
    onErrorResponse: (Throwable) -> Unit
) {
    val scope = MainScope()

    scope.launch {
        injectRazorpayForm(orderId, onResponsePayment, onErrorResponse)
    }
}

fun injectRazorpayForm(
    orderId: String,
    onSuccessResponsePayment: (String, String, String) -> Unit,
    onErrorResponse: (Throwable) -> Unit
) {

    val form = document.createElement("form")
    form.setAttribute("id", "payment-form")

    val script = document.createElement("script") as HTMLScriptElement
    script.src = "https://checkout.razorpay.com/v1/checkout.js"

    val hiddenInput = document.createElement("input") as HTMLInputElement
    hiddenInput.type = "hidden"
    hiddenInput.setAttribute("custom", "Hidden Element")
    hiddenInput.name = "hidden"

    form.appendChild(script)
    document.body?.appendChild(form)
    console.log("Form injected") // Debug log

    script.onload = {
        console.log("Razorpay script loaded") // Debug log
        try {
            // Razorpay options and callback
            val options = json(
               // "key" to "rzp_test_2Xyd6IzNwWLtkO",  // Replace with your actual key
                "key" to "rzp_live_SOXGx6aTSzGwx2",  // Replace with your actual key
                "currency" to "INR",
                "name" to "Talk to Astro.",
                "description" to "Payment for Wallet recharge of Winyway",
                "image" to WINYWAY_IMAGE_URL,
                "order_id" to orderId,
                "handler" to { response: dynamic ->
                    try {
                        onSuccessResponsePayment.invoke(
                            response.razorpay_payment_id,
                            response.razorpay_order_id,
                            response.razorpay_signature
                        )
                        handlePaymentSuccess(
                            response.razorpay_payment_id,
                            response.razorpay_order_id,
                            response.razorpay_signature
                        )
                    } catch (e: Throwable) {
                        onErrorResponse.invoke(e)
                        console.error("Error in payment handler: ${e.message}")
                        handlePaymentError(e)
                    }
                },
                "theme" to json(
                    "color" to "#1069BC"
                ),
                "modal" to json(
                    "ondismiss" to {
                        console.log("Payment modal closed by the user.")
                        onErrorResponse.invoke(Exception("Payment modal closed by the user"))
                    }
                )
            )

            console.log("Opening Razorpay checkout") // Debug log
            // Invoke Razorpay checkout
            js("new Razorpay(options).open()")
        } catch (e: Throwable) {
            console.error("Error setting up Razorpay options or opening checkout: ${e.message}")
            onErrorResponse.invoke(e)
            handlePaymentError(e)
        }
    }

    script.addEventListener("error", {
        console.error("Error loading Razorpay script") // Debug log
        onErrorResponse.invoke(Exception("Error loading Razorpay script"))
        handlePaymentError(Exception("Error loading Razorpay script"))
    })
}

fun handlePaymentError(error: Throwable) {
    // Handle error (e.g., show a user-friendly message, log the error, etc.)
    console.error("Payment error: ${error.message}")
}

fun handlePaymentSuccess(paymentId: String, orderId: String, signature: String) {
    // Handle the payment success response in Kotlin
    console.log("Payment ID: $paymentId")
    console.log("Order ID: $orderId")
    console.log("Signature: $signature")
}


