package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Lession(
    @SerialName("created_at") val createdAt: String?, // 2024-04-15 17:35:06
    @SerialName("custom_order_id") val customOrderId: Int?, // 1
    @SerialName("duration") val duration: String?, // 15
    @SerialName("expert_id") val expertId: Int, // 200
    @SerialName("file") val `file`: String?, // 1713182706.pdf
    @SerialName("id") val id: Int?, // 1
    @SerialName("image_url") val imageUrl: String?, // 1
    @SerialName("lession_name") val lessionName: String?, // Astrology
    @SerialName("section_id") val sectionId: Int?, // 1
    @SerialName("section_name") val sectionName: String?, // null
    @SerialName("updated_at") val updatedAt: String? // 2024-04-15 17:35:06
)
