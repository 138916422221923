package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Chat
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.SheetState
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.CORNER_LARGE
import tta.destinigo.talktoastro.core.theme.primaryColor


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class,
    ExperimentalMaterial3Api::class
)
@Composable
fun DeleteChatConfirmationDialog(
    onDeleteChat: () -> Unit,
    onNotNow: () -> Unit,
    onDismissRequest: () -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = remember { SheetState(skipPartiallyExpanded = true) }

    when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> {
            // Dialog for compact screen sizes
            ModalBottomSheet(
                sheetState = sheetState,
                onDismissRequest = { onDismissRequest.invoke() },
                shape = RoundedCornerShape(topStart = CORNER_LARGE, topEnd = CORNER_LARGE),
                modifier = Modifier
                    .fillMaxWidth()
                    .wrapContentHeight()
            ) {
                Content(
                    onDeleteChat = onDeleteChat,
                    onNotNow = onNotNow
                )
            }

        }
        else -> {
            // Bottom sheet for larger screens
            Dialog(onDismissRequest = { onDismissRequest() }) {
                Surface(
                    shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
                    color = Color.White,
                    modifier = Modifier.padding(16.dp).background(Color.White)
                ) {
                    Content(
                        onDeleteChat = onDeleteChat,
                        onNotNow = onNotNow
                    )
                }
            }
        }
    }
}

@Composable
private fun Content(
    onDeleteChat: () -> Unit,
    onNotNow: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
            .padding(vertical = 32.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(24.dp)
    ) {
        Icon(
            Icons.Default.Chat,
            contentDescription = "Chat Again",
            tint = primaryColor,
            modifier = Modifier.size(48.dp)
        )

        Text(
            text = "Are You Sure You want to Delete Chat?",
            style = MaterialTheme.typography.titleMedium,
            textAlign = TextAlign.Center
        )

        Text(
            text = "Deleting this chat will permanently remove it from your conversation history.",
            style = MaterialTheme.typography.labelLarge,
            textAlign = TextAlign.Center,
            modifier = Modifier.padding(horizontal = 16.dp)
        )

        Button(
            onClick = { onDeleteChat() },
            modifier = Modifier
                .fillMaxWidth()
                .height(45.dp)
                .padding(horizontal = 16.dp),
            colors = ButtonDefaults.buttonColors(primaryColor),
            shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
            elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_SMALL),
        ) {
            Text(
                text = "Delete Chat",
                color = Color.White,
                fontSize = 15.sp
            )
        }

        TextButton(
            onClick = { onNotNow() },
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp),
            colors = ButtonDefaults.buttonColors(Color.LightGray),
            shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
            elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_SMALL),
        ) {
            Text(text = "Not Now", color = primaryColor, fontSize = 15.sp)
        }
    }
}


