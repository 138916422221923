package tta.destinigo.talktoastro.feature_custom_order.custom_order_details


import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AccessTime
import androidx.compose.material.icons.filled.Error
import androidx.compose.material.icons.filled.KeyboardArrowDown
import androidx.compose.material.icons.filled.Language
import androidx.compose.material.icons.filled.Lock
import androidx.compose.material.icons.filled.PlayCircle
import androidx.compose.material.icons.filled.Videocam
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import kotlinx.coroutines.coroutineScope
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.persentation.LoginDialog
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.core.theme.star_rating_color
import tta.destinigo.talktoastro.feature_common.persentation.home_tab.home_screen_widget.ConsultantRating
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.CustomOrderListData
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.Lession
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget.SessionDetailsScreen
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.ic_filled_star

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CustomOrderDetailScreen(
    component: CustomOrderDetailsComponent
) {
    val data = component.data

    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }
    Box(modifier = Modifier.fillMaxSize().background(background_color), contentAlignment = Alignment.Center)
    {
//        Logger.d { "lessons: ${data.lessions}" }
//        Logger.d { "liveSession: ${data.liveSession}" }
//        Logger.d { "sections: ${data.sections}" }
        LazyColumn(modifier = widthModifier.fillMaxSize()) {
            item {
                CommonTopAppBar(title = "Custom Order Detail", onBackClick = { component.onBackClick() })
            }
            item {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp)
                        .background(background_color)
                ) {

                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(400.dp)
                    ) {
                        // Header Image
                        AsyncImage(
                            model = data.image,
                            contentDescription = null,
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(400.dp),
                            contentScale = ContentScale.Crop
                        )

                        // Content Type Badge
                        data.contentType?.let {
                            Box(
                                modifier = Modifier
                                    .align(Alignment.TopEnd) // Position the tag at the top-right corner
                                    .padding(8.dp)
                                    .background(primaryColor.copy(alpha = 0.8f), RoundedCornerShape(Scale.CORNER_SMALL))
                                    .padding(horizontal = 8.dp, vertical = 4.dp)
                            ) {
                                Text(
                                    text = it,
                                    style = MaterialTheme.typography.labelSmall,
                                    color = Color.White
                                )
                            }
                        }
                    }
                    Spacer(modifier = Modifier.height(8.dp))
                    // Order Title
                    Text(
                        text = data.heading ?: "",
                        style = MaterialTheme.typography.titleMedium,
                        modifier = Modifier
                            .fillMaxWidth()
                            //.padding(horizontal = 16.dp)
                    )

                    Spacer(modifier = Modifier.height(8.dp))

                    // Price Section
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                           // .padding(horizontal = 16.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        if (data.service.equals("Free",true)) {
                            Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                                Text(
                                    text = "Free",
                                    style = MaterialTheme.typography.labelMedium.copy(
                                        fontWeight = FontWeight.SemiBold,
                                        fontSize = 12.sp
                                    ),
                                    color = MaterialTheme.colorScheme.error,
                                    modifier = Modifier
                                        .background(MaterialTheme.colorScheme.error.copy(alpha = 0.2f), RoundedCornerShape(Scale.CORNER_SMALL))
                                        .padding(horizontal = 8.dp, vertical = 4.dp)
                                )
                                if(!data.displayPrice.isNullOrEmpty() && !data.displayPrice.equals("0",true)){
                                    Text(
                                        text = "${initializeCurrency()}${data.displayPrice}",
                                        color = Color.Black,
                                        style = MaterialTheme.typography.titleSmall,
                                        modifier = Modifier.padding(start = 8.dp)
                                    )
                                }

//                                if (!data.displayPrice.isNullOrEmpty() && !data.displayPrice.equals("0", true)) {
//                                    val priceToDisplay = data.coInternationalPrice ?: data.displayPrice
//                                    Text(
//                                        text = "${initializeCurrency()} $priceToDisplay",
//                                        color = Color.Black,
//                                        fontStyle = FontStyle.Italic,
//                                        fontSize = 16.sp,
//                                        style = MaterialTheme.typography.titleSmall.copy(
//                                            textDecoration = TextDecoration.LineThrough
//                                        ),
//                                        modifier = Modifier.padding(start = 8.dp)
//                                    )
//                                }

//                                val priceToDisplay =  if (initializeCurrency().equals("$", true)) {
//                                    data.coInternationalPrice ?: data.displayPrice
//                                } else {
//                                    data.displayPrice ?: data.coInternationalPrice
//                                }
//
//                                Text(
//                                    text = "${initializeCurrency()} $priceToDisplay",
//                                    color = Color.Black,
//                                    fontStyle = FontStyle.Italic,
//                                    fontSize = 16.sp,
//                                    style = MaterialTheme.typography.titleSmall.copy(
//                                        textDecoration = TextDecoration.LineThrough
//                                    ),
//                                    modifier = Modifier.padding(start = 8.dp)
//                                )


                            }
                        } else {
                            val priceToDisplay = data.coInternationalPrice ?: data.displayPrice
                            Text(
                                //text = if (currencyType) "$${data.displayPrice}" else "₹${data.displayPrice}",
                                text = "${initializeCurrency()}${priceToDisplay}",
                                color = Color.Black,
                                style = MaterialTheme.typography.titleSmall
                            )
                        }
//                        if (data.isFree == 1) {
//                            Text(
//                                text = "Free",
//                                style = MaterialTheme.typography.bodySmall,
//                                color = Color.White,
//                                modifier = Modifier
//                                    .background(MaterialTheme.colorScheme.primary)
//                                    .padding(horizontal = 6.dp, vertical = 2.dp)
//                            )
//                        } else {
//                            Text(
//                                text = "${initializeCurrency()}${data.displayPrice}",
//                                style = MaterialTheme.typography.bodyLarge,
//                                color = MaterialTheme.colorScheme.onBackground
//                            )
//                        }
                    }

                    Spacer(modifier = Modifier.height(8.dp))

                    // Language and Duration
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                           // .padding(horizontal = 16.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Icon(
                            imageVector = Icons.Default.Language,
                            contentDescription = null,
                            modifier = Modifier.size(24.dp)
                        )
                        Text(
                            text = data.language ?: "",
                            style = MaterialTheme.typography.bodySmall,
                            modifier = Modifier.padding(start = 8.dp)
                        )
                        Spacer(modifier = Modifier.width(16.dp))
                        Icon(
                            imageVector = Icons.Default.AccessTime,
                            contentDescription = null,
                            modifier = Modifier.size(24.dp)
                        )
                        Text(
                            text = "Duration: ${data.duration} minutes",
                            style = MaterialTheme.typography.bodySmall,
                            modifier = Modifier.padding(start = 8.dp)
                        )
                    }

                    Spacer(modifier = Modifier.height(8.dp))

                    // Play Button
                    EnrollButton(data = data,onLoginClicked = {component.onLoginButtonClick()}, onDismiss = {},component, primaryColor)

                    Spacer(modifier = Modifier.height(16.dp))
                    if(data.contentType.equals("Live",true)){
                        data.liveSession?.forEach {
                            SessionDetailsScreen(
                                contentType = it.contentAccess ?: "Unavailable",
                                startDate = it.fromDate ?: "Unavailable",
                                endDate = it.toDate ?: "Unavailable",
                                sessionTime = it.time ?: "Unavailable",
                                activeDays = it.days ?: "Unavailable",
                                onEnquiryClick = {}

                            )
                        }
                    }
                   
                    // Description
                    Text(
                        text = data.description ?: "",
                        style = MaterialTheme.typography.bodySmall,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 16.dp)
                    )


                    Spacer(modifier = Modifier.height(16.dp))

                    // Expert Details
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 16.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        AsyncImage(
                            model = data.expertDetail?.profileImageUrl
                                ?: data.expertDetail?.profileImage,
                            contentDescription = null,
                            modifier = Modifier
                                .size(45.dp)
                                .clip(CircleShape)
                                .border(1.dp, Color.Gray, CircleShape)
                        )
                        Spacer(modifier = Modifier.width(16.dp))
                        Column {
                            Text(
                                text = data.expertDetail?.fullName ?: "",
                                style = MaterialTheme.typography.bodyLarge
                            )
                            Row(verticalAlignment = Alignment.CenterVertically) {
                                Icon(
                                    painter = painterResource(Res.drawable.ic_filled_star),
                                    contentDescription = null,
                                    tint = star_rating_color,
                                    modifier = Modifier.size(14.dp)
                                )
                                ConsultantRating(data.expertDetail?.expertReviews?.toDouble())
                            }
                        }
                    }
                }
            }
            item {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp)
                        .background(background_color)
                ) {
                    Spacer(modifier = Modifier.height(4.dp))
                    // Divider
                    HorizontalDivider(color = Color.Black, thickness = 1.dp)
                    Spacer(modifier = Modifier.height(8.dp))
                    Text(text = "Transform your life", fontWeight = FontWeight.Bold, fontSize = 14.sp)
                    Spacer(modifier = Modifier.height(8.dp))
                    data.liveSession?.forEach {
                        if (it.contentAccess.equals("One to One", ignoreCase = true)) {
                            Text("Section Type: One to One")
                        }
                    }
                    data.lessions.let {
                        if (it != null) {
                            Lessons(it)
                        }
                    }
                }
            }
        }
    }
}


@Composable
fun EnrollButton(
    data: CustomOrderListData,
    onLoginClicked: () -> Unit,
    onDismiss: () -> Unit,
    component: CustomOrderDetailsComponent,
    primaryColor: Color
) {
    var showLoginDialog by remember { mutableStateOf(false) }

    if (showLoginDialog) {
        LoginDialog(
            onLoginClicked = {
                onLoginClicked.invoke()
                showLoginDialog = false
            },
            onSignUpClicked = {
                onLoginClicked.invoke()
                showLoginDialog = false
            },
            onDismiss = {
                onDismiss.invoke()
                showLoginDialog = false
            }
        )
    }

    Button(
        onClick = {
            val isUserLogin = UserInfoProvider.getIsLogin()
            if (!isUserLogin) {
                showLoginDialog = true
            } else {
                if (initializeCurrency().equals("₹", true)){
                    component.getEnrollCustomOrder()
                }else{
                    showToast("Coming Soon")
                }

            }
        },
        modifier = Modifier
            .background(primaryColor, shape = RoundedCornerShape(Scale.CORNER_SMALL))
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    ) {
        Text(
            text = if (data.enrollmentStatus == 1) "Play" else "Enroll Now",
            fontSize = 14.sp,
            fontWeight = FontWeight.Bold
        )
    }
}


@Composable
fun Lessons(lesson: List<Lession>) {
    var expandedItem by remember { mutableStateOf<Lession?>(null) }

    Column(modifier = Modifier.padding(16.dp)) {
        lesson.forEach { item ->
            Column(modifier = Modifier.fillMaxWidth()) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .clickable { expandedItem = if (expandedItem == item) null else item }
                        .padding(vertical = 8.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = item.sectionName.toString(),
                        modifier = Modifier.weight(1f),
                        fontWeight = FontWeight.Bold,
                        fontSize = 16.sp,
                        style = MaterialTheme.typography.bodyLarge
                    )

                    // Animate arrow rotation
                    val rotationAngle by animateFloatAsState(
                        targetValue = if (expandedItem == item) 180f else 0f
                    )

                    Icon(
                        imageVector = Icons.Default.KeyboardArrowDown,
                        contentDescription = if (expandedItem == item) "Collapse" else "Expand",
                        modifier = Modifier
                            .clickable {
                                expandedItem = if (expandedItem == item) null else item
                            }
                            .rotate(rotationAngle) // Apply rotation
                    )
                }

                if (expandedItem == item) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 8.dp, vertical = 4.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        // Determine the icon based on the file type
                        val icon = when {
                            item.imageUrl?.endsWith(".mp3", ignoreCase = true) == true -> Icons.Default.PlayCircle
                            item.imageUrl?.endsWith(".webm", ignoreCase = true) == true -> Icons.Default.Videocam
                            item.imageUrl?.endsWith(".mp4", ignoreCase = true) == true -> Icons.Default.Videocam
                            else -> Icons.Default.Error
                        }

                        // Display the media type icon
                        Icon(
                            imageVector = icon,
                            contentDescription = "Media Icon",
                            modifier = Modifier.size(24.dp),
                            tint = MaterialTheme.colorScheme.primary
                        )

                        Spacer(modifier = Modifier.width(8.dp))

                        // Song title or description
                        Text(
                            text = item.lessionName ?: "Unknown Lesson",
                            modifier = Modifier.weight(1f),
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 14.sp,
                            style = MaterialTheme.typography.bodyMedium
                        )

                        // Lock icon
                        Icon(
                            imageVector = Icons.Default.Lock,
                            contentDescription = "Locked Content",
                            modifier = Modifier.size(24.dp),
                            tint = MaterialTheme.colorScheme.secondary
                        )
                    }
                }


                // Show locked MP3 if this item is expanded
//                if (expandedItem == item) {
//                    Row(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .padding(horizontal = 8.dp, vertical = 4.dp),
//                        verticalAlignment = Alignment.CenterVertically
//                    ) {
//                        // Play button
//                        Icon(
//                            imageVector = Icons.Default.PlayCircle,
//                            contentDescription = "Play MP3",  
//                            modifier = Modifier.size(24.dp),
//                            tint = MaterialTheme.colorScheme.primary
//                        )
//
//                        Spacer(modifier = Modifier.width(8.dp))
//
//                        // Song title or description
//                        Text(
//                            text = item.lessionName ?: "Unknown Lesson",
//                            modifier = Modifier.weight(1f),
//                            style = MaterialTheme.typography.bodyMedium
//                        )
//
//                        // Lock icon
//                        Icon(
//                            imageVector = Icons.Default.Lock,
//                            contentDescription = "Locked Content",
//                            modifier = Modifier.size(24.dp),
//                            tint = MaterialTheme.colorScheme.secondary
//                        )
//                    }
//                }
            }
        }
    }
}