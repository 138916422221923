package tta.destinigo.talktoastro.feature_call_consultation.data.api

import co.touchlab.kermit.Logger
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.core.data.CommonResponse
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_call_consultation.data.request.EndVoipCallRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.request.GetCallDurationRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.request.GetVoiceAgoraTokenRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.request.RequestCallDuration
import tta.destinigo.talktoastro.feature_call_consultation.data.request.StartVoiceCallRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallDurationData
import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallDurationResponse
import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallWaitingResponse
import tta.destinigo.talktoastro.feature_call_consultation.data.response.Data
import tta.destinigo.talktoastro.feature_call_consultation.data.response.start_voip_call.StartCallConsultationData
import tta.destinigo.talktoastro.feature_call_consultation.data.response.start_voip_call.StartCallConsultationResponse
import tta.destinigo.talktoastro.feature_call_consultation.data.response.voip_agora_token.VoipAgoraTokenData
import tta.destinigo.talktoastro.feature_call_consultation.data.response.voip_agora_token.VoipAgoraTokenResponse

class CallConsultationApi : KtorApi() {
    companion object {
        const val GET_CALL_DURATION_INFO = "call-duration-check"
        const val POST_ACTION_TO_CALL_PLACE = "book-call"

        //VoIP Call Api
        private const val VOIP_PREFIX = "voip/"
        const val VOIP_CALL_END_CONSULTATION = VOIP_PREFIX + "user-end-call"
        const val POST_AGORA_TOKEN_VOICE = VOIP_PREFIX + "get-user-agora-token"
        const val START_CALL_CONSULTATION = VOIP_PREFIX + "call"
    }

    /**
     * Retrieves an Agora token for voice calls.
     *
     * @param request The request object containing necessary information.
     * @return A Result object containing either the VoipAgoraTokenResponse on success or an exception on failure.
     */
    private suspend fun getVoiceCallAgoraToken(request: GetVoiceAgoraTokenRequest) = client.post {
        url(POST_AGORA_TOKEN_VOICE)
        setBody(request)
    }.body<VoipAgoraTokenResponse>()

    suspend fun getVoIPAgoraTokenApi(request: GetVoiceAgoraTokenRequest): Resources<VoipAgoraTokenData>{
        return withContext(Dispatchers.Default){
            try{
                val callPlaceResponse = getVoiceCallAgoraToken(request)

                if(callPlaceResponse.status.not()) {
                    return@withContext Resources.Error(callPlaceResponse.msg?: "Unexpected error")
                }

                return@withContext Resources.Success(callPlaceResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    private suspend fun startVoiceCallConsultation(request: StartVoiceCallRequest) = client.post {
        url(START_CALL_CONSULTATION)
        setBody(request)
    }.body<StartCallConsultationResponse>()

    suspend fun startVoIPCallApi(request: StartVoiceCallRequest): Resources<StartCallConsultationData>{
        return withContext(Dispatchers.Default){
            try{
                val callPlaceResponse = startVoiceCallConsultation(request)

                if(callPlaceResponse.status.not()) {
                    return@withContext Resources.Error(callPlaceResponse.msg?: "Unexpected error")
                }

                return@withContext Resources.Success(callPlaceResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    private suspend fun endVoipCallConsultation(request: EndVoipCallRequest) = client.post {
        url(VOIP_CALL_END_CONSULTATION)
        setBody(request)
    }.body<CommonResponse>()

    suspend fun endVoIPCallApi(request: EndVoipCallRequest): Resources<Boolean>{
        return withContext(Dispatchers.Default){
            try{
                val callPlaceResponse = endVoipCallConsultation(request)

                if(callPlaceResponse.status.not()) {
                    return@withContext Resources.Error(callPlaceResponse.msg)
                }

                return@withContext Resources.Success(true)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    /*Voip Call Implementation End Here*/

    /*Normal Call Api */
    private suspend fun callDurationInfoApi(request: RequestCallDuration)= client.post {
        url(GET_CALL_DURATION_INFO)
        setBody(request)
    }.body<CallDurationResponse>()

    private suspend fun placeCallApi(request: GetCallDurationRequest)= client.post{
        url(POST_ACTION_TO_CALL_PLACE)
        setBody(request)
    }.body<CallWaitingResponse>()

    suspend fun getCallDurationInfo(request: RequestCallDuration): Resources<CallDurationData> {
        return withContext(Dispatchers.Default) {
            try {
                val callDurationInfoResponse = callDurationInfoApi(request)

                if (callDurationInfoResponse.status.not()) {
                    Logger.d("Error state ${callDurationInfoResponse.message} ")
                    return@withContext Resources.Error(
                        callDurationInfoResponse.message ?: "Unexpected Error"
                    )
                }

                return@withContext Resources.Success(callDurationInfoResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun placeCall(request: GetCallDurationRequest): Resources<Data>{
        return withContext(Dispatchers.Default){
            try{
                val callPlaceResponse = placeCallApi(request)

                if(callPlaceResponse.status.not()) {
                    return@withContext Resources.Error(callPlaceResponse.message)
                }

                return@withContext Resources.Success(callPlaceResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}