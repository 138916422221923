package tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_concern

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.PlaceChatRequestBody
import tta.destinigo.talktoastro.feature_chat_consultation_new.domain.ChatConcernRepo
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_concern.ChatConcernEvent
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.AnswerRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.ConcernQuestionRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.GeoDetailsRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.concern_question.ConcernQuestions
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.geo_details.GeoName
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_concern.widget.RequestAnswerData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ChatConcernComponent(
    private val context: ComponentContext,
    val expertName: String,
    val expertImage: String,
    val expertSkills: String,
    val expertId: String,
    val chatDuration: String,
    val category: String,
    private val event: (ChatConcernEvent) -> Unit,

    ) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: ChatConcernRepo by inject()
    private val _state = MutableStateFlow<ChatConcernState>(ChatConcernState.InitialState)
    val state get() = _state.asStateFlow()

    private val _stateQuestion = MutableStateFlow<List<ConcernQuestions>?>(null)
    val stateQuestion get() = _stateQuestion.asStateFlow()

    private val _stateTown = MutableStateFlow<List<GeoName>?>(null)
    val stateTown get() = _stateTown.asStateFlow()


    fun onClosePage() = event.invoke(ChatConcernEvent.CloseThis)
    fun onSubmitConcern(answerData: List<RequestAnswerData>?) {
        coroutineScope.launch {
            submitConcernQuestion(answerData)
        }
    }

    fun onChangeTownInput(inputTown: String) = coroutineScope.launch {
        getGeoDetails(inputTown)
    }

    private fun startConsultation(chatId: String, userUid: String) = event.invoke(
        ChatConcernEvent.OnStartConsultation(
            chatId, userUid
        )
    )

    fun onRetry() {
        coroutineScope.launch {
            getConcernQuestion()
        }
    }

    init {

        lifecycle.doOnStart {
            coroutineScope.launch {
                getConcernQuestion()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private suspend fun onErrorState(msg: String) {
        Logger.d("component error-> $msg")
        _state.emit(ChatConcernState.OnError(msg))
    }

    private suspend fun onSuccess(data: List<ConcernQuestions>) {
        Logger.d("component success-> $data")
        _stateQuestion.emit(data)
    }

    private suspend fun onLoading(isLoading: Boolean) {
        Logger.d("component loading -> $isLoading")
        _state.emit(ChatConcernState.LoadingState(isLoading))
    }

    private suspend fun getConcernQuestion() {
        val request = ConcernQuestionRequest(
            category = category, type = null, expertId = null,
        )
        api.getChatConcernQuestions(request).collectLatest {
            when (it) {
                is Resources.Error -> onErrorState(it.msg ?: "Unexpected Error")
                is Resources.Loading -> onLoading(it.isLoading)
                is Resources.Success -> it.data?.let { question -> onSuccess(question) }
            }
        }
    }


    private suspend fun getGeoDetails(cityName: String) {
        val request = GeoDetailsRequest(
            cityName = cityName, maxRows = MAX_GEO_DETAILS
        )
        api.getGeoDetails(request).collectLatest {
            when (it) {
                is Resources.Success -> it.data?.let { geo ->
                    Logger.d("Geo Details -> $it")
                    _stateTown.emit(geo)
                }

                else -> Unit
            }
        }
    }

    private suspend fun submitConcernQuestion(answerData: List<RequestAnswerData>?) {

        val request = PlaceChatRequestBody(
            questionId = null,
            expertId = expertId,
            category = category,
            answers = if (answerData == null) null else AnswerRequest(answerData),
            flashOff = null,
            images = null,
            type = ""
        )

        api.placeWithChatConcern(request).collect {

            when (it) {
                is Resources.Error -> {
                    Logger.d("On Error -> ${it.msg}")
                    onErrorState(it.msg ?: "Unexpected Error")
                }

                is Resources.Loading -> {
                    Logger.d("On Loading -> ${it.isLoading}")
                    _state.emit(ChatConcernState.LoadingState(it.isLoading))
                }

                is Resources.Success -> {
                    Logger.d("on Success -> ${it.data}")
                    it.data?.let { d ->

                        event.invoke(ChatConcernEvent.ConnectToExpert(
                            token = d.agoraToken!!, uid = d.agoraUserId!!,
                            onError = { msg ->
                                coroutineScope.launch {
                                    onErrorState(msg)
                                }
                            },
                            onSuccess = {
                                startConsultation(
                                    chatId = d.chatId.toString(),
                                    userUid = d.agoraUserId
                                )
                            }
                        ))
                    }
                }
            }
        }
    }

    companion object {
        private const val MAX_GEO_DETAILS = "5"
    }

}