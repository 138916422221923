
package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import co.touchlab.kermit.Logger
import coil3.compose.AsyncImage
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.md_them_primary
import tta.destinigo.talktoastro.feature_winyway_wallet.data.CouponApplyListener
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.CheckCouponCodeRequest
import tta.destinigo.talktoastro.feature_winyway_wallet.data.response.RechargeInfoData
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.all_coupons_page.AllCouponComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.all_coupons_page.CouponScreenList
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout.RechargeCheckoutComponent
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_local_offer_24
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottomsheet.widget.BottomSheetContent
import tta.destinigo.talktoastro.feature_custom_order.custom_order_details.widget.EnrolConfirmationBottomSheet
import tta.destinigo.talktoastro.resources.ic_wallet
import kotlin.math.log

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun OrderSummary(
//    component: RechargeCheckoutComponent,
//    selectedAmount: Int,
//    rechargeInfoData: RechargeInfoData,
//    onPaymentClicked: () -> Unit = {},
//    context: AllCouponComponent
//) {
//
//    var isCouponApplied by remember { mutableStateOf(component.rechargeData?.cupon != null) }
//    val cashbackAmountConfirmation = component.cashbackAmount.collectAsState(initial = "").value
//    val isDialogOpen = remember { mutableStateOf(false) }
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
//    val coroutineScope = rememberCoroutineScope()
//    val data = component.state.value
//    val isSuccessFullyParches = component.isSuccessFullyParches.value
//    //val cashbackAmountConfirmation = "100"
//    BoxWithConstraints {
//        val maxWidthDp = maxWidth.value
//        val widthModifier = if (maxWidthDp > 700) {
//           // Modifier.width(700.dp).fillMaxHeight(0.8f)
//            Modifier.width(700.dp).fillMaxHeight()
//        } else {
//            Modifier.fillMaxWidth().fillMaxHeight()
//        }
//
//        Column(
//            modifier = widthModifier
//                .background(Color.White, shape = RoundedCornerShape(8.dp))
//                .padding(16.dp).fillMaxHeight().align(Alignment.Center)
//        ) {
//            OrderHeaderNew(
//                selectedAmount = selectedAmount,
//                component = component
//            )
//            Spacer(modifier = Modifier.height(16.dp))
//            HorizontalDivider()
//            Spacer(modifier = Modifier.height(16.dp))
//            CouponSection(component, component1 = context, isCouponApplied, txnId = rechargeInfoData.transationDetails.id.toString(),  setIsCouponApplied = { isCouponApplied = it })
//            Spacer(modifier = Modifier.height(16.dp))
//            //AppliedCoupon()
//            Spacer(modifier = Modifier.height(16.dp))
//            HorizontalDivider()
//            Spacer(modifier = Modifier.height(16.dp))
//            PaymentDetails(
//                component = component,
//                rechargeInfoData = rechargeInfoData,
//                isCouponApplied = isCouponApplied,
//                cashbackAmountConfirmation = (cashbackAmountConfirmation ?: 0).toString()
//            )
//            Spacer(modifier = Modifier.height(24.dp))
//            ProceedToPaymentButton(
//                onPaymentClicked = onPaymentClicked,
//                component = component,
//                onPaymentClickedConferm = { data ->
//                    isDialogOpen.value = data
//                }
//            )
//        }
//
//
//        // Check if the payment is successful and dialog should be shown
//        if (isSuccessFullyParches && isDialogOpen.value) {
//            // Use LaunchedEffect to handle when dialog/sheet needs to be shown
//            LaunchedEffect(isDialogOpen.value) {
//                if (windowSizeClass == WindowWidthSizeClass.Compact) {
//                    // Show ModalBottomSheet for compact screens
//                    coroutineScope.launch {
//                        sheetState.show()
//                    }
//                }
//            }
//
//            // Modal Bottom Sheet for Compact screens
//            if (windowSizeClass == WindowWidthSizeClass.Compact) {
//                ModalBottomSheet(
//                    sheetState = sheetState,
//                    containerColor = Color.White,
//                    onDismissRequest = {
//                        coroutineScope.launch {
//                            sheetState.hide()
//                        }
//                    }
//                ) {
//                    EnrolConfirmationBottomSheet()
//                }
//            }
//
//            // AlertDialog for larger screens (Non-Compact)
//            if (windowSizeClass != WindowWidthSizeClass.Compact) {
//                AlertDialog(
//                    containerColor = Color.White,
//                    onDismissRequest = {
//                        // Handle dismiss and close dialog
//                        isDialogOpen.value = false
//                    },
//                    text = {
//                        EnrolConfirmationBottomSheet()
//                    },
//                    shape = RoundedCornerShape(Scale.CORNER_SMALL),
//                    confirmButton = {},
//                    dismissButton = {}
//                )
//            }
//        }
//    }
//}
@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun OrderSummary(
    component: RechargeCheckoutComponent,
    selectedAmount: Int,
    rechargeInfoData: RechargeInfoData,
    onPaymentClicked: () -> Unit = {},
    context: AllCouponComponent
) {
    var isCouponApplied by remember { mutableStateOf(component.rechargeData?.cupon != null) }
    val cashbackAmountConfirmation = component.cashbackAmount.collectAsState(initial = "").value
    val isDialogOpen = remember { mutableStateOf(false) }
    val isLoading = remember { mutableStateOf(false) }
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    val data = component.state.value
    val isSuccessFullyParches = component.isSuccessFullyParches.value

//    LaunchedEffect(isSuccessFullyParches) {
//        if (isSuccessFullyParches) {
//            isDialogOpen.value = true
//            isLoading.value = false
//        }
//    }

    BoxWithConstraints {
        val maxWidthDp = maxWidth.value
        val widthModifier = if (maxWidthDp > 700) {
            Modifier.width(700.dp).fillMaxHeight()
        } else {
            Modifier.fillMaxWidth().fillMaxHeight()
        }

        // Main content
        Box(modifier = Modifier.fillMaxSize()) {
            Column(
                modifier = widthModifier
                    .background(Color.White, shape = RoundedCornerShape(8.dp))
                    .padding(16.dp).fillMaxHeight().align(Alignment.Center)
            ) {
                OrderHeaderNew(
                    selectedAmount = selectedAmount,
                    component = component
                )
                Spacer(modifier = Modifier.height(16.dp))
                HorizontalDivider()
                Spacer(modifier = Modifier.height(16.dp))
                CouponSection(
                    component,
                    component1 = context,
                    isCouponApplied,
                    txnId = rechargeInfoData.transationDetails.id.toString(),
                    setIsCouponApplied = { isCouponApplied = it }
                )
                Spacer(modifier = Modifier.height(16.dp))
                HorizontalDivider()
                Spacer(modifier = Modifier.height(16.dp))
                PaymentDetails(
                    component = component,
                    rechargeInfoData = rechargeInfoData,
                    isCouponApplied = isCouponApplied,
                    cashbackAmountConfirmation = (cashbackAmountConfirmation ?: 0).toString()
                )
                Spacer(modifier = Modifier.height(24.dp))
                ProceedToPaymentButton(
                    onPaymentClicked = {
                        onPaymentClicked()
                    },
                    component = component,
                    onPaymentClickedConfirm = { data ->
                       // onPaymentClicked()
                    }
                )
            }

        }
    }
}


//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun OrderSummary(
//    component: RechargeCheckoutComponent,
//    selectedAmount: Int,
//    rechargeInfoData: RechargeInfoData,
//    onPaymentClicked: () -> Unit = {},
//    context: AllCouponComponent
//) {
//
//    var isCouponApplied by remember { mutableStateOf(component.rechargeData?.cupon != null) }
//    val cashbackAmountConfirmation = component.cashbackAmount.collectAsState(initial = "").value
//    val isDialogOpen = remember { mutableStateOf(false) }
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
//    val coroutineScope = rememberCoroutineScope()
//    val data = component.state.value
//    val isSuccessFullyParches = component.isSuccessFullyParches.value
//
//    LaunchedEffect(isSuccessFullyParches) {
//        if (isSuccessFullyParches && !isDialogOpen.value) {
//            isDialogOpen.value = true
//        }
//    }
//
//    BoxWithConstraints {
//        val maxWidthDp = maxWidth.value
//        val widthModifier = if (maxWidthDp > 700) {
//            Modifier.width(700.dp).fillMaxHeight()
//        } else {
//            Modifier.fillMaxWidth().fillMaxHeight()
//        }
//
//        Column(
//            modifier = widthModifier
//                .background(Color.White, shape = RoundedCornerShape(8.dp))
//                .padding(16.dp).fillMaxHeight().align(Alignment.Center)
//        ) {
//            OrderHeaderNew(
//                selectedAmount = selectedAmount,
//                component = component
//            )
//            Spacer(modifier = Modifier.height(16.dp))
//            HorizontalDivider()
//            Spacer(modifier = Modifier.height(16.dp))
//            CouponSection(component, component1 = context, isCouponApplied, txnId = rechargeInfoData.transationDetails.id.toString(), setIsCouponApplied = { isCouponApplied = it })
//            Spacer(modifier = Modifier.height(16.dp))
//            Spacer(modifier = Modifier.height(16.dp))
//            HorizontalDivider()
//            Spacer(modifier = Modifier.height(16.dp))
//            PaymentDetails(
//                component = component,
//                rechargeInfoData = rechargeInfoData,
//                isCouponApplied = isCouponApplied,
//                cashbackAmountConfirmation = (cashbackAmountConfirmation ?: 0).toString()
//            )
//            Spacer(modifier = Modifier.height(24.dp))
//            ProceedToPaymentButton(
//                onPaymentClicked = onPaymentClicked,
//                component = component,
//                onPaymentClickedConferm = { data ->
//                    isDialogOpen.value = data
//                }
//            )
//        }
//
//        // Show ModalBottomSheet for Compact screens
//        if (isDialogOpen.value) {
//            if (windowSizeClass == WindowWidthSizeClass.Compact) {
//                ModalBottomSheet(
//                    sheetState = sheetState,
//                    containerColor = Color.White,
//                    onDismissRequest = {
//                        coroutineScope.launch {
//                            sheetState.hide()
//                        }
//                        isDialogOpen.value = false
//                    }
//                ) {
//                    EnrolConfirmationBottomSheet()
//                }
//            }
//
//            // Show AlertDialog for larger screens
//            if (windowSizeClass != WindowWidthSizeClass.Compact) {
//                AlertDialog(
//                    containerColor = Color.White,
//                    onDismissRequest = {
//                        isDialogOpen.value = false
//                    },
//                    text = {
//                        EnrolConfirmationBottomSheet()
//                    },
//                    shape = RoundedCornerShape(Scale.CORNER_SMALL),
//                    confirmButton = {},
//                    dismissButton = {}
//                )
//            }
//        }
//    }
//}


@Composable
fun OrderHeaderNew(
    selectedAmount: Int,
    component: RechargeCheckoutComponent,
    ) {

    Row(verticalAlignment = Alignment.CenterVertically) {

        if (component.isCustomOrder) {
            AsyncImage(
                model = component.image,
                contentDescription = null,
                modifier = Modifier.size(50.dp),
                contentScale = ContentScale.Crop
            )
        } else {
            Image(
                painter = painterResource(Res.drawable.ic_wallet),
                contentDescription = null
            )
        }

        Spacer(modifier = Modifier.width(16.dp))
        Column(modifier = Modifier.weight(1f)) {
            Text("Order #1", fontWeight = FontWeight.Bold)

            Text(
                text = if (component.isCustomOrder) {
                    "Custom Order"
                } else {
                    "Wallet Recharge"
                },
                fontSize = 14.sp,
                fontWeight = FontWeight.Medium
            )

            Text(
                text = if (component.isCustomOrder) {
                    component.heading
                } else {
                    "Selected Wallet Recharge Amount"
                },
                fontSize = 14.sp,
                fontWeight = FontWeight.Medium
            )
        }
        Text("${initializeCurrency()} $selectedAmount", fontWeight = FontWeight.Bold, fontSize = 16.sp)
    }
}

//@OptIn(ExperimentalMaterial3Api::class)
//@Composable
//fun CouponSection(
//    component: RechargeCheckoutComponent,
//    component1: AllCouponComponent,
//    isCouponApplied: Boolean,
//    txnId:String,
//    setIsCouponApplied: (Boolean) -> Unit
//) {
//    val couponApplyConfirmation = component.customCoupon.collectAsState(initial = null).value
//    val couponRemoveConfirmation = component.customCouponRemove.collectAsState(initial = null).value
//    var invalideCoupon:Boolean = false
//    var userInputcouponCode by remember { mutableStateOf("") }
//    var couponError by remember { mutableStateOf("") }
//    var onShowAllCouponClick by remember { mutableStateOf(false) }
//
//    Column(modifier = Modifier.padding(16.dp)) {
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//            modifier = Modifier.fillMaxWidth()
//        ) {
//            Icon(imageVector = Icons.Default.Info, contentDescription = null)
//            Spacer(modifier = Modifier.width(8.dp))
//            Text(
//                "Do you have a coupon?",
//                style = MaterialTheme.typography.titleMedium,
//                modifier = Modifier.weight(1f)
//            )
//            Text(
//                "All Coupons",
//                color = Color.Blue,
//                modifier = Modifier
//                    .padding(start = 8.dp)
//                    .clickable {
//                        //component.getAllCouponCode()
////                        component.onShowAllCouponPress()
//                        if (onShowAllCouponClick) {
//                            onShowAllCouponClick = false
//                        }
//                        onShowAllCouponClick = true
//
//                    }
//            )
//        }
//        val listener = object : CouponApplyListener {
//            override fun onCouponApplied(couponCode: String) {
//                userInputcouponCode = couponCode
//                Logger.d { "CouponSection in Recgecheckout  $couponCode" }
//            }
//        }
//
//        if(onShowAllCouponClick){
//            CouponScreenList(component1,listener, onDismiss = {onShowAllCouponClick = false})
//        }
////        state.let {
////            Logger.d { }
////        }
//
//        Spacer(modifier = Modifier.height(8.dp))
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//            modifier = Modifier.fillMaxWidth()
//        ) {
//            val textFieldHeight = 56.dp
//
//            OutlinedTextField(
//                value = userInputcouponCode,
//                onValueChange = { newValue ->
//                    userInputcouponCode = newValue
//                    couponError = ""
//                },
//                placeholder = { Text("Add Coupon", color = Color.Gray, textAlign = TextAlign.Center) },
//                modifier = Modifier
//                    .weight(1f)
//                    .height(textFieldHeight)
//                    .padding(0.dp),
//                leadingIcon = {
//                    Image(
//                        painter = painterResource(Res.drawable.baseline_local_offer_24),
//                        contentDescription = null,
//                        colorFilter = ColorFilter.tint(Color.Black)
//                    )
//                },
//                textStyle = TextStyle(fontSize = 14.sp),
//                colors = TextFieldDefaults.outlinedTextFieldColors(
//                    focusedBorderColor = Color.Gray,
//                    unfocusedBorderColor = Color.LightGray,
//                )
//            )
//            Spacer(modifier = Modifier.width(8.dp))
//
//            Button(
//                onClick = {
//                    if (userInputcouponCode.isNotEmpty()) {
//                        component.checkCouponCode(
//                            CheckCouponCodeRequest(txnId = txnId, couponId = userInputcouponCode)
//                        )
////                        val isValid = validateCoupon(couponCode)
//                        if (invalideCoupon) {
//                            setIsCouponApplied(true)
//                            couponError = ""
//                        } else {
//                            couponError = "Invalid coupon code"
//                        }
//                    } else {
//                        couponError = "Please enter a coupon code"
//                    }
//                },
//                colors = ButtonDefaults.buttonColors(MaterialTheme.colorScheme.primary),
//                shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
//                elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_SMALL),
//                modifier = Modifier.height(textFieldHeight)
//            ) {
//                Text("Apply")
//            }
//        }
//
//        if (couponError.isNotEmpty()) {
//            Text(
//                text = couponError,
//                color = Color.Red,
//                style = MaterialTheme.typography.bodySmall,
//                modifier = Modifier.padding(top = 4.dp)
//            )
//        }
//        Spacer(modifier = Modifier.height(8.dp))
//
//        if (isCouponApplied || couponApplyConfirmation == true) {
//            AppliedCoupon(couponCode = component.rechargeData?.cupon?.couponCode ?: userInputcouponCode, onRemoveCoupon = {
//                component.rechargeData?.cupon?.couponCode.let { couponCode ->
//                    component.cancelCouponCode(
//                        CheckCouponCodeRequest(
//                            couponId = couponCode ?: userInputcouponCode ,
//                            txnId = txnId
//                        )
//                    )
//                }
//                setIsCouponApplied(false)
//            })
//        }else{
//            invalideCoupon = true
//        }
//
//    }
//}

// Sample validation function (to be implemented as needed)
//private fun validateCoupon(code: String): Boolean {
//
//    // Logic to validate the coupon
//    //return code == "VALIDCOUPON" // Replace with actual validation logic
//}


@Composable
fun CouponSection(
    component: RechargeCheckoutComponent,
    component1: AllCouponComponent,
    isCouponApplied: Boolean,
    txnId: String,
    setIsCouponApplied: (Boolean) -> Unit
) {
    val couponApplyConfirmation = component.customCoupon.collectAsState(initial = null).value
    val couponRemoveConfirmation = component.customCouponRemove.collectAsState(initial = null).value
    var userInputCouponCode by remember { mutableStateOf("") }
    var couponError by remember { mutableStateOf("") }
    var onShowAllCouponClick by remember { mutableStateOf(false) }

    Column(modifier = Modifier.padding(16.dp)) {
        CouponHeader(onShowAllCouponClick) {
            onShowAllCouponClick = !onShowAllCouponClick
        }

        if (onShowAllCouponClick) {
            val listener = object : CouponApplyListener {
                override fun onCouponApplied(couponCode: String) {
                    userInputCouponCode = couponCode
                }
            }
            CouponScreenList(component1, listener) { onShowAllCouponClick = false }
        }

        Spacer(modifier = Modifier.height(8.dp))

        CouponInputField(
            userInputCouponCode = userInputCouponCode,
            onCouponCodeChange = { newValue ->
                userInputCouponCode = newValue
                couponError = ""
            },
            onApplyCoupon = {
                if (userInputCouponCode.isNotEmpty()) {
                    component.checkCouponCode(
                        CheckCouponCodeRequest(txnId = txnId, couponId = userInputCouponCode)
                    )
                } else {
                    couponError = "Please enter a coupon code"
                }
            }
        )

        if(couponApplyConfirmation == false){
            Text(
                text = "Invalide Coupon Code",
                color = Color.Red,
                style = MaterialTheme.typography.bodySmall,
                modifier = Modifier.padding(top = 4.dp)
            )
        }
        if (couponError.isNotEmpty()) {
            Text(
                text = couponError,
                color = Color.Red,
                style = MaterialTheme.typography.bodySmall,
                modifier = Modifier.padding(top = 4.dp)
            )
        }

        Spacer(modifier = Modifier.height(8.dp))

        if (couponRemoveConfirmation != true) {
            if (isCouponApplied || (couponApplyConfirmation == true && !isCouponApplied)) {
                AppliedCoupon(
                    couponCode = component.rechargeData?.cupon?.couponCode ?: userInputCouponCode,
                    onRemoveCoupon = {
                        component.cancelCouponCode(
                            CheckCouponCodeRequest(
                                couponId = component.rechargeData?.cupon?.couponCode ?: userInputCouponCode,
                                txnId = txnId
                            )
                        )
                        setIsCouponApplied(false)
                    }
                )
            }
        }

//        val effectiveCouponCode = when {
//            //isCouponApplied -> userInputCouponCode
//            couponApplyConfirmation == true && !isCouponApplied -> userInputCouponCode
//            else -> component.rechargeData?.cupon?.couponCode ?: userInputCouponCode
//        }
//        if (couponRemoveConfirmation != true && (isCouponApplied || (couponApplyConfirmation == true && !isCouponApplied))) {
//            AppliedCoupon(
//                couponCode = effectiveCouponCode.toString(),
//                onRemoveCoupon = {
//                    val couponId = component.rechargeData?.cupon?.couponCode ?: userInputCouponCode
//                    component.cancelCouponCode(
//                        CheckCouponCodeRequest(
//                            couponId = couponId,
//                            txnId = txnId
//                        )
//                    )
//                    setIsCouponApplied(false)
//                }
//            )
//        }

    }
}

//@Composable
//fun CouponSection(
//    component: RechargeCheckoutComponent,
//    component1: AllCouponComponent,
//    isCouponApplied: Boolean,
//    txnId: String,
//    setIsCouponApplied: (Boolean) -> Unit
//) {
//    val couponApplyConfirmation = component.customCoupon.collectAsState(initial = null).value
//    val couponRemoveConfirmation = component.customCouponRemove.collectAsState(initial = null).value
//    var userInputCouponCode by remember { mutableStateOf("") }
//    var couponError by remember { mutableStateOf("") }
//    var onShowAllCouponClick by remember { mutableStateOf(false) }
//    var effectiveCouponCode by remember { mutableStateOf(component.rechargeData?.cupon?.couponCode ?: "") } // State for the effective coupon code
//
//    // Apply the coupon automatically if it exists
//    LaunchedEffect(couponApplyConfirmation) {
//        if (component.rechargeData?.cupon?.couponCode != null && couponApplyConfirmation == true) {
//            effectiveCouponCode = component.rechargeData!!.cupon?.couponCode.toString()
//            setIsCouponApplied(true)
//        }
//    }
//
//    Column(modifier = Modifier.padding(16.dp)) {
//        CouponHeader(onShowAllCouponClick) {
//            onShowAllCouponClick = !onShowAllCouponClick
//        }
//
//        // Show all coupons if the toggle is enabled
//        if (onShowAllCouponClick) {
//            val listener = object : CouponApplyListener {
//                override fun onCouponApplied(couponCode: String) {
//                    userInputCouponCode = couponCode
//                }
//            }
//            CouponScreenList(component1, listener) { onShowAllCouponClick = false }
//        }
//
//        Spacer(modifier = Modifier.height(8.dp))
//
//        // Input field for coupon code
//        CouponInputField(
//            userInputCouponCode = userInputCouponCode,
//            onCouponCodeChange = { newValue ->
//                userInputCouponCode = newValue
//                couponError = ""
//            },
//            onApplyCoupon = {
//                if (userInputCouponCode.isNotEmpty()) {
//                    if (isCouponApplied) {
//                        val previousCouponId = component.rechargeData?.cupon?.couponCode
//                        if (previousCouponId != null) {
//                            component.cancelCouponCode(
//                                CheckCouponCodeRequest(couponId = previousCouponId, txnId = txnId)
//                            )
//                        }
//                    }
//                    component.checkCouponCode(
//                        CheckCouponCodeRequest(txnId = txnId, couponId = userInputCouponCode)
//                    )
//                } else {
//                    couponError = "Please enter a coupon code"
//                }
//            }
//        )
//
//        // Display error messages
//        if (couponApplyConfirmation == false) {
//            Text(
//                text = "Invalid Coupon Code",
//                color = Color.Red,
//                style = MaterialTheme.typography.bodySmall,
//                modifier = Modifier.padding(top = 4.dp)
//            )
//        }
//        if (couponError.isNotEmpty()) {
//            Text(
//                text = couponError,
//                color = Color.Red,
//                style = MaterialTheme.typography.bodySmall,
//                modifier = Modifier.padding(top = 4.dp)
//            )
//        }
//
//        Spacer(modifier = Modifier.height(8.dp))
//
//        // Display applied coupon if applicable
//        if (couponRemoveConfirmation != true && (isCouponApplied || couponApplyConfirmation == true)) {
//            AppliedCoupon(
//                couponCode = effectiveCouponCode,
//                onRemoveCoupon = {
//                    val couponId = component.rechargeData?.cupon?.couponCode ?: userInputCouponCode
//                    component.cancelCouponCode(
//                        CheckCouponCodeRequest(couponId = couponId, txnId = txnId)
//                    )
//                    setIsCouponApplied(false)
//                    effectiveCouponCode = "" // Clear the effective coupon code when removed
//                }
//            )
//        }
//    }
//}




@Composable
fun CouponHeader(
    onShowAllCouponClick: Boolean,
    onShowAllCouponToggle: () -> Unit
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.fillMaxWidth()
    ) {
        Icon(imageVector = Icons.Default.Info, contentDescription = null)
        Spacer(modifier = Modifier.width(8.dp))
        Text(
            "Do you have a coupon?",
            style = MaterialTheme.typography.titleMedium,
            modifier = Modifier.weight(1f)
        )
        Text(
            "All Coupons",
            color = primaryColor,
            modifier = Modifier
                .padding(start = 8.dp)
                .clickable { onShowAllCouponToggle() }
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CouponInputField(
    userInputCouponCode: String,
    onCouponCodeChange: (String) -> Unit,
    onApplyCoupon: () -> Unit
) {
    val textFieldHeight = 56.dp

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.fillMaxWidth()
    ) {
        OutlinedTextField(
            value = userInputCouponCode,
            onValueChange = onCouponCodeChange,
            placeholder = { Text("Add Coupon", color = Color.Gray, textAlign = TextAlign.Center) },
            modifier = Modifier
                .weight(1f)
                .height(textFieldHeight)
                .padding(0.dp),
            leadingIcon = {
                Image(
                    painter = painterResource(Res.drawable.baseline_local_offer_24),
                    contentDescription = null,
                    colorFilter = ColorFilter.tint(Color.Black)
                )
            },
            textStyle = TextStyle(fontSize = 14.sp),
            colors = TextFieldDefaults.outlinedTextFieldColors(
                focusedBorderColor = Color.Gray,
                unfocusedBorderColor = Color.LightGray,
            )
        )
        Spacer(modifier = Modifier.width(8.dp))

        Button(
            onClick = onApplyCoupon,
            colors = ButtonDefaults.buttonColors(md_them_primary),
            shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
            elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_SMALL),
            modifier = Modifier.height(textFieldHeight)
        ) {
            Text("Apply")
        }
    }
}




@Composable
fun AppliedCoupon(couponCode:String ,onRemoveCoupon: () -> Unit ) {
    Column(
        modifier = Modifier
            .wrapContentSize()
            .background(Color(0xFFDFF5D9), shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL))
            .padding(8.dp)
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(4.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = "$couponCode - Applied",
                fontWeight = FontWeight.Bold,
                fontSize = 16.sp,
                color = Color(0xFF388E3C),
                modifier = Modifier.weight(1f)
            )
            Text(
                text = "Remove",
                color = Color.Red,
                fontSize = 14.sp,
                modifier = Modifier
                    .padding(start = 8.dp)
                    .clickable { onRemoveCoupon() }
            )
        }

        Spacer(modifier = Modifier.height(2.dp))

        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(start = 8.dp)
        ) {
            Icon(
                imageVector = Icons.Default.CheckCircle,
                contentDescription = "Coupon applied",
                tint = Color(0xFF388E3C), // Green check icon
                modifier = Modifier.size(20.dp)
            )
            Spacer(modifier = Modifier.width(4.dp))
            Text(
                text = "Coupon applied successfully",
                fontSize = 14.sp,
                color = Color.Gray
            )
        }

       // Spacer(modifier = Modifier.height(4.dp))

//        Text(
//            text = "You will get 300 cashback after recharge.",
//            fontSize = 12.sp,
//            color = Color.Gray,
//            modifier = Modifier.padding(start = 8.dp)
//        )
    }
}


@Composable
fun PaymentDetails(
    component: RechargeCheckoutComponent,
    rechargeInfoData: RechargeInfoData,
    isCouponApplied: Boolean,
    cashbackAmountConfirmation: String? = ""
) {
    Column {
        Row(verticalAlignment = Alignment.CenterVertically) {
//            Icon(imageVector = Icons.Default.Face, contentDescription = null)
//            Spacer(modifier = Modifier.width(8.dp))
            Text("Payment Details:", fontWeight = FontWeight.Bold)
        }
        Spacer(modifier = Modifier.height(8.dp))
        PaymentDetailRow(label = "Amount", value = "${initializeCurrency()}${rechargeInfoData.referbalance.amount?: 0}")
        PaymentDetailRow(
            label = "Service Tax (18% GST)",
            value = "${initializeCurrency()}${rechargeInfoData.referbalance.gstPercentageAmount ?: 0}"
        )
        PaymentDetailRow(
            label = "Coin Applied Discount",
            value = "- ${initializeCurrency()}${rechargeInfoData.referbalance.coinPercentageValue ?: 0}",
            isDiscount = true
        )

        HorizontalDivider(modifier = Modifier.padding(vertical = 8.dp))

        PaymentDetailRow(
            label = "Wallet Balance Used",
            value = "- ${initializeCurrency()}${rechargeInfoData.referbalance.walletAmountUsed ?: 0}",
            isTotal = true
        )
//        PaymentDetailRow(label = "Wallet Used", value = "- ₹180", isDiscount = true)
       // HorizontalDivider(modifier = Modifier.padding(vertical = 8.dp))

        PaymentDetailRow(
            label = "Total amount to be paid",
            value = "${initializeCurrency()}${rechargeInfoData.referbalance.cashAmount ?: 0}",
            isTotal = true
        )
        val amount = rechargeInfoData.referbalance.amount
        val persentage = if(component.rechargeData?.cupon != null)component.rechargeData?.cupon?.cashbackPercent else 0
       // Logger.d { "$amount $persentage ${calculateCashback(amount?.toDouble()!!,persentage?.toDouble()!!)}" }
        if(isCouponApplied || cashbackAmountConfirmation?.isBlank() == false){
            PaymentDetailRow(
                label = "Cashback Value",
                value = "${initializeCurrency()}${cashbackAmountConfirmation ?: rechargeInfoData.transationDetails.cashbackValue?: calculateCashback(amount?.toDouble()!!,persentage?.toDouble()!!) ?: 0}",
                isTotal = true
            )
        }
    }
}

fun calculateCashback(amount: Double, percentage: Double): Double {
    return amount * (percentage / 100)
}

@Composable
fun PaymentDetailRow(
    label: String,
    value: String,
    isDiscount: Boolean = false,
    isTotal: Boolean = false
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = label,
            fontSize = 14.sp,
            color = if (isTotal) Color.Black else Color.Gray,
            fontWeight = if (isTotal) FontWeight.Bold else FontWeight.Normal
        )
        Text(
            text = value,
            fontSize = 14.sp,
            color = when {
                isTotal -> Color.Black
                isDiscount -> Color(0xFF4CAF50) // Green color for discounts
                else -> Color.Black
            },
            fontWeight = if (isTotal) FontWeight.Bold else FontWeight.Normal
        )
    }
}

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun ProceedToPaymentButton(
//    component: RechargeCheckoutComponent,
//    onPaymentClicked: () -> Unit
//) {
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val isDialogOpen = MutableStateFlow<Boolean>(false)
//    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
//    val coroutineScope = rememberCoroutineScope()
//    val data = component.state.value
//    val isSuccessFullyParches = component.isSuccessFullyParches.value
//    Button(
//        onClick = {
//            Logger.d { "PROCEED TO PAYMENT Click ${data?.transationDetails}" }
//            Logger.d { "PROCEED TO PAYMENT Click ${data?.referbalance}" }
//            isDialogOpen.value = true
//            if(component.isCustomOrder){
//                if(data?.referbalance?.totalAmount?.toDouble()!! <= data.referbalance.walletAmountUsed?.toDouble()!!){
//                    //showToast("No Need to go Payment Gate way")
//                    component.callEnrollCustomOrder()
//                }else{
//                    onPaymentClicked.invoke()
//                }
//            }else{
//                onPaymentClicked.invoke()
//            }
//
//            //onPaymentClicked.invoke()
//                  },
//        modifier = Modifier
//            .fillMaxWidth()
//            .padding(horizontal = 16.dp),
//        colors = ButtonDefaults.buttonColors(md_them_primary),
//        shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
//        elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_MEDIUM),
//    ) {
//        Text("PROCEED TO PAYMENT")
//    }
//
//
//
//    if(isSuccessFullyParches && isDialogOpen.value){
//        if (windowSizeClass == WindowWidthSizeClass.Compact) {
//            ModalBottomSheet(
//                sheetState = sheetState,
//                containerColor = Color.White,
//                onDismissRequest = {
//                    coroutineScope.launch {
//                        sheetState.hide()
//                    }
//                }
//            ) {
//                EnrolConfirmationBottomSheet()
//            }
//        }else{
//            AlertDialog(
//                containerColor = Color.White,
//                onDismissRequest = {
//                    //consultationComponent.onClosePage()
////                    onDismiss.invoke()
//                },
//                text = {
//                    EnrolConfirmationBottomSheet()
//                },
//                shape = RoundedCornerShape(Scale.CORNER_SMALL),
//                confirmButton = {},
//                dismissButton = {}
//            )
//        }
//    }
//}

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun ProceedToPaymentButton(
//    component: RechargeCheckoutComponent,
//    onPaymentClicked: () -> Unit
//) {
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val isDialogOpen = remember { mutableStateOf(false) }  // Use remember for local state
//    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
//    val coroutineScope = rememberCoroutineScope()
//    val data = component.state.value
//    val isSuccessFullyParches = component.isSuccessFullyParches.value
//
//    Button(
//        onClick = {
//            Logger.d { "PROCEED TO PAYMENT Click ${data?.transationDetails}" }
//            Logger.d { "PROCEED TO PAYMENT Click ${data?.referbalance}" }
//            isDialogOpen.value = true
//
//            if (component.isCustomOrder) {
//                if (data?.referbalance?.totalAmount?.toDouble()!! <= data.referbalance.walletAmountUsed?.toDouble()!!) {
//                    component.callEnrollCustomOrder()
//                } else {
//                    onPaymentClicked.invoke()
//                }
//            } else {
//                onPaymentClicked.invoke()
//            }
//        },
//        modifier = Modifier
//            .fillMaxWidth()
//            .padding(horizontal = 16.dp),
//        colors = ButtonDefaults.buttonColors(md_them_primary),
//        shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
//        elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_MEDIUM),
//    ) {
//        Text("PROCEED TO PAYMENT")
//    }
//
//    // Check if the payment is successful and dialog should be shown
//    if (isSuccessFullyParches && isDialogOpen.value) {
//        if (windowSizeClass == WindowWidthSizeClass.Compact) {
//            ModalBottomSheet(
//                sheetState = sheetState,
//                containerColor = Color.White,
//                onDismissRequest = {
//                    coroutineScope.launch {
//                        sheetState.hide()
//                    }
//                }
//            ) {
//                EnrolConfirmationBottomSheet()
//            }
//        } else {
//            AlertDialog(
//                containerColor = Color.White,
//                onDismissRequest = {
//                    // Add dismiss logic if needed
//                },
//                text = {
//                    EnrolConfirmationBottomSheet()
//                },
//                shape = RoundedCornerShape(Scale.CORNER_SMALL),
//                confirmButton = {},
//                dismissButton = {}
//            )
//        }
//    }
//}


@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ProceedToPaymentButton(
    component: RechargeCheckoutComponent,
    onPaymentClicked: () -> Unit,
    onPaymentClickedConfirm: (Boolean) -> Unit,
) {
    val data = component.state.value

    Button(
        onClick = {
            Logger.d { "PROCEED TO PAYMENT Click ${data?.transationDetails}" }
            Logger.d { "PROCEED TO PAYMENT Click ${data?.referbalance}" }
            if (component.isCustomOrder) {
                if (data?.referbalance?.totalAmount?.toDouble()!! <= data.referbalance.walletAmountUsed?.toDouble()!! || data.referbalance.totalAmount.equals("0", ignoreCase = true) ) {
                    component.callEnrollCustomOrder()
                    onPaymentClickedConfirm(true)
                } else {
                    onPaymentClicked.invoke()
                }
            } else {
                onPaymentClicked.invoke()
            }
        },
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp),
        colors = ButtonDefaults.buttonColors(md_them_primary),
        shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
        elevation = ButtonDefaults.elevatedButtonElevation(Scale.ELEVATION_MEDIUM),
    ) {
        Text("PROCEED TO PAYMENT")
    }
}
