package tta.destinigo.talktoastro.feature_call_consultation.domain

import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_call_consultation.data.api.CallConsultationApi
import tta.destinigo.talktoastro.feature_call_consultation.data.request.EndVoipCallRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.request.GetVoiceAgoraTokenRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.request.StartVoiceCallRequest
import tta.destinigo.talktoastro.feature_call_consultation.data.response.start_voip_call.StartCallConsultationData
import tta.destinigo.talktoastro.feature_call_consultation.data.response.voip_agora_token.VoipAgoraTokenData
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class VoIPCallRepo(
    private val api: CallConsultationApi
) {

    /**
     * Retrieves a VoIP Agora token.
     *
     * @param request The request object containing information needed to fetch the token.
     * @return A Flow that emits the following [Resources] states:
     *   * [Resources.Loading] when the request is initiated.
     *   * [Resources.Success] with the [VoipAgoraTokenData] if the request is successful.
     *   * [Resources.Error] with an error message if the request fails.
     *   * [Resources.Loading] with `isLoading = false` when the request is completed.
     */
    fun getVoipAgoraToken(request: GetVoiceAgoraTokenRequest): Flow<Resources<VoipAgoraTokenData>> = flow {
        emit(Resources.Loading())
        val response = api.getVoIPAgoraTokenApi(request)
        emit(response)
        emit(Resources.Loading(false))
    }

    /**
     * Starts a VoIP call consultation.
     *
     * @param request The request object containing information to initiate the call.
     * @return A Flow that emits the following [Resources] states:
     *   * [Resources.Loading] when the request is initiated.
     *   * [Resources.Success] with the [StartCallConsultationData] if the request is successful.
     *   * [Resources.Error] with an error message if the request fails.
     *   * [Resources.Loading] with `isLoading = false` when the request is completed.
     */
    fun startVoipCall(request: StartVoiceCallRequest): Flow<Resources<StartCallConsultationData>> = flow {
        emit(Resources.Loading())
        val response = api.startVoIPCallApi(request)
        emit(response)
        emit(Resources.Loading(false))
    }

    /**
     * Ends a VoIP call.
     *
     * @param request The request object containing information to end the call.
     * @return A Flow that emits the following [Resources] states:
     *   * [Resources.Loading] when the request is initiated.
     *   * [Resources.Success] with a boolean value (true if successful) if the request is successful.
     *   * [Resources.Error] with an error message if the request fails.
     *   * [Resources.Loading] with `isLoading = false` when the request is completed.
     */
    fun endVoipCall(request: EndVoipCallRequest): Flow<Resources<Boolean>> = flow {
        emit(Resources.Loading())
        val response = api.endVoIPCallApi(request)
        emit(response)
        emit(Resources.Loading(false))
    }
}