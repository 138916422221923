package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TransactionOrderData(
    @SerialName("amount_with_gst")
    val amountWithGst: String? = null,
    @SerialName("call_duration")
    val callDuration: String? = null,
    @SerialName("created_at")
    val createdAt: String? = null,
    @SerialName("id")
    val id: Int? = null,
    @SerialName("paid_amount")
    val paidAmount: String? = null,
    @SerialName("payment_method")
    val paymentMethod: String? = null,
    @SerialName("payment_mode")
    val paymentMode: String? = null,
    @SerialName("payment_status")
    val paymentStatus: String? = null,
    @SerialName("plan_id")
    val planId: Int? = null,
    @SerialName("plan_name")
    val planName: String? = null,
    @SerialName("redeem_coins")
    val redeemCoins: String? = null,
    @SerialName("total_amount")
    val totalAmount: String? = null,
    @SerialName("txn_id")
    val txnId: String? = null,
    @SerialName("type")
    val type: String? = null,
    @SerialName("updated_at")
    val updatedAt: String? = null,
    @SerialName("used_coin")
    val usedCoin: Int? = null,
    @SerialName("used_coin_balance")
    val usedCoinBalance: String? = null,
    @SerialName("user_id")
    val userId: Int? = null,
    @SerialName("wallet_balance")
    val walletBalance: String? = null,
    @SerialName("call_details")
    val callDetails: CallDetailsData? = null,
    @SerialName("chat_details")
    val chatDetails: ChatDetailsData? = null,
    @SerialName("event")
    val event: String? = null,
    @SerialName("refund_amount")
    val refundAmount: String? = null,
    @SerialName("remarks")
    val remarks: String? = null,
    @SerialName("emergency_price")
    val emergencyPrice: String? = null,
    @SerialName("emergency_call_details")
    val emergency_call_details: EmergencyCallDetails? = null,
    @SerialName("emergency_chat_details")
    val emergency_chat_details: EmergencyChatDetails? = null,
    @SerialName("recommendations")
    val recommendations: List<ExpertRecommendItem>? = null,
    @SerialName("per_min_price")
    val emergency_per_min_price: String? = null,
    @SerialName("voip_call_details") val voipCallDetails: VoipCallDetails?
)
