package tta.destinigo.talktoastro.feature_expert_notify.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ExpertAvailableRequest(
    @SerialName ("expert_id") val expertId:String,
    @SerialName ("domain") val Domain:String,
    @SerialName ("platformtype") val PlatefromType:String
)
