package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.domain.TransactionInfoRepo
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.remote.TransactionHistoryApiService
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class TransactionHistoryComponent(
    context: ComponentContext,
    private  val onBackButtonClick:() -> Unit
):ComponentContext by context,KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val api: TransactionInfoRepo by inject()
    private val _state = MutableStateFlow<TransactionHistoryState>(TransactionHistoryState.Loading(false))
    val state get()  = _state.asStateFlow()
    private val api1: TransactionHistoryApiService by inject()

    init {
        lifecycle.doOnStart {
            coroutineScope.launch {
                Logger.d { "TransactionHistoryComponent on start" }
                getTransactionDetails()
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }
    }

    fun event(event:TransactionHistoryEvent){
        when(event){
            TransactionHistoryEvent.onBackButtonClick -> onBackButtonClick.invoke()
        }
    }

//    suspend fun onSuccess(data: List<TransactionOrderData>?) = coroutineScope.launch{
//        _state.emit(TransactionHistoryState.TransactionInfoSuccess(data!!))
//    }

    suspend fun onSuccess(data: List<TransactionOrderData>?) = coroutineScope.launch{
        _state.emit(TransactionHistoryState.TransactionInfoSuccess(data!!))
    }

    private suspend fun getTransactionDetails() {
        // Emit loading state before making the API call
        _state.emit(TransactionHistoryState.Loading(true))

        // Collect data from the API call
        api.getTransactionHistoryInfo().collect { result ->
            when (result) {
                is Resources.Error -> {
                    // Emit error state
                    _state.emit(TransactionHistoryState.Error(result.msg ?: "Unknown Error"))
                    Logger.d { "getTransactionDetails Error ${result.msg}" }
                }
                is Resources.Loading -> {
                    // Emit loading state only when isLoading is true, prevent redundant emissions
                    if (result.isLoading) {
                        _state.emit(TransactionHistoryState.Loading(result.isLoading))
                        Logger.d { "getTransactionDetails loading ${result.isLoading}" }
                    }
                }
                is Resources.Success -> {
                    onSuccess(result.data)

//                    result.data?.let { data ->
//                        // Emit success state with the data
//                        onSuccess(data)
//                        Logger.d { "getTransactionDetails Success ${data.size}" }
//                    } ?: run {
//                        // Handle null data case
//                        _state.emit(TransactionHistoryState.Error("No Data Found"))
//                        Logger.d { "getTransactionDetails Success but data is null" }
//                    }
                }
            }
        }
    }

}