package tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Scaffold
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import kotlinx.coroutines.flow.distinctUntilChanged
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.purchase_history.widget.PurchaseHistoryCard

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun PurchaseHistory(component: PurchaseHistoryComponent) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_COMPACT
    }

    Scaffold(
        modifier = Modifier.fillMaxSize(),
        containerColor = background_color,
        topBar = {
            CommonTopAppBar(title = "Purchase History", onBackClick = { component.onBackClick()})
        }
    ) { innerPadding ->
        val purchaseHistory by component.state.collectAsState()
        val lazyListState = rememberLazyListState()

        LaunchedEffect(lazyListState) {
            snapshotFlow { lazyListState.layoutInfo.visibleItemsInfo.lastOrNull()?.index }
                .distinctUntilChanged()
                .collect { lastVisibleIndex ->
                    if (lastVisibleIndex == purchaseHistory.lastIndex) {
                        // Load next page when the last item becomes visible
                        component.loadNextPage()
                    }
                }
        }

        Box(
            modifier = Modifier
                .fillMaxSize()
                .padding(innerPadding)
                .background(background_color),
            contentAlignment = Alignment.Center // Align content to the center of the Box
        ) {
            LazyColumn(
                state = lazyListState,
                modifier = widthModifier,
                verticalArrangement = Arrangement.spacedBy(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                items(purchaseHistory.size) { item ->
                    val data = purchaseHistory[item]
                   // Logger.d { "PurchaseHistoryScreen :$item" }
                    PurchaseHistoryCard(
                        data = data,
                        enquireBtnText = "Enquire",
                        onCardClick = { data ->
                            if(!data.customOrder?.liveSession?.isEmpty()!!){
                                component.onCardClick(data.customOrder.id.toString())
                            }else{
                                showToast("No Session Data Found")
                            }
                        }
                    )
                }
                item {
                    // Show loading indicator while loading more data
                    if (component.isLoading.collectAsState().value) {
                        CircularProgressIndicator(
                            modifier = Modifier
                                .padding(16.dp)
                                .size(48.dp)
                        )
                    }
                }
            }
        }
    }
}

