package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.razorpay_order_id


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TransationDetails(
    @SerialName("amount_with_gst")
    val amountWithGst: String?,
    @SerialName("call_duration")
    val callDuration: String?,
    @SerialName("created_at")
    val createdAt: String?,
    @SerialName("id")
    val id: Int?,
    @SerialName("paid_amount")
    val paidAmount: String?,
    @SerialName("payment_method")
    val paymentMethod: String?,
    @SerialName("payment_mode")
    val paymentMode: String?,
    @SerialName("payment_status")
    val paymentStatus: String?,
    @SerialName("plan_id")
    val planId: Int?,
    @SerialName("plan_name")
    val planName: String?,
    @SerialName("total_amount")
    val totalAmount: String?,
    @SerialName("txn_id")
    val txnId: String?,
    @SerialName("type")
    val type: String?,
    @SerialName("updated_at")
    val updatedAt: String?,
    @SerialName("used_coin")
    val usedCoin: Int?,
    @SerialName("used_coin_balance")
    val usedCoinBalance: String?,
    @SerialName("user_id")
    val userId: Int?,
    @SerialName("wallet_balance")
    val walletBalance: String?
)