package tta.destinigo.talktoastro.feature_common.persentation.bottom_navigation_bar

import androidx.compose.runtime.Composable
import com.arkivanov.decompose.ComponentContext

class MainScreenComponent(
    private val context: ComponentContext,
    private val onNavigationEvent: @Composable (NavigationEvent) -> Unit
) : ComponentContext by context {
    @Composable
    fun event(event: NavigationEvent) {
        onNavigationEvent(event)
    }
}