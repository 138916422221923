package tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.persentation.CopyableTransactionIdWithIcon
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.persentation.SupportButton
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.data.response.TransactionOrderData
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.convertToIndianTime
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_category_24
import tta.destinigo.talktoastro.resources.ic_duration_chronic
import tta.destinigo.talktoastro.resources.ic_wallet
import tta.destinigo.talktoastro.resources.purchase_histor_bag

@Composable
fun ConsultationLayout(
    modifier: Modifier = Modifier,
    data: TransactionOrderData,
    isRescheduled: Boolean = false
) {

    val trType = when {
        data.callDetails?.expertInfo?.name != null -> "Via Call"
        data.chatDetails?.expertInfo?.name != null -> "Via Chat"
        data.emergency_call_details?.expertInfo?.name != null -> "Via Emergency Call"
        data.emergency_chat_details?.expertInfo?.name != null -> "Via Emergency Chat"
        data.type?.contains("Voip Call", ignoreCase = true) == true -> "Via Voip Call"
        else -> "Unknown Data"
    }

    val imgUrl = data.callDetails?.expertInfo?.image
        ?: data.chatDetails?.expertInfo?.image
        ?: data.emergency_call_details?.expertInfo?.image
        ?: data.emergency_chat_details?.expertInfo?.image
        ?: data.voipCallDetails?.expertInfo?.image
        ?: ""

    val skill = data.callDetails?.expertInfo?.skills
        ?: data.chatDetails?.expertInfo?.skills
        ?: data.emergency_call_details?.expertInfo?.skills
        ?: data.emergency_chat_details?.expertInfo?.skills
        ?: data.voipCallDetails?.expertInfo?.skills
        ?: ""

    Column(
        modifier = modifier
            .fillMaxWidth()
            .padding(16.dp)
            .wrapContentHeight()
            .background(
                color = Color.White,
            ),
        horizontalAlignment = Alignment.Start
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            ExpertProfileIcon(profileImageUrl = imgUrl, modifier = Modifier.size(60.dp))
            Spacer(modifier = Modifier.width(16.dp))
            Column {
                (data.callDetails?.expertInfo?.name ?: data.chatDetails?.expertInfo?.name ?: data.emergency_call_details?.expertInfo?.name ?: data.emergency_chat_details?.expertInfo?.name ?: data.voipCallDetails?.expertInfo?.name)?.let {
                    Text(
                        text = "Consultation With $it",
                        style = MaterialTheme.typography.titleMedium,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }
                Text(
                    text = skill,
                    style = MaterialTheme.typography.bodySmall,
                    maxLines = 2,
                    overflow = TextOverflow.Ellipsis
                )
            }
        }

        Spacer(modifier = Modifier.height(16.dp))

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
           // IconBox(Res.drawable.ic_wallet)
            Column {

                Row {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_category_24),
                        contentDescription = null
                    )
                    Spacer(Modifier.width(5.dp))
                    Column {
                        Text(
                            text = "Consultation",
                            style = MaterialTheme.typography.labelSmall,
                            color = Color.Gray
                        )
                        Text(
                            text = trType,
                            style = MaterialTheme.typography.bodyMedium,
                            fontSize = 16.sp
                        )
                    }

                }
            }
            Spacer(modifier = Modifier.weight(1f))

            val duration = data.callDuration ?: data.emergency_call_details?.callDuration ?: data.chatDetails?.duration ?: data.emergency_chat_details?.duration
            Column(horizontalAlignment = Alignment.End) {
                Row {
                    Icon(
                        painter = painterResource(Res.drawable.ic_duration_chronic),
                        contentDescription = null
                    )
                    Spacer(Modifier.width(5.dp))
                    Column() {
                        Text(
                            text = "Time",
                            style = MaterialTheme.typography.labelSmall,
                            color = Color.Gray
                        )
                        Text(
                            text = "$duration Min",
                            style = MaterialTheme.typography.bodyLarge,
                            fontSize = 16.sp
                        )
                    }

                }


            }
        }

        Spacer(modifier = Modifier.height(16.dp))
        val winywayPrice = data.callDetails?.winywayPrice?.toIntOrNull() ?: 0
        val expertPrice = data.callDetails?.expertPrice?.toIntOrNull() ?: 0
        val perMinutePrice = winywayPrice + expertPrice

        val emergencyPrice = data.emergency_per_min_price ?: data.emergencyPrice
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Column {
                Text(
                    text = "Consultation Price",
                    style = MaterialTheme.typography.titleMedium,
                    color = Color.Gray
                )
                Text(
                    text = "${initializeCurrency()}${emergencyPrice ?: perMinutePrice}/Min ",
                    style = MaterialTheme.typography.bodySmall,
                    fontSize = 16.sp
                )
            }


            Column {
                Text(
                    text = "Billed Amount",
                    style = MaterialTheme.typography.bodyMedium,
                    color = Color.Gray
                )
                Text(
                    text = "${initializeCurrency()}${data.totalAmount.toString()}",
                    style = MaterialTheme.typography.bodySmall,
                    fontSize = 16.sp
                )
            }
        }

        Spacer(modifier = Modifier.height(16.dp))

        Column {
            Text(
                text = "Order Date",
                style = MaterialTheme.typography.bodyMedium,
                color = Color.Gray
            )
            Text(
                text =  "${data.createdAt?.let { convertToIndianTime(it) }}",
                style = MaterialTheme.typography.bodySmall,
                fontSize = 16.sp
            )
        }

        if (isRescheduled) {
            Spacer(modifier = Modifier.height(16.dp))
            Text(
                text = "Appointment is Rescheduled",
                style = MaterialTheme.typography.bodySmall,
                color = Color.Gray,
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .fillMaxWidth()
                    .background(
                        color = Color.LightGray.copy(alpha = 0.5f),
                        shape = RoundedCornerShape(8.dp)
                    )
                    .padding(vertical = 8.dp)
            )
        }

        Spacer(modifier = Modifier.height(10.dp))

        SupportButton(onClick = {})

        val clipboardManager = LocalClipboardManager.current
        CopyableTransactionIdWithIcon(data.txnId.toString()) { txnId ->
            clipboardManager.setText(AnnotatedString(txnId))
        }
        Text(
            modifier = Modifier.fillMaxWidth(),
            text = "${data.createdAt?.let { convertToIndianTime(it) }}" ,
            //style = MaterialTheme.typography.caption
            textAlign = TextAlign.Center,
            fontSize = 16.sp
        )
    }
}

@Composable
fun IconBox(iconResId: Int) {
    Box(
        modifier = Modifier
            .size(38.dp)
            .background(color = Color.LightGray.copy(alpha = 0.5f), shape = RoundedCornerShape(8.dp))
            .padding(6.dp),
        contentAlignment = Alignment.Center
    ) {
//        Icon(
//            painter = painterResource(id = iconResId),
//            contentDescription = null,
//            tint = Color.Gray
//        )
    }
}
