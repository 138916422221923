package tta.destinigo.talktoastro.core.navigation_decompose


fun getPathAndSlug(url: String): String {

    // Remove the protocol and host part
    val hostIndex = url.indexOf("://")
    val pathStartIndex = if (hostIndex != -1) url.indexOf("/", hostIndex + 3) else url.indexOf("/")
    // Extract the path and query/slug if they exist
    return if (pathStartIndex != -1) url.substring(pathStartIndex) else "/"

}