package tta.destinigo.talktoastro.feature_winyway_wallet.data.response.razorpay_order_id


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetRazorPayOrderIdResponse(
    @SerialName("code")
    val code: Int,
    @SerialName("data")
    val `data`: RazorPayOrderData?,
    @SerialName("message")
    val message: String,
    @SerialName("status")
    val status: Boolean
)