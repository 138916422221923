package tta.destinigo.talktoastro.feature_common.data.request

import tta.destinigo.talktoastro.feature_common.data.response.SubCategoriesModel
import kotlinx.serialization.Serializable

@Serializable
data class RequestCategory(
    val id: Int,
    val name: String,
    val image: String,
    val image_url: String,
    val subcategories: List<SubCategoriesModel>?
)
