package tta.destinigo.talktoastro.feature_article.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.core.data.CommonResponse

@Serializable
data class GetPostsResponse(
    @SerialName("data")val data:List<Post>?
):CommonResponse()

