package tta.destinigo.talktoastro.feature_chat_consultation.data.response.start_consultation

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.ChatConcernItem

/**
 * Created by Prince on 14-06-2023.
 * @Author Suraj Sharma
 */
@Serializable
data class StartConsultationData(
    @SerialName("user_id") val userId: Int?,
    @SerialName("agora_expert_uid") val expertUid: Int?,
    @SerialName("total_chat_duration") val totalDurationToChat: Int,
//    @SerialName("chat_concern") val chatConcernItem: List<String>?
    @SerialName("chat_concern") val chatConcernItem: List<ChatConcernItem>?
)
