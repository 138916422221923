package tta.destinigo.talktoastro.feature_authentication.data

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.local.TokenProvider
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_authentication.data.response.RegisterUserResponse
import tta.destinigo.talktoastro.feature_authentication.data.response.RegisterUserResponseData
import tta.destinigo.talktoastro.user_feature_authentication.data.request.RegisterRequest
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class RegisterApiService:KtorApi() {

    companion object {
        const val POST_REGISTER = "user/consultant-on-demand"
    }

    suspend fun registerApi(request: RegisterRequest) = client.post {
        url(POST_REGISTER)
        // contentType(ContentType.Application.Json)
        setBody(request)
    }.body<RegisterUserResponse>()


    suspend fun registerUser (request: RegisterRequest): Flow<Resources<RegisterUserResponseData>> {
        val authToken = TokenProvider.getNewToken()
        Logger.d { "fun registerUser $authToken" }
        return flow {
            emit(Resources.Loading(true))
            try {
                val response = registerApi(request)

                if (response.status.not()) {
                    return@flow emit(Resources.Error(response.message))
                }
                Logger.d { "fun registerUser ${response.data}" }
                emit(Resources.Success(response.data))

            } catch (apiError: Exception) {
                emit(Resources.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(Resources.Error(httpException.message))
            } finally {
                emit(Resources.Loading(false))
            }

        }
    }
}