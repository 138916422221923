package tta.destinigo.talktoastro.feature_chat_consultation_new.data.api

import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.GeoDetailsRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.TimeZoneRequest
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.request.TimeZoneResponse
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.geo_details.GeoDetailsResponse
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.geo_details.GeoName
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

class AstrologyApi : KtorApi() {

    companion object {
        private const val BASE_URL = "https://json.astrologyapi.com/"
        private const val GET_GEO_DETAILS = BASE_URL + "v1/geo_details"
        private const val GET_TIME_ZONE = BASE_URL + "v1/timezone"
    }

    private suspend fun geoDetailsApi(request: GeoDetailsRequest) = astrologyClient.post {
        url(GET_GEO_DETAILS)
        setBody(request)
        println("Headers being sent: ${this.headers}")

    }.body<GeoDetailsResponse>()


    private suspend fun timeZoneApi(request: TimeZoneRequest) = astrologyClient.post {
        url(GET_TIME_ZONE)
        setBody(request)
    }.body<TimeZoneResponse>()


    suspend fun getGeoDetails(request: GeoDetailsRequest): Resources<List<GeoName>> {
        return withContext(Dispatchers.Default) {
            try {

                val topConsultantResponse = geoDetailsApi(request)

                if (topConsultantResponse.geonames == null) {
                    return@withContext Resources.Error(
                        "Geo Names not found"
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.geonames)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            } catch (e: ClientRequestException) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getTimeZone(request: TimeZoneRequest): Resources<String> {
        return withContext(Dispatchers.Default) {
            try {

                val topConsultantResponse = timeZoneApi(request)

                if (topConsultantResponse.timeZone == null) {
                    return@withContext Resources.Error(
                        "Unexpected Error"
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.timeZone)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            } catch (e: ClientRequestException) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}