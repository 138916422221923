package tta.destinigo.talktoastro.feature_common.persentation.more_tab.component

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.core.local.UserInfoProvider

class MoreScreenTabComponent(
    context: ComponentContext,
    private val onMoreScreenEvent: (MoreScreenEvent) -> Unit
) : ComponentContext by context {

    init {
        Logger.d { "More Screen Tab Component initialized" }

        lifecycle.doOnStart {
            Logger.d { "More Screen Tab Component onStart" }
            Logger.d { "More Screen Tab Component onStart Wallet Coin${UserInfoProvider.getTotalCoin()}" }
            //fetchData()
        }

        lifecycle.doOnDestroy {
            Logger.d { "More Screen Tab Component onDestroy" }
            //coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }
    fun event(event: MoreScreenEvent) {
        onMoreScreenEvent.invoke(event)
    }
}