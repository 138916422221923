package tta.destinigo.talktoastro.feature_winyway_wallet.di

import tta.destinigo.talktoastro.feature_winyway_wallet.data.api.WalletRechargeApi
import org.koin.dsl.module

//val walletModule = module {
//    scope<RechargeCheckoutComponent> {
//        scoped { WalletRechargeApi() }
//    }
//}

val walletModule = module {
    factory { WalletRechargeApi() }
}
