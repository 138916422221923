package tta.destinigo.talktoastro.feature_call_consultation.persentation.call_bottomsheet.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Warning
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.persentation.CommonButton
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_call_consultation.data.response.CallDurationData
import tta.destinigo.talktoastro.feature_chat_consultation_new.persentation.chat_bottom_sheet_info.widget.ExpertCard
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.core.theme.initializeCurrency

//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun LowOnFreeCallConsultation(
//    modifier: Modifier,
//    expertName: String,
//    expertSkills: String,
//    expertImage: String,
//    callDurationData: CallDurationData,
//    onSubmit: () -> Unit
//){
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//
//    Column(
//        modifier = modifier.fillMaxWidth().padding(20.dp)
//    ) {
//        // Title Section
//        Text(
//            text = "Call Consultation",
//            style = MaterialTheme.typography.bodyLarge,
//            modifier = Modifier.fillMaxWidth(),
//            textAlign = TextAlign.Center
//        )
//
//
//        ExpertCard(
//            modifier = Modifier,
//            expertName = expertName,
//            expertImage = expertImage,
//            expertSkills = expertSkills
//        )
//
//        Spacer(modifier = Modifier.height(8.dp))
//
//        // Warning Section
//        Row(
//            horizontalArrangement = Arrangement.Center,
//            modifier = Modifier
//                .fillMaxWidth()
//                .background(Color.White, RoundedCornerShape(8.dp)),
//            verticalAlignment = Alignment.CenterVertically
//        ) {
//            Icon(
//                imageVector = Icons.Default.Warning, // Or use another icon
//                contentDescription = "Warning Icon",
//                tint = Color.Black
//            )
//
//            Spacer(modifier = Modifier.width(8.dp))
//
//            Text(
//                text = "Not Enough Wallet Balance",
//                style = MaterialTheme.typography.titleSmall,
//                fontWeight = FontWeight.Bold
//            )
//        }
//
//        Spacer(modifier = Modifier.height(8.dp))
//
//        // Error Message
//        Text(
//            text = "You should have at least ₹ ${callDurationData.plan_amount} to connect with an expert",
//            color = Color.Red,
//            style = MaterialTheme.typography.titleSmall,
//            maxLines = 1,
//            modifier = Modifier
//                .background(Color(0xFFFFEAEA), RoundedCornerShape(8.dp))
//                .fillMaxWidth()
//                .padding(16.dp),
//            textAlign = TextAlign.Center
//        )
//
//        Spacer(modifier = Modifier.height(16.dp))
//
//        // Wallet Balance Section
//        Column(
//            horizontalAlignment = Alignment.CenterHorizontally,
//            modifier = Modifier
//                .fillMaxWidth()
//                .padding(8.dp)
//        ) {
//            Text(
//                text = "Wallet Balance",
//                style = MaterialTheme.typography.labelSmall
//            )
//            Text(
//                text = "₹ ${UserInfoProvider.getTotalWalletMoney()}",
//                style = MaterialTheme.typography.bodyLarge,
//                fontWeight = FontWeight.Bold
//            )
//        }
//
//
//        Spacer(modifier = Modifier.height(16.dp))
//
//        // Footer Text
//        Row(
//            modifier = Modifier.background(
//                primaryColor.copy(alpha = 0.1F), shape = RoundedCornerShape(
//                    Scale.CORNER_SMALL)
//            ).fillMaxWidth().wrapContentHeight().padding(horizontal = 12.dp, vertical = 6.dp),
//            verticalAlignment = Alignment.CenterVertically
//        ) {
////            MarqueeText(
////                text = "* This will not take much time, 10 users recharged the wallet recently.",
////                modifier = Modifier
////                    .background(Color(0xFFFFEAEA), RoundedCornerShape(8.dp))
////                    .fillMaxWidth()
////                    .padding(16.dp)
////            )
//            Text(text = "This will not take much time, 10 users recharged the wallet recently.")
//        }
//
//        Spacer(modifier = Modifier.height(2.dp))
//
//        CommonButton(
//            onClick = {
//                onSubmit.invoke()
//            },
//            modifier = Modifier.fillMaxWidth().height(55.dp).padding(bottom = 8.dp),
//            buttonText = "Let's Recharge"
//        )
//
//        Box(
//            modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center
//        ) {
//            Text(
//                "This is a one-time free call offered by Winyway",
//                fontSize = 12.sp,
//                textAlign = TextAlign.Center
//            )
//        }
//
//    }
//}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun LowOnFreeCallConsultation(
    modifier: Modifier = Modifier,
    expertName: String,
    expertSkills: String,
    expertImage: String,
    callDurationData: CallDurationData,
    onSubmit: () -> Unit,
    onDismiss: () -> Unit
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()
    val openCallOption = rememberSaveable { mutableStateOf(false) }
    if (windowSizeClass == WindowWidthSizeClass.Compact) {
        // Use BottomSheet for mobile devices

        ModalBottomSheet(
            sheetState = sheetState,
            containerColor = Color.White,
            onDismissRequest = {
                coroutineScope.launch {
                    sheetState.hide()
                    onDismiss()
                }
            }
        ) {
            BottomSheetContent(
                expertName = expertName,
                expertSkills = expertSkills,
                expertImage = expertImage,
                callDurationData = callDurationData,
                onSubmit = onSubmit
            )
        }
    } else {
        // Use Dialog for larger screens (like desktop)
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                //consultationComponent.onClosePage()
                onDismiss.invoke()
            },
            text = {
                BottomSheetContent(
                    expertName = expertName,
                    expertSkills = expertSkills,
                    expertImage = expertImage,
                    callDurationData = callDurationData,
                    onSubmit = onSubmit
                )
            },
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {
//                coroutineScope.launch {
//                    sheetState.hide()
//                }
            },
            dismissButton = {

//                coroutineScope.launch {
//                    sheetState.hide()
//                }
            }
        )
    }
}

@Composable
fun BottomSheetContent(
    expertName: String,
    expertSkills: String,
    expertImage: String,
    callDurationData: CallDurationData,
    onSubmit: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(20.dp)
    ) {
        Text(
            text = "Call Consultation",
            style = MaterialTheme.typography.bodyLarge,
            modifier = Modifier.fillMaxWidth(),
            textAlign = TextAlign.Center
        )

        ExpertCard(
            modifier = Modifier,
            expertName = expertName,
            expertImage = expertImage,
            expertSkills = expertSkills
        )

        Spacer(modifier = Modifier.height(8.dp))

        Row(
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White, RoundedCornerShape(8.dp)),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Icon(
                imageVector = Icons.Default.Warning,
                contentDescription = "Warning Icon",
                tint = Color.Black
            )

            Spacer(modifier = Modifier.width(8.dp))

            Text(
                text = "Not Enough Wallet Balance",
                style = MaterialTheme.typography.titleSmall,
                fontWeight = FontWeight.Bold
            )
        }

        Spacer(modifier = Modifier.height(8.dp))

        Text(
            text = "You should have at least ${initializeCurrency()} ${callDurationData.plan_amount} to connect with an expert",
            color = Color.Red,
            style = MaterialTheme.typography.titleSmall,
            maxLines = 1,
            modifier = Modifier
                .background(Color(0xFFFFEAEA), RoundedCornerShape(8.dp))
                .fillMaxWidth()
                .padding(16.dp),
            textAlign = TextAlign.Center
        )

        Spacer(modifier = Modifier.height(16.dp))

        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .fillMaxWidth()
                .padding(8.dp)
        ) {
            Text(
                text = "Wallet Balance",
                style = MaterialTheme.typography.labelSmall
            )
            Text(
                text = "${initializeCurrency()} ${UserInfoProvider.getTotalWalletMoney()}",
                style = MaterialTheme.typography.bodyLarge,
                fontWeight = FontWeight.Bold
            )
        }

        Spacer(modifier = Modifier.height(16.dp))

        Row(
            modifier = Modifier
                .background(
                    primaryColor.copy(alpha = 0.1F),
                    shape = RoundedCornerShape(Scale.CORNER_SMALL)
                )
                .fillMaxWidth()
                .wrapContentHeight()
                .padding(horizontal = 12.dp, vertical = 6.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(text = "This will not take much time, 10 users recharged the wallet recently.")
        }

        Spacer(modifier = Modifier.height(2.dp))

        CommonButton(
            onClick = {
                onSubmit.invoke()
            },
            modifier = Modifier
                .fillMaxWidth()
                .height(55.dp)
                .padding(bottom = 8.dp),
            buttonText = "Let's Recharge"
        )

        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Text(
                "This is a one-time free call offered by Winyway",
                fontSize = 12.sp,
                textAlign = TextAlign.Center
            )
        }
    }
}
