package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Language
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.persentation.CallConsultationButton
import tta.destinigo.talktoastro.core.persentation.ChatConsultationButton
import tta.destinigo.talktoastro.core.persentation.ExpertPriceRow
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.persentation.LoginDialog
import tta.destinigo.talktoastro.core.persentation.getPlatformType
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.platform.PlatformType
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallHostPage
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageEvent
import tta.destinigo.talktoastro.feature_expert_notify.NotifyScreen
import tta.destinigo.talktoastro.feature_expert_notify.data.request.ExpertAvailableRequest
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_notifications_24

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalMaterial3Api::class)
@Composable
fun ProfileBox(
    component: ExpertDetailsPageComponent,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
    modifier: Modifier = Modifier,
    expertData: RequestConsultant
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var isNotifyClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    val state = component.submitNotifyButton.collectAsState()
    val error by component.errorMessage.collectAsState()

    val detectedPlatform: PlatformType by lazy { getPlatformType() }

    val PlatformType =  when(detectedPlatform){
        PlatformType.Android -> "Android"
        PlatformType.Desktop -> "Desktop"
        PlatformType.Web -> "Web"
        PlatformType.iOS -> "Ios"
    }
    val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
    val coroutineScope = rememberCoroutineScope()

    Column(modifier = modifier) {
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.TopStart
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Profile Image

                ExpertProfileIcon(
                    profileImageUrl = expertData.profile_image_url,
                    modifier = Modifier.size(70.dp)
                )

                Spacer(modifier = Modifier.width(8.dp))

                // Consultant Details Column
                Column {
                    // Consultant Name
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 8.dp, vertical = 4.dp),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = expertData.full_name.toString(),
                            style = TextStyle(
                                fontSize = 20.sp,
                                fontWeight = FontWeight.Bold
                            ),
                            color = primaryColor,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis
                        )

                        if (WindowWidthSizeClass.Compact == windowSizeClass) {
                            Button(
                                onClick = {
                                  //  showToast("Coming Soon")
                                    isNotifyClickSelected = expertData
                                },
                                enabled = expertData.callStatus.equals(
                                    "Offline",
                                    true
                                ) || expertData.chatButtonStatus.equals("Offline", true),
                                colors = ButtonDefaults.buttonColors(primaryColor),
                                shape = RoundedCornerShape(Scale.CORNER_MEDIUM)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.baseline_notifications_24),
                                    contentDescription = null
                                )

                            }
                        }else{
                            Button(
                                onClick = {
//                                    CoroutineScope(Dispatchers.Default).launch {
//                                        component.getNotifyWhenExpertAvailable(
//                                            request = ExpertAvailableRequest(
//                                                expertId = expertData.id.toString()
//                                            )
//                                        )
//                                    }
//                                    if(state.value){
//                                        isNotifyClickSelected = expertData
//                                    }else{
//                                        showToast("Unexpected Error Occur")
//                                    }

                                    isNotifyClickSelected = expertData

                                    //showToast("Coming Soon")
                                },
                                enabled = expertData.callStatus.equals(
                                    "Offline",
                                    true
                                ) || expertData.chatButtonStatus.equals("Offline", true),
                                colors = ButtonDefaults.buttonColors(primaryColor),
                                shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.baseline_notifications_24),
                                    contentDescription = null
                                )
                                Spacer(Modifier.width(5.dp))
                                Text(
                                    text = "Notify Me",
                                    textAlign = TextAlign.Center,
                                    color = Color.White
                                )
                            }
                        }
                    }


                    Spacer(modifier = Modifier.height(4.dp))


                    // Consultant Description
                    Text(
                        text = expertData.profile_description!!,
                        style = TextStyle(
                            fontSize = 16.sp,
                            color = Color.DarkGray
                        ),
                        overflow = TextOverflow.Ellipsis,
                        maxLines = 2
                    )

                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = expertData.skills.toString(),
                        style = TextStyle(
                            fontSize = 14.sp
                        ),
                        maxLines = 1
                    )
                    // Language Text
                    Spacer(modifier = Modifier.height(4.dp))
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Icon(
                            imageVector = Icons.Default.Language,
                            contentDescription = "World Icon",
                            tint = Color.Black,
                            modifier = Modifier.size(14.dp)
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            text = expertData.language!!,
                            style = TextStyle(
                                fontSize = 14.sp
                            ),
                            maxLines = 1
                        )
                    }

                    Spacer(modifier = Modifier.height(4.dp))
                }
            }
        }

        // Pricing Text at the bottom of the card
        Column(
            modifier = Modifier
                .padding(vertical = 8.dp)
                .fillMaxWidth(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.Start
        ) {

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 4.dp, vertical = 2.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.Top // Align content to the top for consistency
            ) {
                Column(
                    modifier = Modifier.weight(1f) // Distribute space between the two columns
                ) {
                    Text(
                        text = "Best Consultation Price for you:",
                        style = TextStyle(
                            fontSize = 16.sp,
                            fontWeight = FontWeight.Medium
                        ),
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                    Spacer(modifier = Modifier.height(4.dp)) // Add spacing between elements
                    ExpertPriceRow(
                        isFreeCallApplicable = component.isFreeConsultation,
                        callPerMinutePrice = expertData.callPerMinutePrice.toString(),
                        expertPrice = expertData.expert_price ?: 0,
                        winywayPrice = expertData.winyway_price ?: 0,
                        flashPrice = expertData.flashPerMinutePrice,
                        horizontalArrangement = Arrangement.Start
                    )
                }

                Spacer(modifier = Modifier.width(16.dp)) // Add space between columns

                Column(
                    horizontalAlignment = Alignment.End // Align text to the end for better structure
                ) {
                    Text(
                        text = "Availability:",
                        style = TextStyle(
                            fontSize = 14.sp,
                            fontWeight = FontWeight.SemiBold
                        )
                    )
                    Spacer(modifier = Modifier.height(4.dp))
//                    Text(
//                        text = "Mon to Fri: 10:AM to 6:PM", // Corrected typo in "Fir" to "Fri"
//                        style = TextStyle(
//                            fontSize = 14.sp
//                        )
//                    )
                    Text(
                        text = expertData.ExpertAvailability ?: "", // Corrected typo in "Fir" to "Fri"
                        style = TextStyle(
                            fontSize = 14.sp
                        )
                    )
                }
            }


            // Action Buttons
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {

                CallConsultationButton(
                    modifier = Modifier.weight(1f),
                    chatStatus = expertData.chatButtonStatus,
                    callStatus = expertData.callStatus,
                    onClick = {
                        isCallClickSelected = expertData
                    }
                )

                Spacer(modifier = Modifier.width(6.dp))

                ChatConsultationButton(
                    modifier = Modifier.weight(1f),
                    chatStatus = expertData.chatButtonStatus,
                    callStatus = expertData.callStatus,
                    onClick = {
                        onChatConsultation.invoke(expertData)
                    }
                )
            }

            if (isCallClickSelected != null) {
                CallHostPage(
                    callDetails = isCallClickSelected!!,
                    onLoginClicked = {
                        onLoginClick.invoke()
                    },
                    onDismiss = {
                        isCallClickSelected = null
                    },
                    onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) },
                    context = component,
                    onNavigateRechargeCheckOut = { rechargeData ->
                        component.event(ExpertDetailsPageEvent.onRechargeRequired(rechargeData))
                    },
                    onVoIpCallStart = { duration, expertInfo ->
                        component.event(
                            ExpertDetailsPageEvent.OnVoIPCallConsultationStart(
                                duration = duration,
                                expertInfo = expertInfo
                            )
                        )
                    },
                    onNavigateCallWaitingPage = { CommonExpertInfo, onClose ->
                        //onNavigateCallWaitingPage.invoke(CommonExpertInfo,onClose)
                    }
                )
            }

            if (isNotifyClickSelected != null) {
                val isUserLogin = UserInfoProvider.getIsLogin()
                if (!isUserLogin) {
                    LoginDialog(
                        onLoginClicked = {
                            onLoginClick.invoke()
                        },
                        onSignUpClicked = {
                            onLoginClick.invoke()
                        },
                        onDismiss = {
                            isNotifyClickSelected = null
                        }
                    )
                    return
                }

                CoroutineScope(Dispatchers.Default).launch {
                    component.getNotifyWhenExpertAvailable(
                        request = ExpertAvailableRequest(
                            expertId = isNotifyClickSelected!!.id.toString(),
                            Domain = "talktoastro.com",
                            PlatefromType = PlatformType
                        )
                    )
                }


                error.let {
                    LaunchedEffect(it) {
                        showToast("Unexpected Error Occurred!")
                    }
                }
                // Show UI based on state
                when {
                    state.value -> {
                        if (WindowWidthSizeClass.Compact == windowSizeClass) {
                            ModalBottomSheet(
                                sheetState = sheetState,
                                containerColor = Color.White,
                                onDismissRequest = {
                                    coroutineScope.launch {
                                        sheetState.hide()
                                    }
                                }
                            ) {
                                NotifyScreen()
                            }
                        } else {
                            AlertDialog(
                                containerColor = Color.White,
                                onDismissRequest = {
                                    isNotifyClickSelected = null
                                },
                                text = { NotifyScreen() },
                                shape = RoundedCornerShape(Scale.CORNER_SMALL),
                                confirmButton = {},
                                dismissButton = {}
                            )
                        }
                    }

                    else -> {
                        //showToast("Unexpected ")
                        // Show ProgressBar while waiting for `state.value` to become true
                    }
                }
            }

        }
    }
}
