package tta.destinigo.talktoastro.feature_article.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Post(
    @SerialName("id") val id: String?,
    @SerialName("category_id") val categoryId: String?,
    @SerialName("status") val status: String?,
    @SerialName("name") val name: String?,
    @SerialName("image") val image: String?,
    @SerialName("url") val url: String?,
    @SerialName("description") val description: String?,
    @SerialName("meta_title") val metaTitle: String?,
    @SerialName("meta_keywords") val metaKeywords: String?,
    @SerialName("meta_description") val metaDescription: String?,
    @SerialName("alt") val alt: String?,
    @SerialName("created_at") val createdAt: String?,
    @SerialName("updated_at") val updatedAt: String?,
    @SerialName("category_name") val categoryName: String?,
    @SerialName("image_url") val imageUrl: String? = "https://images.sftcdn.net/images/t_app-cover-l,f_auto/p/befbcde0-9b36-11e6-95b9-00163ed833e7/260663710/the-test-fun-for-friends-screenshot.jpg",
)