package tta.destinigo.talktoastro.feature_courses.persentation.course_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnCreate
import com.arkivanov.essenty.lifecycle.doOnDestroy
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_courses.data.request.CourseDetailsSubmitForm
import tta.destinigo.talktoastro.feature_courses.domain.CourseRepo

class CourseComponent(
    context: ComponentContext,
    slug: String
) : ComponentContext by context, KoinComponent {

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Default)
    private val api: CourseRepo by inject()

    private val _state = MutableStateFlow<Resources<CourseInfo>>(Resources.Loading(false))
    val state get() = _state.asStateFlow()

    private val _courses = MutableStateFlow<CourseInfo?>(null)
    val courses get() = _courses.asStateFlow()

    fun onBackPress() {}
    fun onSubmitForm(
        name: String, mobile: String, email: String, courseId: String
    ) {
        coroutineScope.launch {
            postCourseDetails(
                CourseDetailsSubmitForm(
                    fullName = name,
                    mobile = mobile,
                    email = email,
                    courseId = courseId
                )
            )
        }
    }

    init {

        lifecycle.doOnCreate {
            coroutineScope.launch {
                getCoursesByRemote(slug)
            }
        }

        lifecycle.doOnDestroy {
            coroutineScope.cancel()
        }

    }

    private suspend fun getCoursesByRemote(slug: String) {
        api.getCourse(slug = slug).collectLatest {
            when (it) {
                is Resources.Error -> {
                    printLog("Courses Response Error -> ${it.msg}")
                    _state.emit(Resources.Error(it.msg))
                }

                is Resources.Loading -> {
                    printLog("Courses Response Loading -> ${it.isLoading}")
                    _state.emit(Resources.Loading(it.isLoading))
                }

                is Resources.Success -> {
                    printLog("Courses Response Success -> ${it.data}")
                    _state.emit(Resources.Success(it.data))
                    it.data?.let { course ->
                        _courses.emit(course)
                    }
                }

            }
        }
    }

    private val _formState = MutableStateFlow<Boolean?>(null)
    val formState get() = _formState.asStateFlow()

    fun setNullFormState() {
        coroutineScope.launch {
            _formState.emit(null)
        }
    }


    private suspend fun postCourseDetails(request: CourseDetailsSubmitForm) {
        api.submitCourseDetails(request).collect {
            when (it) {
                is Resources.Error -> {
                    Logger.d { "postCourseDetails error ${it.msg}" }
                }

                is Resources.Loading -> {
                    _formState.emit(it.isLoading)
                }

                is Resources.Success -> {
                    Logger.d { "postCourseDetails success ${it.data}" }
                }
            }
        }
    }

    private fun printLog(msg: String) {
        Logger.d("COURSE_LIST_PAGE - $msg")
    }

}