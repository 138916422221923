package tta.destinigo.talktoastro.feature_common.data.response

import kotlinx.serialization.Serializable

@Serializable
data class SubCategoriesModel(
    val id: Int,
    val name: String,
    val isSelected: Boolean? = null
)
