package tta.destinigo.talktoastro.feature_custom_order.custom_order_details

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderDeatilsRequest
import tta.destinigo.talktoastro.feature_custom_order.data.response.CustomOrderDetailData
import tta.destinigo.talktoastro.feature_custom_order.data.response.DataX
import tta.destinigo.talktoastro.feature_custom_order.domain.CustomOrderListRepo

class CustomOrderLessonComponent(
    context: ComponentContext,
    private val _customOrderId:String,
    private val onBackPress:()->Unit
): ComponentContext by context, KoinComponent {

    val customOrderId get() = _customOrderId
    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()
    }
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    private val api: CustomOrderListRepo by inject()
    private val _state = MutableStateFlow<CustomOrderDetailData?>(null)
    val state: StateFlow<CustomOrderDetailData?> = _state
    fun onBackClick() = onBackPress.invoke()
    init {
        lifecycle.doOnStart {
            Logger.d { "Custom Order Lesson Screen Component onStart" }
            coroutineScope.launch {
                getCustomOrderDetailsData()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Custom Order Lesson Screen Component onDestroy" }
            coroutineScope.cancel()
        }
    }


    private suspend fun getCustomOrderDetailsData() {
        val request = CustomOrderDeatilsRequest(customOrderId = customOrderId)
        api.getCustomOrderDetails(request = request).collect { result ->
            when (result) {
                is Resources.Error -> {
                    Logger.d { "getCustomOrderDetailsData on Error Start: ${result.msg}." }
                }
                is Resources.Loading -> {
                    Logger.d { "getCustomOrderDetailsData on Loading State: ${result.isLoading}" }
                }
                is Resources.Success -> {
                    Logger.d { "getCustomOrderDetailsData on Success State: ${result.data}" }
                    _state.emit(result.data)
                }
            }
        }
    }

}