package tta.destinigo.talktoastro.feature_courses.data.response.course

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.core.data.CommonResponse
import tta.destinigo.talktoastro.feature_courses.data.response.courses.CourseData

@Serializable
data class CourseResponse(
    @SerialName("data") val data: CourseData? = null
) : CommonResponse()
